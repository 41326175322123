import { Component, HostBinding } from '@angular/core';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { EditorService } from '../../services/editor.service';

@Component({
  selector: 'app-layerlist',
  templateUrl: './layerlist.component.html',
  styleUrls: ['./layerlist.component.css']
})
export class LayerlistComponent {

  constructor(public editorService: EditorService) { 
    
  }


  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.editorService.layers, event.previousIndex, event.currentIndex);
    var ids: number[] = [];
    ids = this.editorService.layers.map(layer => layer.widget.id);
    //@ts-ignore
    LayerAPI.reOrderLayers(ids);
  }
}
