import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/api/api.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-setup',
  templateUrl: './setup.component.html',
  styleUrls: ['./setup.component.scss']
})
export class SetupComponent {
  name: string = '';
  accountCreated: boolean = false;
  constructor(private apiService: ApiService, private authService: AuthService, private router: Router, private _snackBar: MatSnackBar) { 
    authService.oAuthCheck();
  }

  setup() {
    if (this.name == '') {
      this._snackBar.open("Please enter a name");
      return;
    }
    this.apiService.createAccount(this.name).subscribe((account) => {
      if(this.authService.isOauth) {
        this.router.navigate(['/oauth2/authorize'], { queryParams: { client_id: this.authService.client_id, response_type: this.authService.response_type, scope: this.authService.scope, state: this.authService.state, redirect_uri: this.authService.redirect_uri } });
        return;
      }
      this.router.navigate([this.authService.getAccountBasePath()]).then(() => { 
        location.reload();
      });
    }, (error) => { this._snackBar.open(error); });
  }
}
