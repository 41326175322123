<div class="modal-main" cdkDrag>
    <div class="modal-head" cdkDragHandle>
        <div class="modal-title">Settings</div>
        <mat-icon class="action-icons close-action" (click)="onClose()">close</mat-icon>
    </div>
    <div class="modal-body">
        <div class="config-main">
            <div class="v-menu-container">
                <div class="v-menu-button" [class.v-menu-button-selected]="selectedMenu == selectedMenuOptions.main" (click)="selectMenuOption(selectedMenuOptions.main)">
                    Main
                </div>
                <div *ngIf="type ==types.table" class="v-menu-button" [class.v-menu-button-selected]="selectedMenu == selectedMenuOptions.table" (click)="selectMenuOption(selectedMenuOptions.table)">
                    Table
                </div>
                <div *ngIf="type ==types.text" class="v-menu-button" [class.v-menu-button-selected]="selectedMenu == selectedMenuOptions.text" (click)="selectMenuOption(selectedMenuOptions.text)">
                    Text
                </div>
                <div *ngIf="type ==types.playlist" class="v-menu-button" [class.v-menu-button-selected]="selectedMenu == selectedMenuOptions.playlist" (click)="selectMenuOption(selectedMenuOptions.playlist)">
                    Playlist
                </div>
            </div>
            <div class="config-body">
                <div *ngIf="selectedMenu == selectedMenuOptions.main">
                    <h3>Main</h3>
                    <div>
                        <div class="mat-elevation-z8">
                            <table class="table text-center">
                                <tr>
                                    <td>Layer Name</td>
                                    <td>
                                        <div class="mb-3">
                                            <input class="form-control" [(ngModel)]="editorService.selectedLayer.name" (change)="updateLayer()">
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Layer class</td>
                                    <td>
                                        <div class="mb-3">
                                            <select class="form-select" [(ngModel)]="editorService.selectedLayer.classType" (change)="updateLayer()">
                                            <option *ngFor="let fieldClass of fieldClasses" [value]="fieldClass.name">{{fieldClass.name}}</option>
                                          </select>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Layer Field</td>
                                    <td>
                                        <div class="mb-3">
                                            <select class="form-select" [(ngModel)]="editorService.selectedLayer.selectedFieldType" (change)="onChangeField($event)">
                                            <option *ngFor="let field of fields" [value]="field.field_class+'_'+field.name">{{field.field_class}} - {{field.name}}</option>
                                          </select>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Dynamic</td>
                                    <td>
                                        <div class="mb-3">
                                            <select class="form-select" [(ngModel)]="editorService.selectedLayer.isDynamic" (change)="updateLayer()">
                                            <option [ngValue]="true">Yes</option>
                                            <option [ngValue]="false">No</option>
                                          </select>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Required</td>
                                    <td>
                                        <div class="mb-3">
                                            <select class="form-select" [(ngModel)]="editorService.selectedLayer.required" (change)="updateLayer()">
                                            <option [ngValue]="true">Yes</option>
                                            <option [ngValue]="false">No</option>
                                          </select>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>

                <div *ngIf="selectedMenu == selectedMenuOptions.table" style="padding-right: 2em;">
                    <h3>Table</h3>
                    <div>
                        <div class="mat-elevation-z8">
                            <div class="top-buttons">
                                <button mat-stroked-button (click)="addColumn()">Add Column</button>
                                <button mat-stroked-button (click)="addRow()">Add Row</button>
                                <button mat-stroked-button (click)="onEditTable()">Edit</button>
                                <inputfield *ngIf="tableDataSource.length > 0" [global]="true" [column]="i" [row]="0" [isColumn]="true" (onChange)="updateTable($event)" [(inputValue)]="tableDataSource[0][0].layerText"></inputfield>
                            </div>
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th *ngFor="let header of displayedColumns; let i = index">
                                            <inputfield  [column]="i" [row]="0" [isColumn]="true" (onChange)="updateTable($event)" [(inputValue)]="header.layerText"></inputfield>
                                        </th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let row of tableDataSource; let i = index">
                                        <td *ngFor="let cell of row; let i2 = index">
                                            <inputfield [column]="i2" [row]="i" [isColumn]="false" (onChange)="updateTable($event)" [(inputValue)]="cell.layerText"></inputfield>
                                        </td>
                                        <td>
                                            <button tabindex="-1" mat-icon-button (click)="deleteRow(i)">
                                                <mat-icon>delete</mat-icon>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div *ngIf="selectedMenu == selectedMenuOptions.text">
                        <h3>Text</h3>
                        <div></div>
                    </div>
                    <div *ngIf="selectedMenu == selectedMenuOptions.playlist">
                        <h3>Playlist</h3>
                        <div></div>
                    </div>
                    <div *ngIf="selectedMenu == selectedMenuOptions.design">
                        <h3>Design</h3>
                        <div></div>
                    </div>
                    <div *ngIf="selectedMenu == selectedMenuOptions.dynamic">
                        <h3>Dynamic</h3>
                        <div></div>
                    </div>
                </div>

                <div *ngIf="selectedMenu == selectedMenuOptions.text">
                    <h3>Text</h3>
                    <div>
                        <div class="mat-elevation-z8">
                            <table class="table table-striped">
                                <tr>
                                    <td style="width: 100px;">Text</td>
                                    <td>
                                        <inputfield [autoFocus]="true" (onChange)="updateTextLayer($event)" [(inputValue)]="textLayer"></inputfield>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>

                <div *ngIf="selectedMenu == selectedMenuOptions.playlist" class="playlist-settings-container">
                    <h3>Playlist</h3>
                    <div>
                        <div class="mat-elevation-z8">
                            <div class="top-buttons" style="padding: 0.5em;">
                                <div class="mb-3" style="display: flex; align-items: center;">
                                    <b>Display time:</b>
                                    <input style="width: 5em;" type="number" class="form-control" [(ngModel)]="this.currentPlaylist.time" (change)="updatePlaylist()">
                                </div>
                                <button mat-stroked-button (click)="addPlaylistItems()">Add</button>
                            </div>
                            <div class="playlist-list">
                                <table class="table table-striped">
                                    <tbody cdkDropList (cdkDropListDropped)="playlistDrop($event)">
                                        <tr style="width: 30em;" cdkDrag *ngFor="let item of this.currentPlaylist.items; let pi = index">

                                            <td style="width: 80%;">
                                                <div class="playlist-item">
                                                    <img crossorigin="anonymous" class="playlist-image" src="{{item.data.url_small}}" />
                                                </div>
                                            </td>
                                            <td style="width: 20%;">
                                                <div class="playlist-action">
                                                    <mat-icon class="icon" (click)="removePlaylistItem(pi)">
                                                        delete
                                                    </mat-icon>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div>
    <app-edit-table *ngIf="editTable" (onCloseTable)="onCloseTable($event)" (onUpdateRowStyle)="onUpdateRowStyle($event)" (onColmnUpdate)="onColmnUpdate($event)" (onColumnDelete)="onColumnDelete($event)" [tableDataSource]="tableDataSource" [displayedColumns]="displayedColumns"></app-edit-table>
</div>