export enum FontWeight {
    w100 = 0,
    w200 = 1,
    w300 = 2,
    w400 = 3,
    w500 = 4,
    w600 = 5,
    w700 = 6,
    w800 = 7,
    w900 = 8,
    normal  = 9,
    bold    = 10,
}
