<div class="modal-main" cdkDrag>
    <div class="modal-head" cdkDragHandle>
        <div class="modal-title">Profile</div>
        <mat-icon class="action-icons close-action" (click)="commonService.userProfileOpen = false">close</mat-icon>
    </div>
    <div class="modal-body">
        <div class="settings-main">
            <div class="v-menu-container">
                <div class="v-menu-button" (click)="currentOption = 'info'" [class.v-menu-button-selected]="currentOption == 'info'">General</div>
                <div class="v-menu-button" (click)="currentOption = 'Security'" [class.v-menu-button-selected]="currentOption == 'Security'">Security</div>
                <div class="v-menu-button" (click)="currentOption = 'WebDav'" [class.v-menu-button-selected]="currentOption == 'WebDav'">WebDav</div>
            </div>
            <div class="settings-edit">
                <div class="general-info" *ngIf="currentOption == 'info'">
                    <table class="table">
                        <thead>

                        </thead>
                        <tbody>
                            <tr>
                                <td class="align-middle">Screen name</td>
                                <td> <input style="width: 22em;" class="input" type="text" placeholder="Screen name" [(ngModel)]="user.alias" /></td>
                            </tr>
                            <tr>
                                <td class="align-middle">First name</td>
                                <td> <input style="width: 22em;" class="input" type="text" placeholder="First name " [(ngModel)]="user.firstname" /></td>
                            </tr>
                            <tr>
                                <td class="align-middle">Last name</td>
                                <td> <input style="width: 22em;" class="input" type="text" placeholder="Last name" [(ngModel)]="user.lastname" /></td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="general-info" *ngIf="currentOption == 'Security'">
                    <div class="authProviders" *ngFor="let authProvider of user.authProviders; let i = index;">
                        <div class="authentication-tokens">Authentication provider #{{i+1}}</div>

                        <div class="provider-uuid">
                            <table class="table">
                                <thead>
    
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="align-middle">Creation date</td>
                                        <td>{{authProvider.date | date:'dd-MM-yyyy HH:mm' }}</td>
                                    </tr>
                                    <tr>
                                        <td class="align-middle">Authentication type</td>
                                        <td>{{authProvider.type}}</td>
                                    </tr>
                                    <tr>
                                        <td class="align-middle">UUID</td>
                                        <td>{{authProvider.providerUUID}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="authentication-tokens">Authentication tokens</div>

                        <table class="table table-dark">
                            <thead>
                                <tr>
                                    <td class="align-middle">Status</td>
                                    <td class="align-middle">IP</td>
                                    <td class="align-middle">Creation date</td>
                                    <td class="align-middle">Expire date</td>
                                    <td class="align-middle"></td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let authenticationToken of authProvider.authenticationTokens">
                                    <td class="align-middle">
                                        <div class="authtoken approved" *ngIf="authenticationToken.active">Approved</div>
                                        <div class="authtoken" *ngIf="!authenticationToken.active">Pending</div>
                                    </td>
                                    <td class="align-middle">{{authenticationToken.ip_address}}</td>
                                    <td class="align-middle">{{authenticationToken.date | date:'dd-MM-yyyy HH:mm' }}</td>
                                    <td class="align-middle">{{authenticationToken.expireDate | date:'dd-MM-yyyy HH:mm' }}</td>
                                    <td class="align-middle"><button class="btn btn-outline-warning" (click)="deleteToken(authenticationToken.id)">Revoke access</button></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="general-info" *ngIf="currentOption == 'WebDav'">
                    
                    <div class="authProviders" *ngFor="let authProvider of user.authProviders; let i = index;">
                        <div class="webdav-logins" *ngIf="authProvider.type == 'webdav'">
                            <table class="table table-dark">
                                <thead>
                                    <tr>
                                        <td class="align-middle">URL</td>
                                        <td class="align-middle">IP</td>
                                        <td class="align-middle">Creation date</td>
                                        <td class="align-middle">Expire date</td>
                                        <td class="align-middle"></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let authenticationToken of authProvider.authenticationTokens">
                                        <td class="align-middle">
                                           {{apiService.webDavURL}}/webdav-{{authService.user.id}}-{{authenticationToken.token}}
                                        </td>
                                        <td class="align-middle">{{authenticationToken.ip_address}}</td>
                                        <td class="align-middle">{{authenticationToken.date | date:'dd-MM-yyyy HH:mm' }}</td>
                                        <td class="align-middle">{{authenticationToken.expireDate | date:'dd-MM-yyyy HH:mm' }}</td>
                                        <td class="align-middle"><button class="btn btn-outline-warning" (click)="deleteToken(authenticationToken.id)">Revoke access</button></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>                        
                    </div>
                    <button class="btn btn-light" (click)="generateWebDavToken()">Generate WebDav URL</button>
                </div>
            </div>
        </div>
    </div>
    <div class="actions" *ngIf="currentOption == 'info'">
        <button mat-button (click)="saveProfile()">Save</button>
    </div>
</div>