<app-breadcrumbs></app-breadcrumbs>
<div class="overlay">
    <div>
        <div>
            <h1>Dashboard</h1>
            <p>Welcome to your account dashboard.</p>
        </div>
        <div class="dash-icons">
            <div class="dash-icon" (click)="loadCompent('playlists')">
                <mat-icon>featured_play_list</mat-icon>
                <span>Playlists</span>
            </div>
            <div class="dash-icon" (click)="loadCompent('data')">
                <mat-icon>table</mat-icon>
                <span>Drive</span>
            </div>
            <div class="dash-icon" (click)="loadCompent('designs')">
                <mat-icon>draw</mat-icon>
                <span>Designs</span>
            </div>
            <div class="dash-icon" (click)="loadCompent('displays')">
                <mat-icon>smart_display</mat-icon>
                <span>Displays</span>
            </div>             
            <div class="dash-icon" (click)="loadCompent('settings')">
                <mat-icon>tune</mat-icon>
                <span>Settings</span>
            </div>

            <div *ngIf="authService.account.firmware_admin" class="dash-icon" (click)="loadCompent('firmware')">
                <mat-icon>install_desktop</mat-icon>
                <span>Firmware</span>
            </div>
        </div>
    </div>
</div>