<div class="modal-main" cdkDrag>
    <div class="modal-head" cdkDragHandle>
        <div class="modal-title">Select PixaBay media</div>
        <mat-icon class="action-icons close-action" (click)="closeMedia()">close</mat-icon>
    </div>
    <div class="modal-body">
        <app-data-folder [dataType]="dataType" [playlistId]="playlistId" [dataSelect]="true" (onDataSelect)="onDataSelect($event)" [dataClass]="dataClass" (onTableDataChange)="onTableDataChange($event)"></app-data-folder>
        <div class="loading-upload" *ngIf="loading">
            <div>
                <div>Adding media to your drive</div>
                <mat-spinner [diameter]="25"></mat-spinner>
            </div>
        </div>
    </div>
</div>