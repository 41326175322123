import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-web-device',
  templateUrl: './web-device.component.html',
  styleUrls: ['./web-device.component.scss']
})
export class WebDeviceComponent implements OnDestroy, OnInit {
  ngOnDestroy() {
    if (document.getElementsByTagName('flt-glass-pane').length > 0) {
      //@ts-ignore
      document.getElementsByTagName('flt-glass-pane')[0].style.display = 'none';
    }

    //@ts-ignore
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
  }

  constructor() {
  }


  ngOnInit(): void {
    this.waitForEditor();
  }

  private waitForEditor() {
    //@ts-ignore
    if (typeof LayerAPI != 'undefined' && typeof LayerAPI.selectLayer === 'function') {
      if (document.getElementsByTagName('flt-glass-pane').length > 0) {
        //@ts-ignore
        document.getElementsByTagName('flt-glass-pane')[0].style.display = 'block';
      }
      //@ts-ignore
      document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    } else {
      setTimeout(() => {
        this.waitForEditor();
      }, 100);
    }
  }
}
