import { NgModule, NgZone } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditorModule } from '../editor/editor.module';

import { AccountRoutingModule } from './account-routing.module';
import { AccountComponent } from './account.component';
import { DashboardComponent } from '../components/dashboard/dashboard.component';
import { DataComponent } from '../components/data/data.component';
import { DatarowComponent } from '../components/datarow/datarow.component';
import { DesignsComponent } from '../components/designs/designs.component';
import { DisplaysComponent } from '../components/displays/displays.component';
import { PlaylistsComponent } from '../components/playlists/playlists.component';
import { SettingsComponent } from '../components/settings/settings.component';
import { FirmwareComponent } from '../components/firmware/firmware.component';

import { SharedModule } from '../../shared/shared/shared.module';

@NgModule({
  declarations: [
    AccountComponent,
    DashboardComponent,
    DataComponent,
    DatarowComponent,
    DesignsComponent,
    DisplaysComponent,
    PlaylistsComponent,
    SettingsComponent,
    FirmwareComponent
  ],
  imports: [
    CommonModule,
    AccountRoutingModule,
    EditorModule,
    SharedModule
  ],
  exports: [
    SharedModule
  ]
})
export class AccountModule {


}