<div class="modal-main" cdkDrag [style.width]="data.width+'px'" [style.height]="data.height+'px'">
    <div class="modal-head" cdkDragHandle>
        <div class="modal-title">{{data.title}}</div>
        <mat-icon class="action-icons close-action" mat-dialog-close>close</mat-icon>
    </div>
    <mat-progress-bar mode="indeterminate" [style.opacity]="isLoading? 1 : 0"></mat-progress-bar>
    <div *ngIf="isCopying" class="center">Copying design(s) please wait.</div>
    <div *ngIf="!isCopying" class="modal-body">
       <table class="table">
            <thead>
                <tr>
                    <th>Account name</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let account of accounts">
                    <td class="center">{{account.info.name}}</td>
                    <td><button class="btn" (click)="onSelectAccount(account)">Select</button></td>
                </tr>
            </tbody>
        </table>
    </div>
</div>