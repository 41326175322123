<div class="main-timeline">
    <div class="top-timeline">
        <div style="width: 20%;"></div>
        <mat-icon class="action-icons" (click)="createKeyFrame()">animation</mat-icon>
        <mat-icon [class.isAnimating]="editorService.isAnimating" class="action-icons" (click)="playAnimations()">play_arrow</mat-icon>
        <mat-icon class="action-icons" (click)="stopAnimations()">stop</mat-icon>
        <div>
            <input type="number" class="form-control designTime" [(ngModel)]="displayTime">
        </div>
        <div style="width: 100%; display: flex; justify-content: flex-end;">
            <div style="display:flex;">
                <div style="display: flex; align-items: center;" *ngIf="editorService.layersSelected && editorService.animationSelectedGroup == 0 && editorService.selectedLayer.widget.childWidget.type == 3">
                    <div>Typing animation</div>
                    <mat-icon *ngIf="isCustomTypingAnimation()" class="action-icons" (click)="toggleAnimationCustom()">check_box</mat-icon>
                    <mat-icon *ngIf="!isCustomTypingAnimation()" class="action-icons" (click)="toggleAnimationCustom()">check_box_outline_blank</mat-icon>
                </div>
                <div style="display: flex; align-items: center;" *ngIf="editorService.layersSelected && editorService.animationSelectedGroup == 1">
                    <div>Auto end time</div>
                    <mat-icon *ngIf="editorService.selectedLayer.widget.useDisplayTime" class="action-icons" (click)="setUseDisplayTime()">check_box</mat-icon>
                    <mat-icon *ngIf="!editorService.selectedLayer.widget.useDisplayTime" class="action-icons" (click)="setUseDisplayTime()">check_box_outline_blank</mat-icon>
                </div>
                <div style="display: flex; align-items: center;" *ngIf="editorService.layersSelected && this.selectedKeyFrame">
                    <div>Wait frame</div>
                    <mat-icon *ngIf="editorService.selectedLayer.widget.playlistKeyFrameSwitch == this.selectedKeyFrame.keyFrameIndex" class="action-icons" (click)="setAnimationIndex()">check_box</mat-icon>
                    <mat-icon *ngIf="editorService.selectedLayer.widget.playlistKeyFrameSwitch != this.selectedKeyFrame.keyFrameIndex" class="action-icons" (click)="setAnimationIndex()">check_box_outline_blank</mat-icon>
                </div>

                <select class="form-select" [(ngModel)]="selectedAnimationCurve" (change)="updateAnimationCurve()" style="padding: 0; padding-right: 2em; padding-left: 0.5em; border: 0; width: 10em;">
                <option [value]="'linear'">linear</option>
                <option [value]="'fastOutSlowIn'">fastOutSlowIn</option>                
                <option [value]="'slowMiddle'">slowMiddle</option>
                <option [value]="'bounceIn'">bounceIn</option>
                <option [value]="'bounceOut'">bounceOut</option>
                <option [value]="'bounceInOut'">bounceInOut</option>
                <option [value]="'elasticIn'">elasticIn</option>
                <option [value]="'elasticOut'">elasticOut</option>
                <option [value]="'elasticInOut'">elasticInOut</option>
                <option [value]="'ease'">ease</option>
                <option [value]="'easeIn'">easeIn</option>
                <option [value]="'easeInToLinear'">easeInToLinear</option>
                <option [value]="'easeInSine'">easeInSine</option>
                <option [value]="'easeInQuad'">easeInQuad</option>
                <option [value]="'easeInCubic'">easeInCubic</option>
                <option [value]="'easeInQuart'">easeInQuart</option>
                <option [value]="'easeInQuint'">easeInQuint</option>
                <option [value]="'easeInExpo'">easeInExpo</option>
                <option [value]="'easeInCirc'">easeInCirc</option>
                <option [value]="'easeInBack'">easeInBack</option>
                <option [value]="'easeOut'">easeOut</option>
                <option [value]="'linearToEaseOut'">linearToEaseOut</option>
                <option [value]="'easeOutSine'">easeOutSine</option>
                <option [value]="'easeOutQuad'">easeOutQuad</option>
                <option [value]="'easeOutCubic'">easeOutCubic</option>
                <option [value]="'easeOutQuart'">easeOutQuart</option>
                <option [value]="'easeOutQuint'">easeOutQuint</option>
                <option [value]="'easeOutExpo'">easeOutExpo</option>
                <option [value]="'easeOutCirc'">easeOutCirc</option>
                <option [value]="'easeOutBack'">easeOutBack</option>
                <option [value]="'easeInOut'">easeInOut</option>
                <option [value]="'easeInOutSine'">easeInOutSine</option>
                <option [value]="'easeInOutQuad'">easeInOutQuad</option>
                <option [value]="'easeInOutCubic'">easeInOutCubic</option>
                <option [value]="'easeInOutQuart'">easeInOutQuart</option>
                <option [value]="'easeInOutQuint'">easeInOutQuint</option>
                <option [value]="'easeInOutExpo'">easeInOutExpo</option>
                <option [value]="'easeInOutCirc'">easeInOutCirc</option>
                <option [value]="'easeInOutBack'">easeInOutBack</option>                
                
              </select>
            </div>
        </div>
    </div>
    <div class="timeline-container">
        <div class="left-timeline">
            <div [class.selectGroup]="editorService.animationSelectedGroup == i" class="timeline-animationgroup" *ngFor="let group of editorService.animationGroups; let i = index">
                <div (click)="selectLayerGroup(i)">{{group.name}}</div>
            </div>
        </div>
        <div class="left-timeline-layers">
            <div class="left-timeline-layers-padding"></div>
            <div [class.selectLayer]="layer.widget.selected" class="timeline-animation-layers" *ngFor="let layer of editorService.animationLayers;" (click)="editorService.selectLayer(layer)">
                <div style="overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;">{{layer.name}}</div>
            </div>
        </div>
        <div class="right-timeline" id="timeline"></div>
    </div>
</div>