<div class="modal-main" cdkDrag>
    <div class="modal-head" cdkDragHandle>
        <div class="modal-title">{{data.name}}</div>
        <mat-icon class="action-icons close-action" (click)="close()">close</mat-icon>
    </div>
    <div class="modal-body">
        <div class="table-top-buttons">
            <div>
                <div class="settings-tabs">
                    <button [ngStyle]="{'background-color': isTab != 'feed' ? '#434343' : 'unset'}" *ngIf="isFeed" mat-stroked-button (click)="isTab = 'feed'">Feed</button>
                    <button [ngStyle]="{'background-color': isTab == 'feed' ? '#434343' : 'unset'}" *ngIf="isFeed" mat-stroked-button (click)="isTab = 'settings'">Settings</button>
                </div>
            </div>
            <div class="feed-buttons" *ngIf="isTab == 'feed' && !isFeedLink">
                <button mat-stroked-button (click)="edit()">
                    edit columns</button>
                <button *ngIf="!isFeed" mat-stroked-button (click)="create()">
                    add item</button>

                <button *ngIf="!isFeed" class="right-menu" mat-button [matMenuTriggerFor]="menu"><mat-icon>more_vert</mat-icon></button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="deleteSelected()"><mat-icon>delete</mat-icon>Delete</button>
                </mat-menu>
            </div>
        </div>
        <mat-progress-bar mode="indeterminate" [style.opacity]="isLoadingResults ? 1 : 0"></mat-progress-bar>
        <div class="mat-elevation-z8 table-parent" *ngIf="isTab == 'feed'">
            <table cdkDropList mat-table [dataSource]="dataSource" [trackBy]="trackByData" (cdkDropListDropped)="drop($event)">

                <ng-container [matColumnDef]="column.index" *ngFor="let column of columns; let i = index">
                    <th mat-header-cell *matHeaderCellDef [disabled]="column.index == 'select' || column.index == 'actions'">
                        <mat-checkbox class="" [ngClass]="{'select-all-checkbox': !isFeed}" tabindex="-1" *ngIf="column.index == 'select' && !isFeed" (change)="$event ? toggleAllRows() : null" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
                        </mat-checkbox>
                        {{column.index == 'select' || column.index == 'actions' && isFeed ? '' : column.name}}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="select-drag" *ngIf="column.index == 'select' && !isFeed">
                            <mat-icon cdkDragHandle *ngIf="!isFeed">drag_indicator</mat-icon>
                            <mat-checkbox tabindex="-1" (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(element) : null" [checked]="selection.isSelected(element)" [aria-label]="checkboxLabel(element)" (mousedown)="$event.stopPropagation()">
                            </mat-checkbox>
                        </div>

                        <div *ngIf="column.index !='select' && column.index !='actions'" (mousedown)="$event.stopPropagation()">
                            <div *ngIf="column.fieldName == 'Video' || column.fieldName == 'Image'" (click)="selectMedia(column.fieldName, element, i-1)">
                                <div *ngIf="element.data[i-1].selected_class && element.data[i-1].selected_class.name =='Image'">
                                    <img crossorigin="anonymous" class="thumb-image" [src]="element.data[i-1].meta.url_thumb" />
                                </div>
                                <div *ngIf="element.data[i-1].selected_class && element.data[i-1].selected_class.name =='Video'">
                                    <img crossorigin="anonymous" class="thumb-image" [src]="element.data[i-1].meta.url_thumb" />
                                </div>
                                <div class="btn" *ngIf="!isFeed">Select {{column.fieldName}}</div>
                            </div>
                            <div *ngIf="column.index != 'mutationDate' && column.fieldName != 'Video' && column.fieldName != 'Image'" (input)="onItemChange($event, element, i-1)" [textContent]="element.data[i-1]" [attr.contenteditable]="!isFeed ? 'true' : 'false'" style="display: block;"></div>
                            <span *ngIf="column.index == 'mutationDate'">{{element.data[i] | date }}</span>
                        </div>

                        <div *ngIf="column.index == 'actions' && !isFeed">
                            <button tabindex="-1" mat-icon-button (click)="delete(element)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                <tr cdkDrag [cdkDragDisabled]="isFeed" mat-row *matRowDef="let row; columns: columnsToDisplay;" class="feed-row"></tr>

                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="50" *ngIf="!isLoadingResults">
                        <div class="noresults">
                            <span *ngIf="searchQuery">No results found. Please try a different search.</span>
                            <span *ngIf="searchQuery == '' && !dataSelect">
                            Click <button *ngIf="columns.length <= 2"  mat-stroked-button (click)="edit()">Create columns</button> <button *ngIf="columns.length > 2"  mat-stroked-button (click)="create()">Create New item</button> to get started!
                            </span>
                        </div>
                    </td>
                </tr>
            </table>

            <mat-paginator [length]="resultsLength" [pageSizeOptions]="[50, 100, 150]" aria-label="Select page of items"></mat-paginator>
        </div>
        <div class="mat-elevation-z8 table-parent" *ngIf="isTab != 'feed'">
            <table class="table">
                <tr>
                    <td style="width: 10em;">Name:</td>
                    <td style="float: left;"><input class="form-control feed-name" placeholder="Name" [(ngModel)]="data.name" /></td>
                </tr>
                <tr *ngIf="data.feed">
                    <td style="width: 10em;">Last update:</td>
                    <td><span style="float: left;">{{getLastUpdateHuman(data.feed.mutationDate)}}</span></td>
                </tr>
                <tr *ngIf="data.feed_link">
                    <td style="width: 10em;">Last update:</td>
                    <td><span style="float: left;">{{getLastUpdateHuman(data.feed_link.mutationDate)}}</span></td>
                </tr>
                <tr>
                    <td style="width: 10em;">Tags:</td>
                    <td >
                        <div class="tags-list">
                            <div *ngFor="let tag of tags; let i2 = index">
                                <span class="tag"><input class="form-control" [(ngModel)]="tag.value" /><button mat-stroked-button (click)="tags.splice(i2,1)">Remove</button></span>
                            </div>
                            <button mat-stroked-button (click)="addTag()">Add tag</button>
                        </div>
                    </td>
                </tr>
                <tr *ngIf="data.feed && data.feed.meta.lastErrors">
                    <td>Last error:</td>
                    <td style="float: left;">
                        <span *ngIf="data.feed.meta.lastErrors.length == 0">No errors</span>
                        <span *ngIf="data.feed.meta.lastErrors.length > 0">{{data.feed.meta.lastErrors[0].message}}</span>
                    </td>
                </tr>
            </table>
            <div>
                <button mat-stroked-button (click)="save()">Save</button>
            </div>
        </div>
    </div>
    <!-- <div class="actions">
        <button mat-button (click)="close()">Close</button>
    </div> -->
</div>

<div class="edit-container">
    <app-data-feed-edit (onDataChange)="onEdit($event)" *ngIf="selected.selected" [data]="data"></app-data-feed-edit>
    <app-media-select *ngIf="editorService.overlay.drive" [playlistId]="data.id" [isEditor]="false" (onCloseMedia)="onCloseMedia($event)" (onSelectMedia)="onSelectMedia($event)" [dataType]="editorService.overlay.driveTypes"></app-media-select>
</div>