import { Component, Inject } from '@angular/core';
import { EditComponent } from '../edit/edit.component';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from 'src/app/api/api.service';
import { EditItem } from 'src/app/models/edit-item';
import { PopupDialog } from 'src/app/popups/confirm/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { Data } from 'src/app/api/models/account/data/data';
import { Account } from 'src/app/api/models/account/account';

@Component({
  selector: 'app-copy-data',
  templateUrl: './copy-data.component.html',
  styleUrls: ['./copy-data.component.scss']
})
export class CopyDataComponent {
  window: any = window;
  accounts: Array<Account>;
  isLoading = true;
  isCopying = false;

  constructor(
    public dialogRef: MatDialogRef<EditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EditItem,
    private apiService: ApiService,
    private authService: AuthService,
    private _snackBar: MatSnackBar, private dialog: MatDialog) {

    this.authService.getAllAccounts().then((accounts) => {
      this.accounts = accounts;
     
      //add 100 testing accounts
      // for (let i = 0; i < 100; i++) {
      //   let account: Account = {
      //     accountUsers: [],
      //     firmware_admin: false,
      //     global_design: false,
      //     global_feed: false,
      //     groups: [],
      //     mutationDate: new Date(),
      //     settings: {
      //       allowedDisplays: 0,
      //       allowedFeeds: 0,
      //       allowedMB: 0,
      //       id: i,
      //       mutationDate: new Date(),
      //     },
      //     id: i,
      //     info: {
      //       name: 'Account ' + i,
      //       id: i,
      //       mutationDate: new Date(),
      //     }
      //   };
      //   this.accounts.push(account);
      // }

      this.isLoading = false;
    }).catch((error) => {
      this._snackBar.open('Error getting accounts', 'Dismiss', {
        duration: 2000,
      });
      this.isLoading = false;
    });
  }

  onSelectAccount(account: Account) {
    this.dialog.open(PopupDialog, {
      data: {
        title: 'Copy content',
        message: 'Are you sure you want to copy the contents to the account "' + account.info.name + '"?'
      }
    }).afterClosed().subscribe(async (resultCheck: any) => {
      if (resultCheck) {
        this.isLoading = true;
        this.isCopying = true;
        try {
          for (let i = 0; i < this.data.data.length; i++) {
            let item: Data = this.data.data[i];
            await this.apiService.copyData(this.authService.account.id, item.id, account.id).toPromise();
          }
          this.dialogRef.close();
          this._snackBar.open('Content copied', 'Dismiss', {
            duration: 2000,
          });
        } catch (error) {
          this._snackBar.open('Error copying content', 'Dismiss', {
            duration: 2000,
          });
          this.isLoading = false;
          this.isCopying = false;
          return;
        }
      }
    });

  }
}
