import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, tap } from 'rxjs';
import { ApiService } from '../api/api.service';
import { Account } from '../api/models/account/account';
import { Partner } from '../api/models/partner/partner';
import { User } from '../api/models/user/user';
import { UserRole } from '../enums/user-role';
import { PartnerResult } from '../api/models/partner/partnerResult';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user: User | null = null;
  account: Account | null = null;
  partner: Partner | null = null;

  public role: UserRole = UserRole.Account;
  selectedAccountId: number | null = null;
  selectedPartnerId: number | null = null;

  accountsCache: Account[] = [];
  partnersCache: Partner[] = [];

  public onInitCallBack = () => { };
  client_id: string;
  response_type: string;
  scope: string;
  state: string;
  redirect_uri: string;
  isOauth: boolean;

  constructor(private api: ApiService, private router: Router) {
    api.onSessionStop = () => {
      if (!this.isOauth) {
        this.router.navigate(['/login']);
      }
      localStorage.setItem('auth-token', '');
      api.setToken('');
    };
    api.setToken(localStorage.getItem('auth-token')!);
  }

  getAccount(refresh?:boolean): Observable<User> {
    return new Observable<User>((observer) => {
      if (this.user == null || refresh) {
        this.api.getUserStatus().subscribe(user => {
          this.user = user;
          this.selectAccount(-1).then(() => {
            observer.next(this.user);
          });
        }, error => { observer.error(error); });
      } else {
        observer.next(this.user);
      }
    });
  }

  logOut() {
    this.account = null;
    this.selectedAccountId = null;
    this.accountsCache = [];
    this.role = UserRole.Account;
    localStorage.setItem('auth-token', '');
    this.api.setToken('');
    window.location.reload();
  }

  getAccountBasePath() {
    return "/account/" + this.selectedAccountId;
  }

  getPartnerBasePath() {
    return "/partner/" + this.selectedPartnerId;
  }

  updateUser() {
    return this.api.getUserStatus().pipe(tap(user => {
      this.user = user;
      this.selectAccount(-1);
    }));
  }

  async selectAccount(id: number) {
    this.account = null;
    this.selectedAccountId = null;
    if (this.user != null) {
      if (this.accountsCache.length == 0) {
        let result = await this.api.listAccounts({
          query: '',
          limit: 1000,
          start: 0,
          order: 'asc',
          orderField: 'id',
        }).toPromise();
        this.accountsCache = result.results;
      }
      for (let i = 0; i < this.accountsCache.length; i++) {
        const accountUser = this.accountsCache[i];
        if (accountUser.id == id) {
          this.selectedAccountId = id;
          this.account = accountUser;
          this.role = UserRole.Account;
          break;
        }
      }

      if(this.account == null && id > 0){
        try{
          this.account = await this.api.getAccount(id).toPromise();
          this.selectedAccountId = this.account.id;
          this.role = UserRole.Account;
        }catch(e){

        }
      }

      if (this.account == null && this.accountsCache.length > 0) {
        this.account = this.accountsCache[0];
        this.selectedAccountId = this.account.id;
        this.role = UserRole.Account;
      }
    }
  }

  async refreshPartnerCache(){
    this.partnersCache = [];
    await this.selectPartner(this.selectedPartnerId);
  }

  async selectPartner(id: number) {
    this.partner = null;
    this.selectedPartnerId = null;
    if (this.user != null) {
      if (this.partnersCache.length == 0) {
        let result: PartnerResult = await this.api.listPartnerAccounts({
          query: '',
          limit: 1000,
          start: 0,
          order: 'asc',
          orderField: 'id',
        }).toPromise();
        this.partnersCache = result.results;
      }
      for (let i = 0; i < this.partnersCache.length; i++) {
        const partnerUser = this.partnersCache[i];
        if (partnerUser.id == id) {
          this.selectedPartnerId = id;
          this.partner = partnerUser;
          this.role = UserRole.Partner;
          break;
        }
      }
      if (this.partner == null && this.partnersCache.length > 0) {
        this.partner = this.partnersCache[0];
        this.selectedPartnerId = this.partner.id;
        this.role = UserRole.Partner;
      }
    }
  }

  oAuthCheck() {
    const urlParams = new URLSearchParams(window.location.search);
    this.client_id = urlParams.get('client_id');
    this.response_type = urlParams.get('response_type');
    this.scope = urlParams.get('scope');
    this.state = urlParams.get('state');
    this.redirect_uri = urlParams.get('redirect_uri');
    if (this.client_id != null && this.response_type != null && this.scope != null && this.state != null && this.redirect_uri != null) {
      this.isOauth = true;
    } else {
      this.isOauth = false;
    }
  }

  async getAllAccounts(): Promise<Account[]> {
    let accounts: Account[] = [];
    let result = await this.api.listAccounts({
      query: '',
      limit: 1000,
      start: 0,
      order: 'asc',
      orderField: 'id',
    }).toPromise();
    accounts = result.results;

    let resultPartners = await this.api.listPartnerAccounts({
      query: '',
      limit: 1000,
      start: 0,
      order: 'asc',
      orderField: 'id',
    }).toPromise();

    for (let resultPartner of resultPartners.results) {
      let resultAccount = await this.api.partnerListAccounts(resultPartner.id, {
        query: '',
        limit: 1000,
        start: 0,
        order: 'asc',
        orderField: 'id'
      }).toPromise();
      for (let account of resultAccount.results) {
        let found = false;
        for (let account_ of accounts) {
          if (account.id == account_.id) {
            found = true;
            break;
          }
        }
        if (!found)
          accounts.push(account);
      }
    }

    return accounts
  }
}
