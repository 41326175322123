import { Component } from '@angular/core';
import { ApiService } from 'src/app/api/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { User } from 'src/app/api/models/user/user';
import { AccountGroup } from 'src/app/api/models/account/accountGroup';
import { AccountUser } from 'src/app/api/models/account/accountUser';
import { MatDialog } from '@angular/material/dialog';
import { PopupDialog } from 'src/app/popups/confirm/dialog';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent {
  roles: AccountGroup[] = [];
  invites: any[] = [];
  email: string = '';

  constructor(public authService: AuthService, private apiService: ApiService, private _snackBar: MatSnackBar, private dialog: MatDialog) {
    this.apiService.listAccountRoles(this.authService.account.id).subscribe(roles => {
      this.roles = roles;
    });
    this.authService.account.accountUsers.forEach(user => {
      user.user.roles = this.getListOfRoles(user).map(role => { return role.id; });
      user.user.isAdmin = user.user.roles.includes(1);
    });

    this.refreshInvites();
   }

   refresh() {
    this.apiService.getAccount(this.authService.account.id).subscribe(account => {
      this.authService.account = account;
      this.authService.account.accountUsers.forEach(user => {
        user.user.roles = this.getListOfRoles(user).map(role => { return role.id; });
        user.user.isAdmin = user.user.roles.includes(1);
      });
      this.refreshInvites();
    });
  }

   refreshInvites() {
    this.apiService.getAccountInvites(this.authService.account.id).subscribe(invites => {
      this.invites = invites;
    });
  }

  currentOption: string = 'info';

  currentLocation() {
    return this.authService.getAccountBasePath() + '/settings';
  }

  saveAccountInfo() {
    this.apiService.updateAccountInfo(this.authService.account.id, this.authService.account.info.name).subscribe(result => {
      this._snackBar.open('Account info updated', '', {
        duration: 3000,
      });
    });
  }

  getListOfRoles(user: AccountUser) {
    let roles = [];
    user.groups.forEach(group => {
      roles.push(group.group);
    });
    return roles;
  }

  getRolesAsString(user: any) {
    let roles = this.getListOfRoles(user);
    let list = [];
    roles.forEach(role => {
      if(list.indexOf(role.name) == -1)
      list.push(role.name);
    });
    return list.map(role => { return role; }).join(', ');
  }

  onRolesChange(user: User) {
    this.apiService.updateUserRoles(this.authService.account.id, user.id, user.roles).subscribe(result => {
      this._snackBar.open('User roles updated', '', {
        duration: 3000,
      });
    }
    );
  }

  addUser() {
    this.apiService.addUserToAccount(this.authService.account.id, this.email).subscribe(result => {
      this._snackBar.open('User invited', '', {
        duration: 3000,
      });
      this.email = '';
      this.refresh();
    }, error => {
      this._snackBar.open(error, '', {
        duration: 3000,
        });
    });
  }

  deleteUser(id:number) {
    this.dialog.open(PopupDialog, {
      data: {
        title: 'Remove user',
        message: 'Are you sure you want to remove this user?'
      }
    }).afterClosed().subscribe((result: any) => {
      if (result) {
        this.apiService.removeUserFromAccount(this.authService.account.id, id).subscribe(result => {
          this._snackBar.open('User deleted', '', {
            duration: 3000,
          });
          this.refresh();
        });
      }
    }); 
  }

  deleteInvite(id:number) {
    this.dialog.open(PopupDialog, {
      data: {
        title: 'Remove user',
        message: 'Are you sure you want to remove this user?'
      }
    }).afterClosed().subscribe((result: any) => {
      if (result) {
        this.apiService.deleteAccountInvite(this.authService.account.id, id).subscribe(result => {
          this._snackBar.open('Invite deleted', '', {
            duration: 3000,
          });
          this.refresh();
        });
      }
    });
  }
  
}
