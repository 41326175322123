<h1 class="typeTitle" *ngIf="dataClass == dataClassType.Designs">Designs</h1>
<h1 class="typeTitle" *ngIf="dataClass == dataClassType.Playlists">Playlists</h1>

<mat-form-field style="width: 100%; padding-bottom: 1em;" *ngIf="dataClass != dataClassType.AuditLogs">
    <mat-label>Search</mat-label>
    <input class="input" matInput (keyup)="applyFilter($event)" placeholder="Search..." type="search" #input>
</mat-form-field>

<div class="table-top-buttons">
    <div>
        <div class="design-tabs" *ngIf="dataClass == dataClassType.Designs">
            <button [ngStyle]="{'background-color': globalDesign ? '#434343' : 'unset'}" mat-stroked-button (click)="globalDesign = false; updateWatch.emit({});">My Designs</button>
            <button [ngStyle]="{'background-color': !globalDesign ? '#434343' : 'unset'}" mat-stroked-button (click)="globalDesign = true; updateWatch.emit({});">Design library</button>
        </div>
    </div>

    <div style="display: flex;gap:0.5em" *ngIf="dataClass != dataClassType.AuditLogs">
        <div class="theme-selector" *ngIf="dataClass == dataClassType.Designs">
            <select class="form-select" [(ngModel)]="selectedThemeGroup" (change)="onThemeGroupChange()">
                <option [value]="''">All categories</option>
                <option *ngFor="let themeGroup of themeGroups" [value]="themeGroup">{{themeGroup}}</option>
            </select>

            <select *ngIf="this.selectedThemeGroup != ''" class="form-select" [(ngModel)]="selectedTheme" (change)="onThemeChange()">
                <option [value]="''">All themes</option>
                <option *ngFor="let theme of themes" [value]="theme">{{theme}}</option>
            </select>
        </div>
        <button *ngIf="!dataSelect && dataClass == dataClassType.Displays" mat-stroked-button (click)="startdevice()">
            start device</button>
        <button *ngIf="dataSelect" mat-stroked-button (click)="selectData()">
            Add {{selection.selected.length}} items</button>
        <button *ngIf="!dataSelect && !globalDesign" mat-stroked-button (click)="create()">
            create new {{getDataClassSingle()}}</button>
        <div *ngIf="!dataSelect && !globalDesign && dataClass != dataClassType.Accounts">
            <button class="right-menu" mat-button [matMenuTriggerFor]="menu"><mat-icon>more_vert</mat-icon></button>
            <mat-menu #menu="matMenu">
                <button *ngIf="dataClass == dataClassType.Designs" mat-menu-item (click)="copySelected()"><mat-icon>file_copy</mat-icon>Copy</button>
                <button *ngIf="dataClass == dataClassType.Displays" mat-menu-item (click)="moveSelected()"><mat-icon>file_copy</mat-icon>Move</button>
                <button mat-menu-item (click)="deleteSelected()"><mat-icon>delete</mat-icon>Delete selected</button>               
            </mat-menu>
        </div>
    </div>
</div>


<mat-progress-bar mode="indeterminate" [style.opacity]="isLoadingResults ? 1 : 0"></mat-progress-bar>
<div class="mat-elevation-z8" ngClass="{{getDataClass()}}">
    <table mat-table [dataSource]="dataSource" matSort>

        <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns">
            <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="column == 'select' || column == 'actions'">
                <mat-checkbox *ngIf="column == 'select'" (change)="$event ? toggleAllRows() : null" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
                </mat-checkbox>
                {{column == 'select' ? '' : columnNames(column)}}
            </th>
            <td mat-cell *matCellDef="let element">
                <mat-checkbox *ngIf="column == 'select'" (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(element) : null" [checked]="selection.isSelected(element)" [aria-label]="checkboxLabel(element)">
                </mat-checkbox>

                <div *ngIf="column !='select'">
                    <span *ngIf="column == 'name' && dataClass == dataClassType.Designs">
                        <div class="screenshot {{element.data.orientation}}" *ngIf="!dataSelect" (click)="loadEdit(element.id)">
                            <img crossorigin="anonymous" *ngIf="element.data.screenshot != null" [src]="element.data.screenshot">
                            <div class="image-placeholder" *ngIf="element.data.screenshot == null"></div>
                            <div class="theme-name">{{element[column]}}</div>
                        </div>
                        <div class="screenshot {{element.data.orientation}}" *ngIf="dataSelect" (click)="onSelectData(element)">
                            <img crossorigin="anonymous" *ngIf="element.data.screenshot != null" [src]="element.data.screenshot">
                            <div class="image-placeholder" *ngIf="element.data.screenshot == null"></div>
                            <div class="theme-name">{{element[column]}}</div>
                        </div>
                    </span>

                    <span *ngIf="dataClass == dataClassType.Designs && column != 'mutationDate' && column != 'name' && column != 'data' && column != 'user'">{{element[column]}}</span>
                    <span *ngIf="column == 'user'">{{element.user.name}}</span>
                    <div *ngIf="column == 'data'">
                        <button class="btn" (click)="element.moreinfo = !element.moreinfo">More info</button>
                        <div class="log-info" *ngIf="element.moreinfo">
                            {{element.data | json}}
                        </div>
                    </div>

                    <span *ngIf="column == 'info' && dataClass == dataClassType.Accounts">{{element.info.name}}</span>
                    <span *ngIf="column != 'data' && column != 'user' && column != 'mutationDate' && column != 'status' && dataClass != dataClassType.Designs && dataClass != dataClassType.Accounts">{{element[column]}}</span>
                    <span *ngIf="column == 'status' && dataClass == dataClassType.Firmware">
                    <span *ngIf="element[column] == 0">None</span>
                    <span *ngIf="element[column] == 1">Beta</span>
                    <span *ngIf="element[column] == 2">Live</span>
                    </span>

                    <span *ngIf="column == 'mutationDate'">{{element[column] | date }}</span>

                </div>
                <div *ngIf="column == 'actions' && dataClass != dataClassType.AuditLogs" class="actions-list">
                    <div *ngIf="dataClass == dataClassType.Displays && !dataSelect">
                        <button mat-button (click)="openLinkDisplay(element)" *ngIf="element.device == null">
                            Link device
                        </button>
                        <button mat-button (click)="openDeviceInfo(element)" *ngIf="element.device != null">
                            Device info
                        </button>
                    </div>


                    <button mat-button (click)="clone(element)" *ngIf="dataClass == dataClassType.Designs && globalDesign">
                        Copy
                    </button>
                    <button mat-icon-button (click)="loadEdit(element.id)" *ngIf="dataClass == dataClassType.Designs && !dataSelect && !globalDesign && dataClass != dataClassType.Accounts">
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button mat-icon-button (click)="edit(element)" *ngIf="dataClass != dataClassType.Designs && !dataSelect">
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button *ngIf="!dataSelect && !globalDesign && dataClass != dataClassType.Accounts" mat-icon-button (click)="delete(element)">
                        <mat-icon>delete</mat-icon>
                    </button>
                    <button *ngIf="dataSelect" mat-stroked-button (click)="onSelectData(element)">
                        select
                    </button>
                    <button *ngIf="dataClass == dataClassType.Accounts && element.id != 1" mat-stroked-button (click)="openAccount(element.id)">
                        Open
                    </button>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="10">
                <div class="noresults">
                    <span *ngIf="searchQuery">No results {{getDataClass()}} found. Please try a different search.</span>
                    <span *ngIf="searchQuery == '' && !dataSelect && dataClass == dataClassType.AuditLogs">No Logs</span>
                    <span *ngIf="searchQuery == '' && !dataSelect && dataClass != dataClassType.AuditLogs">
                       No {{getDataClass()}} found. Click <button  mat-stroked-button (click)="create()">Create New {{getDataClassSingle()}}</button> to get started!
                    </span>
                </div>
            </td>
        </tr>
    </table>

    <mat-paginator [length]="resultsLength" [pageSizeOptions]="[50, 75, 100]" aria-label="Select page of designs"></mat-paginator>
</div>

<div>
    <app-connect-device *ngIf="linkDisplay" [display]="selectedDisplay" (onClose)="onSelectedDisplayClose($event)"></app-connect-device>
    <app-device-info *ngIf="deviceInfo" [display]="selectedDisplay" (onClose)="onDeviceInfoClose($event)"></app-device-info>
</div>