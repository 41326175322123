import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/api/api.service';
import { LoginResponse } from 'src/app/api/models/user/login-response';
import { LoginStatus } from 'src/app/api/models/user/login-status';
import { User } from 'src/app/api/models/user/user';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  email: string = '';

  waitingForLogin: boolean = false;
  loginResponseCheck: LoginResponse | null = null;
  loading: boolean = false;
  initLoading: boolean = true;
  loginTimer: any = null;

  setupProfile: boolean = false;
  user: User | null = null;

  constructor(private apiService: ApiService, public authService: AuthService, private router: Router, private _snackBar: MatSnackBar) {
    //get oath2 client_id,response_type,scope,state,redirect_uri from url
    authService.oAuthCheck();

    this.authService.getAccount().subscribe((user: User) => {
      this.initLoading = false;
      this.user = user;
      if (user.alias == null || user.alias == '') {
        this.setupProfile = true;
      } else {
        if (!this.authService.isOauth) {
          this.setupOrContinue();
        }
      }
    }, (error) => {
      this.initLoading = false;
    });
  }

  logout() {
    this.authService.logOut();
  }

  oAuthApprove() {
    this.setupOrContinue();
  }

  login() {
    this.loading = true;
    this.apiService.login('email', this.email).subscribe((loginResponse: LoginResponse) => {
      this.waitingForLogin = true;
      this.loginResponseCheck = loginResponse;
      this.loginCheck();
    }, (error) => {
      this.loading = false; this.waitingForLogin = false; this._snackBar.open(error, '', {
        duration: 3000,
      });
    });
  }

  loginCheck() {
    this.loginTimer = setTimeout(() => {
      if (this.loginResponseCheck == null) { return; }
      this.apiService.getLoginStatus(this.loginResponseCheck.checkToken).subscribe((loginStatus: LoginStatus) => {
        if (loginStatus.approved) {
          localStorage.setItem('auth-token', loginStatus.token);
          this.apiService.setToken(loginStatus.token);
          this.authService.getAccount().subscribe((user: User) => {
            this.waitingForLogin = false;
            this.user = user;
            if (user.alias == null || user.alias == '') {
              this.setupProfile = true;
            } else {
              if (!this.authService.isOauth) {
                this.setupOrContinue();
              }
            }
          });
        } else {
          this.loginCheck();
        }
      });
    }, 1000);
  }

  setupOrContinue() {
    this.authService.selectAccount(-1).then(() => {
      if (this.authService.selectedAccountId == null) {
        if (this.authService.isOauth) {
          this.router.navigate(['/setup'], { queryParams: { client_id: this.authService.client_id, response_type: this.authService.response_type, scope: this.authService.scope, state: this.authService.state, redirect_uri: this.authService.redirect_uri} });
        } else {
          this.router.navigate(['/setup']);
        }
        return;
      } else {
        if (this.authService.isOauth) {
          this.apiService.createOath2Token(this.authService.scope, this.authService.client_id).subscribe((response: any) => {
            window.location.href = this.authService.redirect_uri + '?code=' + response.token + '&state=' + this.authService.state;
          });
        } else {
          this.router.navigate([this.authService.getAccountBasePath()]);
        }
      }
    });
  }

  cancelLogin() {
    this.loginResponseCheck = null;
    clearTimeout(this.loginTimer);
    this.waitingForLogin = false;
    this.loading = false;
  }

  saveProfile() {
    this.apiService.updateUser(this.user).subscribe(result => {
      this._snackBar.open('Profile updated', '', {
        duration: 3000,
      });
      this.setupOrContinue();
    }, error => {
      this._snackBar.open(error, '', {
        duration: 3000,
      });
    });
  }
}
