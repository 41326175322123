import { SelectionModel } from "@angular/cdk/collections";

export enum TableChangeDataActions {
    New,
    Edit,
    Delete,
    DeleteAll,
    Move,
    Copy,
}

export interface TableChangeData {
    action:TableChangeDataActions;
    selected: SelectionModel<any>
    item?: any;
}

