import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  Timeline,
  TimelineRow,
  TimelineModel,
  TimelineOptions,
  TimelineRowStyle,
  TimelineKeyframe,
} from "animation-timeline-js";
import { AnimationKeyframe } from '../../models/keyframe';
import { EditorService } from '../../services/editor.service';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.css']
})
export class TimelineComponent implements OnInit, OnDestroy {
  timeline: Timeline | null = null;
  timelineChangeDetector: any = [];
  updateTimeout: any = null;
  selectedKeyFrame: any | null = null;
  dragging: boolean = false;
  selectedAnimationCurve: string = "fastOutSlowIn";
  displayTime: number = 10;
  layerChangeCallback: any = null;
  updateDebounce: any = null;
  animationTicker: any = null;
  animationTickerValue: number = 0;
  wasPaused: boolean = false;
  lastSelectedGroup: number = 0;
  wasPlaying: boolean = false;

  constructor(public editorService: EditorService) {
    this.editorService.updateAnimationTimeline = this.updateTimeline.bind(this);
    this.editorService.play = this.playAnimations.bind(this);
    this.editorService.stop = this.stopAnimations.bind(this);
    this.editorService.playPause = this.playPauseAnimations.bind(this);
  }

  onFocus() {
    this.editorService.onTimelineFocus = true;
  }

  onBlur() {
    this.editorService.onTimelineFocus = false;
  }

  ngOnDestroy(): void {
    clearInterval(this.animationTicker);
    document.getElementById("timeline").removeEventListener("mouseenter", this.onFocus.bind(this));
    document.getElementById("timeline").removeEventListener("mouseleave", this.onBlur.bind(this));
  }

  selectLayerGroup(group: number) {
    this.lastSelectedGroup = group;
    //@ts-ignore
    AnimationAPI.selectGroup(group);
  }

  createKeyFrame() {
    if (this.timeline == null) return;
    //@ts-ignore
    AnimationAPI.createKeyframe(parseFloat(this.timeline.getTime()), this.selectedAnimationCurve);
  }

  playAnimations() {    
      // clearInterval(this.animationTicker);
      // this.animationTicker = setInterval(() => {
      //   if(!this.editorService.isAnimating){
      //     clearInterval(this.animationTicker);
      //   }
      //   if(!this.wasPaused){
      //     this.animationTickerValue += 50;
      //     this.timeline.setTime(this.animationTickerValue);
      //   }
      // }, 50);
    //@ts-ignore
    AnimationAPI.play(this.displayTime);
  }

  playPauseAnimations() {
    if(this.editorService.isAnimating){
      if(!this.wasPaused){
        //@ts-ignore
        AnimationAPI.pause();
        this.wasPaused = true;
      }else{
        //@ts-ignore
        AnimationAPI.resume();
        this.wasPaused = false;
      }
    }else{
      this.playAnimations();
    }
  }

  stopAnimations() {
    //@ts-ignore
    AnimationAPI.stop();
    clearInterval(this.animationTicker);
  }

  updateAnimationCurve() {
    if (this.selectedKeyFrame == null) return;
    this.editorService.updateAnimationCurve(this.selectedKeyFrame.id, this.selectedKeyFrame.keyFrameIndex, this.selectedAnimationCurve);
  }

  setAnimationIndex() {
    if (this.selectedKeyFrame == null) return;
    this.editorService.selectedLayer.widget.playlistKeyFrameSwitch = this.selectedKeyFrame.keyFrameIndex;
    //@ts-ignore
    LayerAPI.updatePlaylistKeyFrameSwitch(this.selectedKeyFrame.id, this.selectedKeyFrame.keyFrameIndex);
  }

  setUseDisplayTime() {
    if (this.editorService.selectedLayer == null) return;
    //@ts-ignore
    LayerAPI.updateUseDisplayTime(this.editorService.selectedLayer.widget.id, !this.editorService.selectedLayer.widget.useDisplayTime)
  }

  toggleAnimationCustom() {
    if (this.editorService.selectedLayer == null) return;
    if (this.editorService.selectedLayer.widget.animationCustom.animationLayer == "LayerText") {
      this.editorService.updateAnimationCustom(this.editorService.selectedLayer.widget.id, {
        animationLayer: "",
        animationName: "",
        animationType: ""
      });
    } else {
      this.editorService.updateAnimationCustom(this.editorService.selectedLayer.widget.id, {
        animationLayer: "LayerText",
        animationName: "typing",
        animationType: "typing"
      });
    }
  }

  isCustomTypingAnimation() {
    if (this.editorService.selectedLayer == null) return false;
    if (this.editorService.selectedLayer.widget.animationCustom.animationLayer == "LayerText") {
      return true;
    } else {
      return false;
    }
  }

  updateTimeline(selectionChanged: boolean = false) {
    if (this.timeline == null) return;
    clearTimeout(this.updateDebounce);
    if (selectionChanged) {
      this.selectedKeyFrame = null;
      this.timeline.select([]);
    }

    if(!this.editorService.isAnimating && this.wasPlaying){
      this.wasPlaying = false;
      setTimeout(() => {
        this.selectLayerGroup(this.lastSelectedGroup);
      }, 250);      
    }

    if(this.editorService.isAnimating){  
      this.wasPlaying = true;    
      this.animationTickerValue = 0;
    }else{
      this.wasPlaying = false;
    }
    
    this.updateDebounce = setTimeout(() => {
      let rows: any = [];

      this.editorService.animationLayers.forEach(layer => {
        let keyframes: AnimationKeyframe[] = layer.widget.keyFrames[this.editorService.animationSelectedGroup];

        if (keyframes == null) { return; }
        let keyFrameRow: any = { keyframes: [] };
        let keyFrameIndex = 0;
        keyframes.forEach(keyframe => {
          keyFrameRow.keyframes.push({ val: keyframe.time, layer: layer, keyframe: keyframe, keyFrameIndex: keyFrameIndex });
          keyFrameIndex++;
        });
        rows.push(keyFrameRow);
      });

      if (JSON.stringify(rows) !== JSON.stringify(this.timelineChangeDetector)) {
        this.timeline.setModel({ rows: rows });
        this.timelineChangeDetector = rows;
      }

      this.editorService.animationSelectedKeyframe = -1;
      if (this.selectedKeyFrame != null) {
        this.timeline.getAllKeyframes().forEach((keyframe: any) => {
          if (this.selectedKeyFrame != null && this.editorService.selectedLayer != null) {
            if (keyframe.layer.widget.id == this.selectedKeyFrame.id &&
              this.editorService.selectedLayer.widget.id == this.selectedKeyFrame.id &&
              keyframe.keyFrameIndex == this.selectedKeyFrame.keyFrameIndex) {
              let selectedList = this.timeline.getSelectedKeyframes();
              if (selectedList.length == 0) {
                this.editorService.animationSelectedKeyframe = keyframe.keyFrameIndex;
                setTimeout(() => {
                  this.timeline.select([keyframe]);
                }, 10);
              }
            }
          }
        });
      }
    }, 100);
  }

  ngOnInit() {
    const model = { rows: [] as Array<TimelineRow> } as TimelineModel;
    const options = {
      id: "timeline",
      fillColor: "#242424",
      headerFillColor: "#242424",
      labelsColor: "#6e6b6b",
      selectionColor: "#ffffff",
      tickColor: "#404040",

      rowsStyle: {
        height: 20,
        fillColor: "#343232",
        keyframesStyle: {
          fillColor: "green",
          selectedStrokeColor: "#ffffff",
          selectedFillColor: "#ffffff",
          shape: "circle",
        }
      } as TimelineRowStyle,
    } as TimelineOptions;

    this.timeline = new Timeline(options, model);
    this.timeline.setModel({ rows: [] });

    this.timeline.onDoubleClick((e: any) => {
      this.createKeyFrame();
    });

    this.timeline.onDragStarted((e: any) => {
      this.dragging = true;
    });

    this.timeline.onDragFinished((e: any) => {
      clearTimeout(this.updateTimeout);
      this.updateTimeout = setTimeout(() => {
        e.target.row.keyframes.forEach((keyframe: any) => {
          this.editorService.updateAnimation(keyframe.layer, keyframe.keyFrameIndex, keyframe.val);
        });
        this.dragging = false;
      }, 100);
    });

    this.timeline.onSelected((e: any) => {
      if (this.dragging) return;
      if (e.selected.length > 0) {
        if (this.selectedKeyFrame != null) {
          if (this.selectedKeyFrame.id == e.selected[0].layer.widget.id &&
            this.selectedKeyFrame.keyFrameIndex == e.selected[0].keyFrameIndex) {
            return;
          }
        }
        this.selectedKeyFrame = {
          id: e.selected[0].layer.widget.id,
          keyFrameIndex: e.selected[0].keyFrameIndex
        };
        this.editorService.selectKeyFrame(e.selected[0].layer, e.selected[0].keyFrameIndex);
        this.selectedAnimationCurve = e.selected[0].keyframe.curve;
        this.timeline.select([]);
      }
    });

    this.updateTimeline();

    document.getElementById("timeline").addEventListener("mouseenter", this.onFocus.bind(this));
    document.getElementById("timeline").addEventListener("mouseleave", this.onBlur.bind(this));
  }
}
