<div class="modal-main" cdkDrag>
    <div class="modal-head" cdkDragHandle>
        <div class="modal-title">Connect device</div>
        <mat-icon class="action-icons close-action" (click)="close()">close</mat-icon>
    </div>
    <div *ngIf="!connected" class="modal-body">
        <div class="info-device-code">
            Please enter the code displayed on the device
        </div>
        <input class="form-control" [formControl]="myInput" placeholder="123-123-123" [(ngModel)]="code">

        <button mat-button (click)="connectDevice()">
            Link device
        </button>

        <div class="error-message" *ngIf="notFound">{{errorMessage}}</div>
    </div>
    <div *ngIf="connected" class="modal-body">
        <div class="device-connected">
            The device is connected successfully!
        </div>
        <button mat-button (click)="close()">
            Close window
        </button>
    </div>
</div>