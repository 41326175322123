<div class="modal-main" cdkDrag>
    <div class="modal-head" cdkDragHandle>
        <div class="modal-title">Edit table</div>
        <mat-icon class="action-icons close-action" (click)="closeTable()">close</mat-icon>
    </div>
    <div class="modal-body">
        <div class="config-main">
            <table>
                <tr *ngFor="let item of displayedColumns; let i = index;">
                    <td>
                        <div class="mb-3">
                            <label for="input_selecttype_{{i}}" class="form-label">{{item.layerText.text}} - {{item.selectedFieldType}}</label>
                            <select class="form-select" id="input_selecttype_{{i}}" [(ngModel)]="item.selectedFieldType" (change)="onChangeField($event, item, i, true)">
                            <option  *ngFor="let field of fields" [value]="field.field_class+'_'+field.name">{{field.field_class}} - {{field.name}}</option>
                          </select>
                        </div>
                    </td>
                    <td>
                        <div class="mb-3">
                            <label for="table_column_alignment_{{i}}" class="form-label">Vertical alignment - {{item.vAlignment}}</label>
                            <select class="form-select" id="table_column_alignment_{{i}}" [(ngModel)]="item.vAlignment" (change)="onChangeField($event, item, i)">
                            <option [value]="'top'">Top</option>
                            <option [value]="'center'">Center</option>
                            <option [value]="'bottom'">Bottom</option>
                          </select>
                        </div>
                    </td>
                    <td>
                        <div class="mb-3">
                            <label for="table_column_width_{{i}}" class="form-label">Width</label>
                            <input id="table_column_width_{{i}}" type="number" class="form-control" placeholder="Column width" [(ngModel)]="item.width" (change)="onChangeField($event, item, i)">
                        </div>
                    </td>
                    <td>
                        <div class="mb-3" *ngIf="i == 0">
                            <label for="table_column_padding_{{i}}" class="form-label">Padding</label>
                            <input id="table_column_padding_{{i}}" type="number" class="form-control" placeholder="Padding" [(ngModel)]="item.padding" (change)="onChangeField($event, item, i)">
                        </div>
                    </td>
                    <td>
                        <button mat-icon-button (click)="deleteColumn(i)">
                                <mat-icon>delete</mat-icon>
                        </button>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</div>