import { FontStyle } from "../enum/font-style";
import { FontWeight } from "../enum/font-weight";
import { TextFit } from "../enum/text-fit";
import { TextOverflow } from "../enum/text-overflow";
import { WrapAlignment } from "../enum/wrap-alignment";

export class LayerText {
    text: string;
    color: { r: number, g: number, b: number, a: number };
    backgroundColor: { r: number, g: number, b: number, a: number };
    fontSize: number;
    fontStyle: FontStyle;
    fontWeight: FontWeight;
    fontFamily: string;
    letterSpacing: number;
    wordSpacing: number;
    overflow: TextOverflow;
    edgeInsets: { top: number, left: number, bottom: number, right: number };
    wrapAlignment: WrapAlignment;
    fit: TextFit.none;
    shadows: { offsetX: number; offsetY: number; blurRadius: number; color: { r: number; g: number; b: number; a: number; }; }[];


    constructor() {
        this.text = "";
        this.color = { r: 0, g: 0, b: 0, a: 1 };
        this.backgroundColor = { r: 0, g: 0, b: 0, a: 0 };
        this.fontSize = 20;
        this.fontStyle = FontStyle.normal;
        this.fontWeight = FontWeight.normal;
        this.fontFamily = "Roboto";
        this.letterSpacing = 0;
        this.wordSpacing = 0;
        this.overflow = TextOverflow.clip;
        this.edgeInsets = { top: 0, left: 0, bottom: 0, right: 0 };
        this.wrapAlignment = WrapAlignment.start;
        this.fit = TextFit.none;
    }
}
