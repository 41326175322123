import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from '../components/dashboard/dashboard.component';
import { PartnerComponent } from './partner.component';
import { SettingsComponent } from '../components/settings/settings.component';
import { AccountsComponent } from '../components/accounts/accounts.component';
import { AccountSettingsComponent } from '../components/account-settings/account-settings.component';

const routes: Routes = [
  {
    path: '',
    component: PartnerComponent,
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
      { path: 'dashboard', component: DashboardComponent },
      { path: 'settings', component: SettingsComponent },
      { path: 'accounts', component: AccountsComponent },
      { path: 'account/:id', component: AccountSettingsComponent },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PartnerRoutingModule { }
