import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EditorService } from 'src/app/account/editor/services/editor.service';
import { ApiService } from 'src/app/api/api.service';
import { DataClass } from 'src/app/enums/data-class';
import { TableChangeData, TableChangeDataActions } from 'src/app/models/table-change-data';
import { PopupDialog } from 'src/app/popups/confirm/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { DataFolderComponent } from '../../data-folder/data-folder.component';
import { EditComponent } from '../../edit/edit.component';
import { NewComponent } from '../../new/new.component';
import { Data } from 'src/app/api/models/account/data/data';
import { Composite } from 'src/app/api/models/account/display/composite';
import { Playlist } from 'src/app/api/models/account/display/playlist';

@Component({
  selector: 'app-data-selector',
  templateUrl: './data-selector.component.html',
  styleUrls: ['./data-selector.component.scss']
})
export class DataSelectorComponent {
  @ViewChild(DataFolderComponent) dataTableComponent!: DataFolderComponent;
  @Output() onCloseMedia = new EventEmitter<void>();
  @Input() dataType: string[] = [];
  @Input() composite: Composite;
  @Input() playlist: Playlist;

  dataClass: DataClass = DataClass.Data;
  
  constructor(public editorService: EditorService, private apiService: ApiService, private authService: AuthService, private dialog: MatDialog) { }

  closeMedia() {
    this.onCloseMedia.emit();
  }

  onDataSelect(data: Data[]) {
    this.apiService.createPlaylistCompositeData(this.authService.selectedAccountId, this.playlist.id, this.composite.id, data).subscribe((data: any) => {
      this.composite = data;
      this.closeMedia();
    });
  }

  currentLocation(){
    return this.authService.getAccountBasePath() + '/data';
  }

  onTableDataChange(event: TableChangeData) {
    if (event.action == TableChangeDataActions.New) {
      this.dialog.open(NewComponent, {
        enterAnimationDuration: 0,
        data: {
          title: 'New Data',
          dataClass: DataClass.Data,
          data: {
            name: 'New Data '+Math.floor(Math.random() * 1000),
            selectedClassType: 'Folder',
            parentId: event.item.parentId
          },
          width: 400,
          height: 300
        }
      }).afterClosed().subscribe((result: any) => { 
        if (result) {
          this.updateTable();
        }
      });
    }

    if (event.action == TableChangeDataActions.Delete) {
      this.dialog.open(PopupDialog, {
        data: {
          title: 'Delete Data',
          message: 'Are you sure you want to delete this Data?'
        }
      }).afterClosed().subscribe(async (result: any) => {
        if (result) {
          this.dataTableComponent.setLoading.emit();
          await this.apiService.deleteData(this.authService.selectedAccountId, event.item.id).toPromise();
          this.updateTable();
        }
      });
    }

    if (event.action == TableChangeDataActions.DeleteAll) {
      this.dialog.open(PopupDialog, {
        data: {
          title: 'Delete Data',
          message: 'Are you sure you want to delete all selected Data?'
        }
      }).afterClosed().subscribe(async (result: any) => {
        if (result) {
          this.dataTableComponent.setLoading.emit();
          for (let item of event.selected.selected) {
           await this.apiService.deleteData(this.authService.selectedAccountId, item.id).toPromise();
          }
          this.updateTable();
        }
      });
    }

    if(event.action == TableChangeDataActions.Edit){
      this.dialog.open(EditComponent, {
        enterAnimationDuration: 0,
        data: {
          title: 'Edit Data',
          dataClass: DataClass.Data,
          data: JSON.parse(JSON.stringify(event.item)),
          width: 400,
          height: 300
        }
      }).afterClosed().subscribe((result: any) => {
        if (result) {
          this.updateTable();
        }
      });
    }
  }

  updateTable() {
    this.dataTableComponent.updateWatch.emit();
  }
}
