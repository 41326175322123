import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from 'src/app/api/api.service';
import { AccountGroup } from 'src/app/api/models/account/accountGroup';
import { AccountUser } from 'src/app/api/models/account/accountUser';
import { PartnerGroup } from 'src/app/api/models/partner/partnerGroup';
import { PartnerUser } from 'src/app/api/models/partner/partnerUser';
import { User } from 'src/app/api/models/user/user';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent {
  roles: PartnerGroup[] = [];
  invites: any[] = [];
  email: string = '';

  constructor(public authService: AuthService, private apiService: ApiService, private _snackBar: MatSnackBar) {
    this.apiService.partnerListAccountRoles(this.authService.partner.id).subscribe(roles => {
      this.roles = roles;
    });
    this.authService.partner.accountUsers.forEach(user => {
      user.user.roles = this.getListOfRoles(user).map(role => { return role.id; });
      user.user.isAdmin = user.user.roles.includes(1);
    });
  }

  currentOption: string = 'info';

 async refresh() {
    await this.authService.refreshPartnerCache();
    this.authService.partner.accountUsers.forEach(user => {
      user.user.roles = this.getListOfRoles(user).map(role => { return role.id; });
      user.user.isAdmin = user.user.roles.includes(1);
    });
  }

  currentLocation() {
    return this.authService.getPartnerBasePath() + '/settings';
  }

  saveAccountInfo() {
    this.apiService.updatePartnerInfo(this.authService.partner.id, this.authService.partner.info.name).subscribe(result => {
      this._snackBar.open('Account info updated', '', {
        duration: 3000,
      });
    });
  }

  getListOfRoles(user: PartnerUser) {
    let roles = [];
    user.groups.forEach(group => {
      roles.push(group.group);
    });
    return roles;
  }

  getRolesAsString(user: any) {
    let roles = this.getListOfRoles(user);
    let list = [];
    roles.forEach(role => {
      if (list.indexOf(role.name) == -1)
        list.push(role.name);
    });
    return list.map(role => { return role; }).join(', ');
  }

  onRolesChange(user: User) {
    this.apiService.partnerUpdateUserRoles(this.authService.partner.id, user.id, user.roles).subscribe(result => {
      this._snackBar.open('User roles updated', '', {
        duration: 3000,
      });
    }
    );
  }

  addUser() {
    this.apiService.addUserToPartnerAccount(this.authService.partner.id, this.email).subscribe(result => {
      this._snackBar.open('User created', '', {
        duration: 3000,
      });
      this.email = '';
      this.refresh();
    }, error => {
      this._snackBar.open(error, '', {
        duration: 3000,
      });
    });
  }

  deleteUser(id: number) {
    this.apiService.removeUserFromPartnerAccount(this.authService.partner.id, id).subscribe(result => {
      this._snackBar.open('User deleted', '', {
        duration: 3000,
      });
      this.authService.partner.accountUsers = this.authService.partner.accountUsers.filter(user => user.id != id);
    }
    );
  }
}
