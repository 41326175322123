import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { LayerTypes } from '../../enum/layer-types';
import { EditorService } from '../../services/editor.service';
import { LayerTableColumn } from '../../models/layer-table-column';
import { TextLayer } from '../../class/text-layer';
import { LayerTableRow } from '../../models/layer-table-row';
import { CommonService } from 'src/app/services/common.service';
import { LayerTableColumnUpdate } from './edit-table/edit-table.component';
import { Layer } from '../../models/layer';
import { ApiService } from 'src/app/api/api.service';
import { DataFields } from 'src/app/api/models/account/data/data-fields';
import { AuthService } from 'src/app/services/auth.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { DataClassType } from 'src/app/api/models/account/data/data-class';
import { LayerText } from '../../models/layer-text';

enum SelectedMenu {
  main = 'main',
  table = 'table',
  text = 'text',
  playlist = 'playlist',
  design = 'design',

}

@Component({
  selector: 'app-layer-config',
  templateUrl: './layer-config.component.html',
  styleUrls: ['./layer-config.component.scss']
})
export class LayerConfigComponent implements OnDestroy {
  @Output() onCloseSettings = new EventEmitter<void>();

  selectedMenuOptions = SelectedMenu;
  selectedMenu: SelectedMenu = SelectedMenu.main;

  tableDataSource: Array<LayerTableRow[]> = [];
  displayedColumns: LayerTableColumn[] = [];

  editTable: boolean = false;
  type: LayerTypes = LayerTypes.none;
  types = LayerTypes;
  fields: DataFields[] = [];
  currentPlaylist: any = null;

  textLayer: TextLayer = new TextLayer();
  fieldClasses: DataClassType[] = [];

  constructor(public editorService: EditorService, private commonService: CommonService, private apiService: ApiService, private authService: AuthService) {
    let data = editorService.formatWidget(this.editorService.selectedLayer.widget.childWidget);
    this.updateConfig();
    editorService.updateConfig = () => {
      this.updateConfig();
    };

    apiService.fieldTypes().subscribe((data: DataFields[]) => {
      this.fields = data;
    });

    this.editorService.getClasses().subscribe(classes => {
      //filter out folder
      this.fieldClasses = classes.filter(c => c.name != 'Folder');
    });
  }
  ngOnDestroy(): void {
    this.editorService.updateConfig = () => { };
  }

  updateConfig() {
    let data = this.editorService.formatWidget(this.editorService.selectedLayer.widget.childWidget);
    this.type = data.type;
    if (this.type == LayerTypes.table) {
      if (Array.isArray(data.data.displayedColumns) && Array.isArray(data.data.tableDataSource)) {
        if(this.tableDataSource.length != data.data.tableDataSource.length || this.displayedColumns.length != data.data.displayedColumns.length) {
          this.tableDataSource = data.data.tableDataSource;
          this.displayedColumns = data.data.displayedColumns;
        }else {
          for (let i = 0; i < data.data.tableDataSource.length; i++) {
            for (let i2 = 0; i2 < data.data.tableDataSource[i].length; i2++) {
              this.tableDataSource[i][i2].layerText = data.data.tableDataSource[i][i2].layerText;
            }
          }
          for (let i = 0; i < data.data.displayedColumns.length; i++) {
            this.displayedColumns[i].layerText = data.data.displayedColumns[i].layerText;
          }
        }
      }
      this.selectedMenu = SelectedMenu.table;
    } else if (this.type == LayerTypes.text) {
      this.textLayer = data.data;
      this.selectedMenu = SelectedMenu.text;
    } else if (this.type == LayerTypes.playlist) {
      this.selectedMenu = SelectedMenu.playlist;
      this.currentPlaylist = data.data;
      for (let i = 0; i < this.currentPlaylist.items.length; i++) {
        let item = this.currentPlaylist.items[i];
        if (item.type == LayerTypes.image || item.type == LayerTypes.video) {
          item.data = JSON.parse(item.data);
        }
      }
    }
  }

  addPlaylistItems() {
    this.editorService.overlay.driveTypes = ['Folder', 'Image', 'Video'];
    this.editorService.overlay.drive = true;
  }

  removePlaylistItem(i: number) {
    this.currentPlaylist.items.splice(i, 1);
    this.updatePlaylist();
  }

  updateLayer() {
    this.editorService.updateLayerInfo(this.editorService.selectedLayer);
  }

  updateTextLayer() {
    this.editorService.updateTextLayer(this.editorService.selectedLayer, this.textLayer);
  }

  onClose() {
    this.onCloseSettings.emit();
  }

  updateTable() {
    this.editorService.updateTableLayer(this.editorService.selectedLayer, this.displayedColumns, this.tableDataSource);
  }

  onEditTable() {
    this.editTable = true;
  }

  disableSelection() {
    this.commonService.selectedInputFocusChange();
  }

  selectMenuOption(menuOption: SelectedMenu) {
    this.selectedMenu = menuOption;
  }

  deleteRow(i: number) {
    this.tableDataSource.splice(i, 1);
    this.updateTable();
  }

  addColumn() {
    let layerText = new TextLayer();
    layerText.text = 'New Column ' + Math.random().toFixed(2);

    this.displayedColumns.push({
      layerText: layerText,
      fieldClass: this.editorService.fieldTypes[0].field_class,
      fieldName: this.editorService.fieldTypes[0].name,
      padding: 0,
      width: 0,
      vAlignment: 'center',
    });

    let id = this.displayedColumns.length - 1;

    for (let i = 0; i < this.tableDataSource.length; i++) {
      let layerText = new TextLayer();
      layerText.text = 'new Row ' + id + ' ' + Math.random().toFixed(2);
      this.tableDataSource[i].push({
        layerText: layerText,
        hidden: false,
        isAction: false,
        padding: 0,
        width: 0,
        vAlignment: 'center',
      });
    }

    this.updateTable();
  }

  addRow() {
    if (this.displayedColumns.length == 0) { return; }
    let row: LayerTableRow[] = [];
    for (let i2 = 0; i2 < this.displayedColumns.length; i2++) {
      let layerText = new TextLayer();
      if(this.tableDataSource.length > 0) {
        layerText = JSON.parse(JSON.stringify(this.tableDataSource[0][i2].layerText));
      }
      layerText.text = 'new row ' + i2 + ' ' + Math.random().toFixed(2);
      
      row.push({
        layerText: layerText,
        hidden: false,
        isAction: false,
        padding: 0,
        width: 0,
        vAlignment: 'center',
      });
    }

    this.tableDataSource.push(row);
    this.updateTable();
  }

  onColumnDelete(i: number) {
    this.displayedColumns.splice(i, 1);
    for (let i2 = 0; i2 < this.tableDataSource.length; i2++) {
      this.tableDataSource[i2].splice(i, 1);
    }
    if (this.displayedColumns.length == 0) {
      this.tableDataSource = [];
    }
    this.updateTable();
  }

  onColmnUpdate(layerTableColumnUpdate: LayerTableColumnUpdate) {
    this.displayedColumns[layerTableColumnUpdate.i] = layerTableColumnUpdate.column;
    for (let i2 = 0; i2 < this.tableDataSource.length; i2++) {
      this.tableDataSource[i2][layerTableColumnUpdate.i].vAlignment = layerTableColumnUpdate.column.vAlignment;
    }
    this.updateTable();
  }

  onUpdateRowStyle(rows: Array<LayerTableRow[]>) {
    this.tableDataSource = rows;
    this.updateTable();
  }

  updateTableGlobal(text: LayerText) {
    // for (let i2 = 0; i2 < this.tableDataSource.length; i2++) {
    //   for (let i3 = 0; i3 < this.displayedColumns.length; i3++) {
    //       let layerText = this.tableDataSource[i2][i3].layerText;
    //       text.text = layerText.text;
    //       this.tableDataSource[i2][i3].layerText = text;
    //   }
    // }
    // this.updateTable();
  }

  onCloseTable() {
    this.editTable = false;
  }

  onChangeField(event: any) {
    this.editorService.selectedLayer.fieldClass = this.editorService.selectedLayer.selectedFieldType.split('_')[0];
    this.editorService.selectedLayer.fieldName = this.editorService.selectedLayer.selectedFieldType.split('_')[1];
    this.updateLayer();   
  }

  playlistDrop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.currentPlaylist.items, event.previousIndex, event.currentIndex);
    this.updatePlaylist();
  }

  updatePlaylist() {
    let playlist = this.currentPlaylist;
    for (let i = 0; i < playlist.items.length; i++) {
      let item = playlist.items[i];
      if (item.type == LayerTypes.image || item.type == LayerTypes.video) {
        item.data = JSON.stringify(item.data);
      }
    }
    this.editorService.updatePlaylistItems(this.editorService.selectedLayer, playlist);
  }
}
