import { Component, OnDestroy, OnInit } from '@angular/core';
import { EditorService } from './services/editor.service';
import { Design } from 'src/app/api/models/account/display/design';
import { AuthService } from 'src/app/services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/api/api.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-root',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss']
})
export class EditorComponent implements OnDestroy, OnInit {
  title = 'Create layer';
  loading = true;
  leftMenu = true;
  rightMenu = true;
  savingState: number = 0;


  ngOnDestroy() {
    if (document.getElementsByTagName('flt-glass-pane').length > 0) {
      //@ts-ignore
      document.getElementsByTagName('flt-glass-pane')[0].style.display = 'none';
    }

    //@ts-ignore
    document.getElementsByTagName('body')[0].style.overflow = 'auto';

    //@ts-ignore
    document.getElementsByTagName('body')[0].style.touchAction = 'unset';

    //@ts-ignore
    LayerAPI.reset();

    this.editorService.editorLoaded = false;
  }

  constructor(public editorService: EditorService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private apiService: ApiService,
    public commonService: CommonService) {

    this.editorService.onSavingSate = (state: number) => {
      this.savingState = state;
    };
  }

  onCloseMedia() {
    this.editorService.overlay.drive = false;
  }

  onCloseSettings() {
    this.editorService.overlay.settings = false;
  }

  ngOnInit(): void {
    this.waitForEditor();
  }

  onEditorFocus() {
    this.editorService.editorFocused = true;
  }

  onEditorBlur() {
    this.editorService.editorFocused = false;
  }

  private waitForEditor() {
    //@ts-ignore
    if (typeof LayerAPI != 'undefined' && typeof LayerAPI.selectLayer === 'function') {
      this.route.params.subscribe(params => {
        if (params['playlistId'] != undefined) {
          this.apiService.playPlayList(this.authService.selectedAccountId, Number.parseInt(params['playlistId'])).subscribe(playlist => {
            if (document.getElementsByTagName('flt-glass-pane').length > 0) {
              //@ts-ignore
              document.getElementsByTagName('flt-glass-pane')[0].style.display = 'block';
            }
            //@ts-ignore
            document.getElementsByTagName('body')[0].style.overflow = 'hidden';
            this.editorService.zoom = 0.7;
            //@ts-ignore
            LayerAPI.zoom(this.editorService.zoom);

            //@ts-ignore
            LayerAPI.loadPlaylist(JSON.stringify(playlist));

            this.loading = false;
            this.editorService.editorLoaded = true;
          });
        } else if (params['id'] != undefined) {
          this.apiService.getDesign(this.authService.selectedAccountId, Number.parseInt(params['id'])).subscribe(design => {
            if (document.getElementsByTagName('flt-glass-pane').length > 0) {
              //@ts-ignore
              document.getElementsByTagName('flt-glass-pane')[0].style.display = 'block';
            }
            //@ts-ignore
            document.getElementsByTagName('body')[0].style.overflow = 'hidden';
            //@ts-ignore
            document.getElementsByTagName('body')[0].style.touchAction = 'none';

            let flutterView = document.getElementsByTagName("flutter-view");
            if (flutterView.length > 0) {
              //remove and add focus event
              flutterView[0].removeEventListener('mouseleave', this.onEditorBlur.bind(this));
              flutterView[0].removeEventListener('mouseenter', this.onEditorFocus.bind(this));
              flutterView[0].addEventListener('mouseleave', this.onEditorBlur.bind(this));
              flutterView[0].addEventListener('mouseenter', this.onEditorFocus.bind(this));
            }

            let loadingOverlay = document.getElementById('loading-container');
            if (loadingOverlay) {
              loadingOverlay.focus();
            }

            this.editorService.selectedDesign = design;
            this.editorService.zoom = 0.7;
            //@ts-ignore
            LayerAPI.zoom(this.editorService.zoom);

            this.editorService.load();
            this.loading = false;
            this.editorService.editorLoaded = true;
          });
        }
      });
    } else {
      setTimeout(() => {
        this.waitForEditor();
      }, 100);
    }
  }



}
