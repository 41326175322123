import { Component, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/api/api.service';
import { User } from 'src/app/api/models/user/user';
import { PopupDialog } from 'src/app/popups/confirm/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnDestroy {

  currentOption: string = 'info';
  user: User | null = null;

  constructor(public commonService: CommonService, private apiService: ApiService, public authService: AuthService, private router: Router, private _snackBar: MatSnackBar, private dialog: MatDialog) {
    this.authService.getAccount().subscribe((user: User) => {
      this.user = user;
    }, (error) => {
    });
  }

  refreshUser() {
    this.authService.getAccount(true).subscribe((user: User) => {
      this.user = user;
    }, (error) => {
    });
  }

  ngOnDestroy(): void {
    this.refreshUser();
  }

  saveProfile() {
    this.apiService.updateUser(this.user).subscribe(result => {
      this._snackBar.open('Information updated', '', {
        duration: 3000,
      });
    }, error => {
      this._snackBar.open(error, '', {
        duration: 3000,
      });
    });
  }

  deleteToken(tokenId: number){
    this.dialog.open(PopupDialog, {
      data: {
        title: 'Delete token',
        message: 'Are you sure you want to delete this acess token?'
      }
    }).afterClosed().subscribe((result: any) => {
      if (result) {
        this.apiService.deleteUserAccessToken(tokenId).subscribe(result => {
          this._snackBar.open('Token deleted', '', {
            duration: 3000,
          });
          this.refreshUser();
        }, error => {
          this._snackBar.open(error, '', {
            duration: 3000,
          });
        });
      }
    });   
  }

  generateWebDavToken(){
    this.apiService.createWebDavToken().subscribe(result => {
      this._snackBar.open('URL Create', '', {
        duration: 3000,
      });
      this.refreshUser();
    }, error => {
      this._snackBar.open(error, '', {
        duration: 3000,
      });
    });
  }
}
