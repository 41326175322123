import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EditorService } from 'src/app/account/editor/services/editor.service';
import { ApiService } from 'src/app/api/api.service';
import { DataClass } from 'src/app/enums/data-class';
import { TableChangeData } from 'src/app/models/table-change-data';
import { AuthService } from 'src/app/services/auth.service';
import { DataFolderComponent } from '../data-folder/data-folder.component';
import { Data } from 'src/app/api/models/account/data/data';

@Component({
  selector: 'app-pixabay',
  templateUrl: './pixabay.component.html',
  styleUrls: ['./pixabay.component.scss']
})
export class PixabayComponent {
  @ViewChild(DataFolderComponent) dataTableComponent!: DataFolderComponent;
  @Output() onCloseMedia = new EventEmitter<void>();
  @Output() onSelectMedia = new EventEmitter<Data>();
  @Input() dataType: string[] = [];
  @Input() isEditor: boolean = true;
  @Input() playlistId: number = 0;
  @Input() dataId: number = 0;

  dataClass: DataClass = DataClass.PixaBay;
  loading: boolean = false;
  
  constructor(public editorService: EditorService, private apiService: ApiService, private authService: AuthService, private dialog: MatDialog) { }

  closeMedia() {
    this.onCloseMedia.emit();
  }

  async onDataSelect(data: Data[]) {
    this.loading  = true;
    for (let i = 0; i < data.length; i++) {
      await this.apiService.createDataByURL(this.authService.selectedAccountId, data[i].name, data[i].selected_class.name, this.dataId, data[i].meta.url).toPromise();
    }
    this.loading = false;
    this.onCloseMedia.emit();
  }

  currentLocation(){
    return this.authService.getAccountBasePath() + '/data';
  }

  onTableDataChange(event: TableChangeData) {
    
  }

  updateTable() {
    this.dataTableComponent.updateWatch.emit();
  }
}
