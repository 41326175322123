<div class="modal-main" cdkDrag>
    <div class="modal-head" cdkDragHandle>
        <div class="modal-title">Accounts</div>
        <mat-icon class="action-icons close-action" (click)="close()">close</mat-icon>
    </div>
    <div class="modal-body">
        <div *ngIf="isLoading" class="loading">
            <mat-spinner diameter="50"></mat-spinner>
        </div>
        <input #accountSearchField type="text" class="form-control" placeholder="Search" [(ngModel)]="searchQuery" (ngModelChange)="onSearch()" autofocus />
        <div class="account-list" *ngIf="!isLoading">            
            <div class="accounts">
                <table class="table">
                    <tbody>
                        <tr *ngFor="let account of accounts">
                            <td class="align-middle">{{account.info.name}}</td>
                            <td>
                                <button type="button" class="btn" (click)="selectAccount(account)">Select</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div>
            <!-- <button type="button" class="btn" (click)="openAccountCreate()">Create account</button> -->
        </div>
    </div>
</div>