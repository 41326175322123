import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { ApiService } from 'src/app/api/api.service';
import { Data } from 'src/app/api/models/account/data/data';
import { DataChange, DataChangeEvent } from 'src/app/models/data-change';

@Component({
  selector: 'app-data-webview',
  templateUrl: './data-webview.component.html',
  styleUrls: ['./data-webview.component.scss']
})
export class DataWebviewComponent implements AfterViewInit {

  @Input() data!: Data;
  @Output() onDataChange = new EventEmitter<DataChange>();

  isFeed = false;

  newUrl = '';

  constructor(private apiService: ApiService) { 

  }
  ngAfterViewInit(): void {
    if (this.data.feed) {
      this.isFeed = true;
    }
  }

  addURL() {
    if (this.newUrl) {
      this.data.meta.allowedUrls.push(this.newUrl);
      this.newUrl = '';
    }
  }

  onUpdate(index: number, url: any) {
    this.data.meta.allowedUrls[index] = url;
  }

  removeURL(index: number) {
    this.data.meta.allowedUrls.splice(index, 1);
  }

  stopPropagation(event: MouseEvent) {
    event.stopPropagation(); // Prevent the event from bubbling up to the drag container
  }

  save() {
    this.onDataChange.emit({dataChangeEvent: DataChangeEvent.onSave, data: this.data});
  }

  cancel() {
    this.onDataChange.emit({dataChangeEvent: DataChangeEvent.onCancel, data: this.data});
  }
}
