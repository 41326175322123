import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EditorService } from 'src/app/account/editor/services/editor.service';
import { ApiService } from 'src/app/api/api.service';
import { Composite } from 'src/app/api/models/account/display/composite';
import { Playlist } from 'src/app/api/models/account/display/playlist';
import { DataClass } from 'src/app/enums/data-class';
import { TableChangeData, TableChangeDataActions } from 'src/app/models/table-change-data';
import { PopupDialog } from 'src/app/popups/confirm/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { DataTableComponent } from '../../data-table/data-table.component';
import { EditComponent } from '../../edit/edit.component';
import { NewComponent } from '../../new/new.component';
import { Display } from 'src/app/api/models/account/display/display';

@Component({
  selector: 'app-display-selector',
  templateUrl: './display-selector.component.html',
  styleUrls: ['./display-selector.component.scss']
})
export class DisplaySelectorComponent {
  @ViewChild(DataTableComponent) dataTableComponent!: DataTableComponent;

  dataClass: DataClass = DataClass.Displays;

  @Output() onCloseDisplays = new EventEmitter<void>();
  @Input() dataType: string[] = [];
  @Input() composite: Composite;
  @Input() playlist: Playlist;

  constructor(public editorService: EditorService, private apiService: ApiService, private authService: AuthService, private dialog: MatDialog) { }

  close() {
    this.onCloseDisplays.emit();
  }

  onTableDataChange(event: TableChangeData) {
    if (event.action == TableChangeDataActions.New) {
      this.dialog.open(NewComponent, {
        enterAnimationDuration: 0,
        data: {
          title: 'New Display',
          dataClass: DataClass.Displays,
          data: {
            name: 'New Display ' + Math.floor(Math.random() * 1000),
          },
          width: 400,
          height: 200
        }
      }).afterClosed().subscribe((result: any) => {
        if (result) {
          this.updateTable();
        }
      });
    }

    if (event.action == TableChangeDataActions.Delete) {
      this.dialog.open(PopupDialog, {
        data: {
          title: 'Delete Display',
          message: 'Are you sure you want to delete this display?'
        }
      }).afterClosed().subscribe((result: any) => {
        if (result) {
          this.apiService.deleteDisplay(this.authService.selectedAccountId, event.item.id).subscribe((data: any) => {
            this.updateTable();
          });
        }
      });
    }

    if (event.action == TableChangeDataActions.DeleteAll) {
      this.dialog.open(PopupDialog, {
        data: {
          title: 'Delete Displays',
          message: 'Are you sure you want to delete all selected displays?'
        }
      }).afterClosed().subscribe((result: any) => {
        if (result) {
          event.selected.selected.forEach((item: any) => {
            this.apiService.deleteDisplay(this.authService.selectedAccountId, item.id).subscribe((data: any) => {
              this.updateTable();
            });
          });
        }
      });
    }
  }

  updateTable() {
    this.dataTableComponent.updateWatch.emit();
  }

  onDataSelect(event: Display[]) {
    for(let i = 0; i < event.length; i++){
      this.apiService.addDisplayToPlaylistComposite(this.authService.selectedAccountId, this.playlist.id, event[i].id).subscribe((data: any) => {
        this.close();
      });
    }
  }
}
