import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/api/api.service';
import { Partner } from 'src/app/api/models/partner/partner';
import { AuthService } from 'src/app/services/auth.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-partner-list',
  templateUrl: './partner-list.component.html',
  styleUrls: ['./partner-list.component.scss']
})
export class PartnerListComponent {
  accounts: Partner[] = [];

  constructor(public commonService: CommonService, private apiService: ApiService, private authService: AuthService, private router: Router) {
    this.apiService.listPartnerAccounts({
      query: '',
      limit: 1000,
      start: 0,
      order: 'asc',
      orderField: 'id',
    }).subscribe(result => {
      this.accounts = result.results;
    });
  }

  close() {
    this.commonService.partnerSelectOpen = false;
  }

  selectAccount(account: Partner) {
    this.authService.selectPartner(account.id).then(() => {
      this.router.navigate([this.authService.getPartnerBasePath()]).then(() => {
        location.reload();
        this.close();
      });
    });
  }

  openAccountCreate() {
    this.router.navigate(['/setup']);
    this.close();
  }
}
