import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from 'src/app/api/api.service';
import { DataClassType } from 'src/app/api/models/account/data/data-class';
import { DataClass } from 'src/app/enums/data-class';
import { EditItem } from 'src/app/models/edit-item';
import { AuthService } from 'src/app/services/auth.service';
import { EditComponent } from '../edit/edit.component';
import { Data } from 'src/app/api/models/account/data/data';
import { PopupDialog } from 'src/app/popups/confirm/dialog';

@Component({
  selector: 'app-move-data',
  templateUrl: './move-data.component.html',
  styleUrls: ['./move-data.component.scss']
})
export class MoveDataComponent {
  window: any = window;

  constructor(
    public dialogRef: MatDialogRef<EditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EditItem,
    private apiService: ApiService,
    private authService: AuthService,
    private _snackBar: MatSnackBar, private dialog: MatDialog) {
  }

  onSelectFolder(result: {data:Data, dirList: Array<{ name: string, id: number }>}) {
    this.dialog.open(PopupDialog, {
      data: {
        title: 'Move items',
        message: 'Are you sure you want to move these items to the folder "'+result.data.name+'" ?'
      }
    }).afterClosed().subscribe(async (resultCheck: any) => {
      if (resultCheck) {
        for (let i = 0; i < this.data.data.length; i++) {
          let item: Data = this.data.data[i];
          if(item.id == result.data.id) {
            continue;
          }
          item.parent = result.data;
          try {
            await this.apiService.updateData(this.authService.selectedAccountId, item).toPromise();
          } catch (error) {
            this._snackBar.open(error, 'Close', {
              duration: 5000,
            });
          }
        }
    
        this.dialogRef.close(result.dirList);
      }
    });

  }
}
