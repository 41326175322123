<div class="modal-main" cdkDrag>
    <div class="modal-head" cdkDragHandle>
        <div class="modal-title">{{data.name}}</div>
        <mat-icon class="action-icons close-action" (click)="cancel()">close</mat-icon>
    </div>
    <div class="modal-body">
        <div class="video-config">
            <div style="width: 31em;">
                <video class="video-el" style="max-height: 35em;" [src]="data.meta.url" controls></video>
            </div>

            <div class="video-settings">
                <table class="table">
                    <tr>
                        <td>Name</td>
                        <td><input class="form-control" placeholder="Name" [(ngModel)]="data.name"></td>
                    </tr>
                    <tr>
                        <td style="width: 10em;">Tags:</td>
                        <td>
                            <div class="tags-list">
                                <div *ngFor="let tag of tags; let i2 = index">
                                    <span class="tag"><input class="form-control" [(ngModel)]="tag.value" /><button mat-stroked-button (click)="tags.splice(i2,1)">Remove</button></span>
                                </div>
                                <button mat-stroked-button (click)="addTag()">Add tag</button>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
    <div class="actions">
        <button mat-button (click)="cancel()">Close</button>
        <button mat-button (click)="save()">Save</button>
    </div>
</div>