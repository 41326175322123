<div class="header">
    <div> <span class="account-name">{{account?.info.name}}</span></div>
    <div class="bar"></div>
    <div class="right">
        <div class="account-info-button" (click)="menuOpen = !menuOpen">
            <div>{{user?.alias}}</div>
            <div  style="display: flex;">
                <mat-icon>account_circle</mat-icon>
            </div>
        </div>

        <div *ngIf="menuOpen" class="account-menu">
            <div class="account-menu-buttons">
                <button type="button" class="btn btn-dark-custom" (click)="openProfile()">Profile</button>
                <button type="button" class="btn btn-dark-custom" (click)="openAccountSelect()">Accounts</button>
                <button type="button" class="btn btn-dark-custom" (click)="openPartnerSelect()">Partners</button>
                <!-- <button type="button" class="btn btn-dark-custom">Admin</button> -->
            </div>
            <div class="account-menu-logout">
                <button (click)="logout()" type="button" class="btn btn-dark-custom"> 
                    <span>Sign out</span>
                </button>
            </div>
        </div>
    </div>
</div>
<div class="router-body" (click)="closeMenu($event)" (dragover)="commonService.onDragOver($event)" (dragenter)="commonService.onDragEnter($event)" (dragleave)="commonService.onDragLeave($event)" (drop)="commonService.onDrop($event)">
    <router-outlet></router-outlet>
</div>