<app-breadcrumbs [isPartner]="true"></app-breadcrumbs>
<div class="overlay">
    <div>
        <div>
            <h1>Partner Dashboard</h1>
            <p>Welcome to your partner dashboard</p>
        </div>
        <div class="dash-icons">
            <div class="dash-icon" (click)="loadCompent('accounts')">
                <mat-icon>group</mat-icon>
                <span>Accounts</span>
            </div>
            <div class="dash-icon" (click)="loadCompent('settings')">
                <mat-icon>tune</mat-icon>
                <span>Settings</span>
            </div>
        </div>
    </div>
</div>