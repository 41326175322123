import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from 'src/app/api/api.service';
import { Account } from 'src/app/api/models/account/account';
import { DataTableComponent } from 'src/app/components/data-table/data-table.component';
import { NewComponent } from 'src/app/components/new/new.component';
import { DataClass } from 'src/app/enums/data-class';
import { TableChangeData, TableChangeDataActions } from 'src/app/models/table-change-data';
import { AuthService } from 'src/app/services/auth.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.scss']
})
export class AccountsComponent {

  @ViewChild(DataTableComponent) dataTableComponent!: DataTableComponent;

  dataClass: DataClass = DataClass.Accounts;
  selectedAccount: Account = null;


  constructor(private apiService: ApiService, private authService: AuthService, private dialog: MatDialog, public commonService: CommonService) { }

  currentLocation(){
    return this.authService.getPartnerBasePath() + '/accounts';
  }

  onTableDataChange(event: TableChangeData) {
    if (event.action == TableChangeDataActions.New) {
      this.dialog.open(NewComponent, {
        enterAnimationDuration: 0,
        data: {
          title: 'New account',
          dataClass: DataClass.Accounts,
          data: {
            name: 'account name',
          },
          width: '30em',
          height: '10em'
        }
      }).afterClosed().subscribe((result: any) => { 
        if (result) {
          this.updateTable();
        }
      });
    }

    if (event.action == TableChangeDataActions.Delete) {

    }

    if (event.action == TableChangeDataActions.DeleteAll) {

    }

    if(event.action == TableChangeDataActions.Edit){
        this.selectedAccount = event.item;
        this.commonService.accountSettingsOpen = true;
    }
  }

  updateTable() {
    this.dataTableComponent.updateWatch.emit();
  }
}
