<div class="modal-main" (mouseover)="onHover()" (mouseout)="onLeave()" cdkDrag>
    <div class="modal-head" cdkDragHandle>
        <div class="modal-title">Text Style</div>
        <mat-icon class="action-icons close-action" (click)="close()">close</mat-icon>
    </div>
    <div class="modal-body">
        <div class="input-style-controls">
            <div class="select-container">
                <mat-button-toggle-group [(ngModel)]="commonService.textInputValue.wrapAlignment" (change)="onChange()" class="style-input-field">
                    <mat-button-toggle value="0" aria-label="Text align left">
                        <mat-icon>format_align_left</mat-icon>
                    </mat-button-toggle>
                    <mat-button-toggle value="2" aria-label="Text align center">
                        <mat-icon>format_align_center</mat-icon>
                    </mat-button-toggle>
                    <mat-button-toggle value="1" aria-label="Text align right">
                        <mat-icon>format_align_right</mat-icon>
                    </mat-button-toggle>
                </mat-button-toggle-group>
            </div>

            <div class="select-container">
                <div class="mb-3">
                    <label for="input_fontfamily" class="form-label">Font</label>
                    <byte-this-google-font-selector-input id="input_fontfamily" [google-api-key]="googleFontApiKey" [value]="commonService.textInputValue.fontFamily" (change)="onFontChange($event)" (hover-value)="onFontHover($event)"></byte-this-google-font-selector-input>
                </div>
                <div class="mb-3">
                    <label for="input_fontsize" class="form-label">Font size</label>
                    <input class="form-control" id="input_fontsize" [(ngModel)]="commonService.textInputValue.fontSize" type="number" (change)="onChange()">
                </div>
            </div>

            <div class="select-container">
                <div class="mb-3">
                    <label for="input_fontweight" class="form-label">Font weight</label>
                    <select class="form-select select-field" id="input_fontweight" [(ngModel)]="commonService.textInputValue.fontWeight" (change)="onChange()">
                    <option [value]="1">w200</option>
                    <option [value]="2">w300</option>
                    <option [value]="3">w400</option>
                    <option [value]="4">w500</option>
                    <option [value]="5">w600</option>
                    <option [value]="6">w700</option>
                    <option [value]="7">w800</option>
                    <option [value]="8">w900</option>
                    <option [value]="9">normal</option>
                    <option [value]="10">Bold</option>
                  </select>
                </div>
                <div class="mb-3">
                    <label for="input_fontstyle" class="form-label">Style</label>
                    <select class="form-select select-field" id="input_fontstyle" [(ngModel)]="commonService.textInputValue.fontStyle" (change)="onChange()">
                        <option [value]="0">Normal</option>
                        <option [value]="1">Italic</option>
                      </select>
                </div>
            </div>

            <div class="select-container">
                <div class="mb-3">
                    <label for="input_fontoverflow" class="form-label">Overflow</label>
                    <select class="form-select select-field" id="input_fontoverflow" [(ngModel)]="commonService.textInputValue.overflow" (change)="onChange()">
                    <option [value]="0">Wrap</option>
                    <option [value]="1">Fade</option>
                    <option [value]="2">Ellipsis</option>
                    <option [value]="3">Visible</option>
                  </select>
                </div>
                <div class="mb-3">
                    <label for="input_fontoverflow" class="form-label">Fit</label>
                    <select class="form-select select-field" id="input_fontoverflow" [(ngModel)]="commonService.textInputValue.fit" (change)="onChange()">
                    <option [value]="0">None</option>
                    <option [value]="1">Fill</option>
                    <option [value]="2">Contain</option>
                    <option [value]="3">Cover</option>
                    <option [value]="4">Fit Width</option>
                    <option [value]="5">Fit Height</option>
                    <option [value]="6">Scale Down</option>
                  </select>
                </div>     
            </div>

            <div class="select-container">
                <div class="mb-3">
                    <label for="input_letterspacing" class="form-label">Letter Spacing</label>
                    <input class="form-control" id="input_letterspacing" [(ngModel)]="commonService.textInputValue.letterSpacing" type="number" (change)="onChange()">
                </div>

                <div class="mb-3">
                    <label for="input_wordspacing" class="form-label">Word Spacing</label>
                    <input class="form-control" id="input_wordspacing" [(ngModel)]="commonService.textInputValue.wordSpacing" type="number" (change)="onChange()">
                </div>
            </div>

            <div class="select-container">
                <div class="mb-3">
                    <label for="input_fontcolor" class="form-label">Font color</label>
                    <input id="input_fontcolor" class="form-control" [cpAlphaChannel]="'forced'" [cpOutputFormat]="'hex'" [(colorPicker)]="textColor" [style.background]="textColor" (colorPickerChange)="onTextColorChange($event)" />
                </div>
                <div class="mb-3">
                    <label for="input_fontbackroundcolor" class="form-label">Font background color</label>
                    <input id="input_fontbackroundcolor" class="form-control" [cpAlphaChannel]="'forced'" [cpOutputFormat]="'hex'" [(colorPicker)]="backgroundColor" [style.background]="backgroundColor" (colorPickerChange)="onBackgroundColorChange($event)" />
                </div>
            </div>

            <div class="select-container" *ngIf="this.commonService.textInputValue.shadows.length > 0">
                <div class="mb-3">
                    <label for="input_fontcolor" class="form-label">Shadow color</label>
                    <input id="input_fontcolor" class="form-control" [cpAlphaChannel]="'forced'" [cpOutputFormat]="'hex'" [(colorPicker)]="shadowColor" [style.background]="shadowColor" (colorPickerChange)="onShadowColorChange($event)" />
                </div>

                <div class="mb-3">
                    <label for="input_wordspacing" class="form-label">Shadow X</label>
                    <input class="form-control" id="input_wordspacing" [(ngModel)]="this.commonService.textInputValue.shadows[0].offsetX" type="number" (change)="onChange()">
                </div>
                <div class="mb-3">
                    <label for="input_wordspacing" class="form-label">Shadow Y</label>
                    <input class="form-control" id="input_wordspacing" [(ngModel)]="this.commonService.textInputValue.shadows[0].offsetY" type="number" (change)="onChange()">
                </div>
                <div class="mb-3">
                    <label for="input_wordspacing" class="form-label">Blur radius</label>
                    <input class="form-control" id="input_wordspacing" [(ngModel)]="this.commonService.textInputValue.shadows[0].blurRadius" type="number" (change)="onChange()">
                </div>
            </div>
        </div>
    </div>
</div>