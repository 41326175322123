export enum DataClass {
    Displays,
    Designs,
    Accounts,
    Users,
    Data,
    DataRow,
    Images,
    Videos,
    Partners,
    Playlists,
    Composite,
    Firmware,
    PixaBay,
    AuditLogs
}
