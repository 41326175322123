import { Color } from '@angular-material-components/color-picker';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, Validators } from '@angular/forms';
import { iGoogleFont } from '@tdonselaar/ngx-google-fonts2/lib/models/google-font';
import { ColorPickerService } from 'ngx-color-picker';
import { FontStyle } from 'src/app/account/editor/enum/font-style';
import { FontWeight } from 'src/app/account/editor/enum/font-weight';
import { LayerTypes } from 'src/app/account/editor/enum/layer-types';
import { WrapAlignment } from 'src/app/account/editor/enum/wrap-alignment';
import { LayerText } from 'src/app/account/editor/models/layer-text';
import { EditorService } from 'src/app/account/editor/services/editor.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-text-style-editor',
  templateUrl: './text-style-editor.component.html',
  styleUrls: ['./text-style-editor.component.scss']
})
export class TextStyleEditorComponent implements OnInit, OnDestroy {
  textColor: String = '#000000';
  backgroundColor: String = '#ffffff';
  shadowColor: String = '#000000';

  italic: boolean = false;
  hover: boolean = false;
  focus: boolean = true;

  constructor(public commonService: CommonService, private editorService: EditorService, private cpService: ColorPickerService) {

    this.editorService.onTextLayerUpdate = () => {
      let data = this.editorService.formatWidget(this.editorService.selectedLayer.widget.childWidget);
      
      if (data.type == LayerTypes.table) {
        if (!Array.isArray(this.commonService.textInputValue.shadows) || this.commonService.textInputValue.shadows.length == 0) {
          this.commonService.textInputValue.shadows = [{ offsetX: 0, offsetY: 0, blurRadius: 0, color: { r: 0, g: 0, b: 0, a: 0 } }];
        }
        this.textColor = this.rgbToHex(this.commonService.textInputValue.color.r, this.commonService.textInputValue.color.g, this.commonService.textInputValue.color.b, this.commonService.textInputValue.color.a / 255);
        this.backgroundColor = this.rgbToHex(this.commonService.textInputValue.backgroundColor.r, this.commonService.textInputValue.backgroundColor.g, this.commonService.textInputValue.backgroundColor.b, this.commonService.textInputValue.backgroundColor.a / 255);
        this.shadowColor = this.rgbToHex(this.commonService.textInputValue.shadows[0].color.r, this.commonService.textInputValue.shadows[0].color.g, this.commonService.textInputValue.shadows[0].color.b, this.commonService.textInputValue.shadows[0].color.a / 255);
      } else if (data.type == LayerTypes.text) {
        if (!Array.isArray(data.data.shadows) || data.data.shadows.length == 0) {
          data.data.shadows = [{ offsetX: 0, offsetY: 0, blurRadius: 0, color: { r: 0, g: 0, b: 0, a: 0 } }];
        }
        this.commonService.textInputValue = data.data;
        this.textColor = this.rgbToHex(this.commonService.textInputValue.color.r, this.commonService.textInputValue.color.g, this.commonService.textInputValue.color.b, this.commonService.textInputValue.color.a / 255);
        this.backgroundColor = this.rgbToHex(this.commonService.textInputValue.backgroundColor.r, this.commonService.textInputValue.backgroundColor.g, this.commonService.textInputValue.backgroundColor.b, this.commonService.textInputValue.backgroundColor.a / 255);
        this.shadowColor = this.rgbToHex(this.commonService.textInputValue.shadows[0].color.r, this.commonService.textInputValue.shadows[0].color.g, this.commonService.textInputValue.shadows[0].color.b, this.commonService.textInputValue.shadows[0].color.a / 255);
      }
    };

    this.commonService.textInputValueChange = () => {
      let data = this.editorService.formatWidget(this.editorService.selectedLayer.widget.childWidget);

      if (data.type == LayerTypes.table) {
        if (!this.commonService.textInputIsGlobal) {
          if (Array.isArray(data.data.displayedColumns) && Array.isArray(data.data.tableDataSource)) {
            if (this.commonService.tableInputValue != null) {
              if (this.commonService.tableInputValue.isColumn) {
                data.data.displayedColumns[this.commonService.tableInputValue.column].layerText = JSON.parse(JSON.stringify(this.commonService.textInputValue));
              } else {
                data.data.tableDataSource[this.commonService.tableInputValue.row][this.commonService.tableInputValue.column].layerText = JSON.parse(JSON.stringify(this.commonService.textInputValue));
              }
            }
            this.editorService.updateTableLayer(this.editorService.selectedLayer, data.data.displayedColumns, data.data.tableDataSource);
          }
        }else{
          if (Array.isArray(data.data.displayedColumns) && Array.isArray(data.data.tableDataSource)) { 
            for(let i = 0; i < data.data.tableDataSource.length; i++){
              for(let j = 0; j < data.data.tableDataSource[i].length; j++){
                let layerText = JSON.parse(JSON.stringify(this.commonService.textInputValue));
                layerText.text = data.data.tableDataSource[i][j].layerText.text;
                data.data.tableDataSource[i][j].layerText = layerText;
              }
            }
            this.editorService.updateTableLayer(this.editorService.selectedLayer, data.data.displayedColumns, data.data.tableDataSource);
          }
        }
      } else if (data.type == LayerTypes.text) {
        this.editorService.updateTextLayer(this.editorService.selectedLayer, this.commonService.textInputValue);
      }
    };
  }
  ngOnDestroy(): void {
    this.commonService.textInputIsGlobal = false;
  }
  //on text input change rgbs
  onTextColorChange(color: String): void {
    this.commonService.textInputValue.color.r = parseInt(color.substring(1, 3), 16);
    this.commonService.textInputValue.color.g = parseInt(color.substring(3, 5), 16);
    this.commonService.textInputValue.color.b = parseInt(color.substring(5, 7), 16);
    this.commonService.textInputValue.color.a = parseInt(color.substring(7, 9), 16); // Assuming the alpha value is in the range of 0-255

    this.commonService.textInputValueChange();
  }

  onBackgroundColorChange(color: String): void {

    this.commonService.textInputValue.backgroundColor.r = parseInt(color.substring(1, 3), 16);
    this.commonService.textInputValue.backgroundColor.g = parseInt(color.substring(3, 5), 16);
    this.commonService.textInputValue.backgroundColor.b = parseInt(color.substring(5, 7), 16);
    this.commonService.textInputValue.backgroundColor.a = parseInt(color.substring(7, 9), 16); // Assuming the alpha value is in the range of 0-255

    this.commonService.textInputValueChange();
  }

  onShadowColorChange(color: String): void {
    if (!Array.isArray(this.commonService.textInputValue.shadows) || this.commonService.textInputValue.shadows.length == 0) {
      this.commonService.textInputValue.shadows = [{ offsetX: 0, offsetY: 0, blurRadius: 0, color: { r: 0, g: 0, b: 0, a: 0 } }];
    }
    this.commonService.textInputValue.shadows[0].color.r = parseInt(color.substring(1, 3), 16);
    this.commonService.textInputValue.shadows[0].color.g = parseInt(color.substring(3, 5), 16);
    this.commonService.textInputValue.shadows[0].color.b = parseInt(color.substring(5, 7), 16);
    this.commonService.textInputValue.shadows[0].color.a = parseInt(color.substring(7, 9), 16); // Assuming the alpha value is in the range of 0-255

    this.commonService.textInputValueChange();
  }

  onFocus(): void {
    this.focus = true;
    // if (!this.editorOpen) {
    //   this.editorOpen = true;
    //   this.commonService.selectedInputFocusChange();
    //   this.commonService.selectedInputFocusChange = () => {
    //     this.editorOpen = false;
    //   };
    // }
  }

  onBlur(): void {
    this.focus = false;
  }

  onHover(): void {
    this.hover = true;
  }

  onLeave(): void {
    this.hover = false;
  }

  close(): void {
    this.commonService.textInputIsGlobal = false;
    this.commonService.textEditorOpen = false;
  }

  ngOnInit(): void {
    this.textColor = this.rgbToHex(this.commonService.textInputValue.color.r, this.commonService.textInputValue.color.g, this.commonService.textInputValue.color.b, this.commonService.textInputValue.color.a / 255);
    this.backgroundColor = this.rgbToHex(this.commonService.textInputValue.backgroundColor.r, this.commonService.textInputValue.backgroundColor.g, this.commonService.textInputValue.backgroundColor.b, this.commonService.textInputValue.backgroundColor.a / 255);
    if (!Array.isArray(this.commonService.textInputValue.shadows) || this.commonService.textInputValue.shadows.length == 0) {
      this.commonService.textInputValue.shadows = [{ offsetX: 0, offsetY: 0, blurRadius: 0, color: { r: 0, g: 0, b: 0, a: 0 } }];
    }

    this.shadowColor = this.rgbToHex(this.commonService.textInputValue.shadows[0].color.r, this.commonService.textInputValue.shadows[0].color.g, this.commonService.textInputValue.shadows[0].color.b, this.commonService.textInputValue.shadows[0].color.a / 255);
  }

  getStyles(): object {
    let data = {
      'font-size': this.commonService.textInputValue.fontSize + 'px',
      'font-style': this.getFontStyle(),
      'font-weight': this.getFontWeight(),
      'font-family': this.commonService.textInputValue.fontFamily,
      'letter-spacing': this.commonService.textInputValue.letterSpacing + 'px',
      'word-spacing': this.commonService.textInputValue.wordSpacing + 'px',
      'color': this.rgbToHex(this.commonService.textInputValue.color.r, this.commonService.textInputValue.color.g, this.commonService.textInputValue.color.b, this.commonService.textInputValue.color.a / 255),
      'background-color': this.rgbToHex(this.commonService.textInputValue.backgroundColor.r, this.commonService.textInputValue.backgroundColor.g, this.commonService.textInputValue.backgroundColor.b, this.commonService.textInputValue.backgroundColor.a / 255),
      'text-overflow': this.getOverflow(),
      'border-top': this.commonService.textInputValue.edgeInsets.top + 'px solid white',
      'border-left': this.commonService.textInputValue.edgeInsets.left + 'px solid white',
      'border-bottom': this.commonService.textInputValue.edgeInsets.bottom + 'px solid white',
      'border-right': this.commonService.textInputValue.edgeInsets.right + 'px solid white',
      'text-align': this.getTextAlign(),
      'white-space': 'nowrap',
    };

    return data;
  }

  getTextAlign(): string {
    switch (Number(this.commonService.textInputValue.wrapAlignment)) {
      case WrapAlignment.start:
        return 'left';
      case WrapAlignment.end:
        return 'right';
      case WrapAlignment.center:
        return 'center';
      case WrapAlignment.spaceBetween:
        return 'justify';
      case WrapAlignment.spaceAround:
        return 'justify';
      case WrapAlignment.spaceEvenly:
        return 'justify';
      default:
        return 'left';
    }
  }


  onChange(): void {
    this.commonService.textInputValueChange();
  }

  hexToRgb(hex: string): { r: number, g: number, b: number, a: number } {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    const a = parseInt(hex.slice(7, 9), 16) / 255;
    return { r, g, b, a };
  }

  rgbToHex(r: number, g: number, b: number, a: number) {
    const toHex = (n) => {
      const hex = n.toString(16);
      return hex.length === 1 ? '0' + hex : hex;
    };

    const rHex = toHex(r);
    const gHex = toHex(g);
    const bHex = toHex(b);
    const aHex = a !== undefined ? toHex(Math.round(a * 255)) : '';
    return `#${rHex}${gHex}${bHex}${aHex}`;
  }

  getFontColor(): string {
    return this.rgbToHex(this.commonService.textInputValue.color.r, this.commonService.textInputValue.color.g, this.commonService.textInputValue.color.b, this.commonService.textInputValue.color.a);
  }

  getBackgroundColor(): string {
    return this.rgbToHex(this.commonService.textInputValue.backgroundColor.r, this.commonService.textInputValue.backgroundColor.g, this.commonService.textInputValue.backgroundColor.b, this.commonService.textInputValue.backgroundColor.a);
  }

  getShadowColor(): string {
    if (!Array.isArray(this.commonService.textInputValue.shadows) || this.commonService.textInputValue.shadows.length == 0) {
      return '#000000';
    }
    return this.rgbToHex(this.commonService.textInputValue.shadows[0].color.r, this.commonService.textInputValue.shadows[0].color.g, this.commonService.textInputValue.shadows[0].color.b, this.commonService.textInputValue.shadows[0].color.a);
  }

  getFontStyle(): string {
    return Number(this.commonService.textInputValue.fontStyle) === 0 ? 'normal' : 'italic';
  }

  getFontWeight(): string {
    switch (Number(this.commonService.textInputValue.fontWeight)) {
      case FontWeight.normal:
        return 'normal';
      case FontWeight.bold:
        return 'bold';
      case FontWeight.w100:
        return '100';
      case FontWeight.w200:
        return '200';
      case FontWeight.w300:
        return '300';
      case FontWeight.w400:
        return '400';
      case FontWeight.w500:
        return '500';
      case FontWeight.w600:
        return '600';
      case FontWeight.w700:
        return '700';
      case FontWeight.w800:
        return '800';
      case FontWeight.w900:
        return '900';
      default:
        return 'normal';
    }
  }

  getOverflow(): string {
    switch (Number(this.commonService.textInputValue.overflow)) {
      case 0:
        return 'clip';
      case 1:
        return 'fade';
      case 2:
        return 'ellipsis';
      case 3:
        return 'visible';
      default:
        return 'clip';
    }
  }

  onFontStyleChange(value: any): void {
    this.commonService.textInputValue.fontStyle = value.source.checked ? FontStyle.italic : FontStyle.normal;
    this.commonService.textInputValueChange();
  }

  onWrapAlignment(value: string): void {
    //this.commonService.textInputValue.wrapAlignment = value;
    this.commonService.textInputValueChange();
  }

  googleFontApiKey: string = 'AIzaSyCbp9U7m8NCJzh0t8rXJaVoyZesa5jb28I';

  /**
   * iGoogleFont is the data type for the font:
   * font family, variants, and other data from google fonts api
   */
  onFontChange(font: iGoogleFont): void {
    this.commonService.textInputValue.fontFamily = font.family;
    this.commonService.textInputValueChange();
  }

  /**
   * Font hover can be useful for preview purposes,
   * such as showing the user how it would look before selecting it
   */
  onFontHover(font: iGoogleFont): void {

  }
}
