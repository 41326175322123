<div class="modal-main" cdkDrag [style.width]="data.width+'px'" [style.height]="data.height+'px'">
    <div class="modal-head" cdkDragHandle>
        <div class="modal-title">{{data.title}}</div>
        <mat-icon class="action-icons close-action" mat-dialog-close>close</mat-icon>
    </div>
    <div class="modal-body">
        <div *ngIf="data.dataClass == dataClasses.Designs || data.dataClass == dataClasses.Displays || data.dataClass == dataClasses.Data">
            <input class="form-control" [(ngModel)]="data.data.name">
        </div>

        <div *ngIf="data.dataClass == dataClasses.Data">
            <!-- <mat-form-field appearance="fill">
                <mat-label>Type</mat-label>
                <mat-select [(ngModel)]="data.data.selected_class.name">
                    <mat-option *ngFor="let type of dataClassType" [value]="type.name">
                        {{type.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field> -->
        </div>

        <div *ngIf="data.dataClass == dataClasses.Firmware">
            <div style="display: flex;">
                <mat-form-field appearance="fill">
                    <mat-label>Type</mat-label>
                    <mat-select [(ngModel)]="data.data.status">
                        <mat-option [value]="0">None</mat-option>
                        <mat-option [value]="1">Beta</mat-option>
                        <mat-option [value]="2">Live</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div>
                <textarea style="height: 20em;" class="form-control" [(ngModel)]="data.data.post_script" placeholder="Post script"></textarea>
            </div>
            <div>
                <textarea style="height: 20em;" class="form-control" [(ngModel)]="data.data.script" placeholder="Script"></textarea>
            </div>
            <div style="    display: flex;">
                <a class="btn" href="{{data.data.firmware_url}}">Download Firmware</a>
            </div>
        </div>
    </div>
    <div class="actions">
        <button mat-button mat-dialog-close cdkFocusInitial>Close</button>
        <button mat-button (click)="onSave()">Save</button>
    </div>
</div>