import { AfterViewInit, Component, Input } from '@angular/core';
import { Breadcrumbs } from 'src/app/models/breadcrumbs';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements AfterViewInit {
  breadcrumbs: any[] = [];

  @Input() crumbs: Breadcrumbs[] = [];
  @Input() isPartner = false;

  constructor(private authService: AuthService) { 
    
  }

  ngAfterViewInit() {
    this.breadcrumbs.push({
      name: "Dashboard",
      link: (this.isPartner ? this.authService.getPartnerBasePath() : this.authService.getAccountBasePath())+"/dashboard"
    });

    this.crumbs.forEach((crumb: Breadcrumbs) => {
      this.breadcrumbs.push(crumb);
    });
  }
}
