import { Component } from '@angular/core';
import { EditorService } from '../../services/editor.service';

@Component({
  selector: 'app-layersetings',
  templateUrl: './layersetings.component.html',
  styleUrls: ['./layersetings.component.css']
})
export class LayersetingsComponent {

  color: string = '#000000';
  borderColor: string = '#000000';
  boxShadow: string = '#000000';

  constructor(public editorService: EditorService) {
    this.editorService.onUpdateLayerSetings = () => {
      this.color = this.rgbToHex(this.editorService.selectedLayer.widget.settings.color.r, this.editorService.selectedLayer.widget.settings.color.g, this.editorService.selectedLayer.widget.settings.color.b, this.editorService.selectedLayer.widget.settings.color.a / 255);
      this.borderColor = this.rgbToHex(this.editorService.selectedLayer.widget.settings.borderColor.r, this.editorService.selectedLayer.widget.settings.borderColor.g, this.editorService.selectedLayer.widget.settings.borderColor.b, this.editorService.selectedLayer.widget.settings.borderColor.a / 255);
      this.boxShadow = this.rgbToHex(this.editorService.selectedLayer.widget.settings.boxShadow.r, this.editorService.selectedLayer.widget.settings.boxShadow.g, this.editorService.selectedLayer.widget.settings.boxShadow.b, this.editorService.selectedLayer.widget.settings.boxShadow.a / 255);
    };
  }

  onLeftChange(event: any) {
    if (this.editorService.selectedLayer == null) return;
    this.editorService.selectedLayer.widget.settings.left = parseFloat(event.target.value);
    this.editorService.updateLayerSettings(this.editorService.selectedLayer.widget.id, this.editorService.selectedLayer.widget.settings);
  }

  onTopChange(event: any) {
    if (this.editorService.selectedLayer == null) return;
    this.editorService.selectedLayer.widget.settings.top = parseFloat(event.target.value);
    this.editorService.updateLayerSettings(this.editorService.selectedLayer.widget.id, this.editorService.selectedLayer.widget.settings);
  }

  onWidthChange(event: any) {
    if (this.editorService.selectedLayer == null) return;
    this.editorService.selectedLayer.widget.settings.width = parseFloat(event.target.value);
    this.editorService.updateLayerSettings(this.editorService.selectedLayer.widget.id, this.editorService.selectedLayer.widget.settings);
  }

  onHeightChange(event: any) {
    if (this.editorService.selectedLayer == null) return;
    this.editorService.selectedLayer.widget.settings.height = parseFloat(event.target.value);
    this.editorService.updateLayerSettings(this.editorService.selectedLayer.widget.id, this.editorService.selectedLayer.widget.settings);
  }

  onOpacityChange(event: any) {
    if (this.editorService.selectedLayer == null) return;
    this.editorService.selectedLayer.widget.settings.opacity = parseFloat(event.target.value);
    this.editorService.updateLayerSettings(this.editorService.selectedLayer.widget.id, this.editorService.selectedLayer.widget.settings);
  }

  onAngleChange(event: any) {
    if (this.editorService.selectedLayer == null) return;
    this.editorService.selectedLayer.widget.settings.angle = parseFloat(event.target.value);
    this.editorService.updateLayerSettings(this.editorService.selectedLayer.widget.id, this.editorService.selectedLayer.widget.settings);
  }

  onBorderSizeChange(event: any) {
    if (this.editorService.selectedLayer == null) return;
    this.editorService.selectedLayer.widget.settings.borderSize = parseFloat(event.target.value);
    this.editorService.updateLayerSettings(this.editorService.selectedLayer.widget.id, this.editorService.selectedLayer.widget.settings);
  }

  onBorderRadiusChange(event: any) {
    if (this.editorService.selectedLayer == null) return;
    this.editorService.selectedLayer.widget.settings.borderRadius = parseInt(event.target.value);
    this.editorService.updateLayerSettings(this.editorService.selectedLayer.widget.id, this.editorService.selectedLayer.widget.settings);
  }

  onBlurValueChange(event: any) {
    if (this.editorService.selectedLayer == null) return;
    this.editorService.selectedLayer.widget.settings.blurValue = parseFloat(event.target.value);
    this.editorService.updateLayerSettings(this.editorService.selectedLayer.widget.id, this.editorService.selectedLayer.widget.settings);
  }

  onIsCircleChange(event: any) {
    if (this.editorService.selectedLayer == null) return;
    this.editorService.selectedLayer.widget.settings.isCircle = event.target.checked == true ? true : false;
    this.editorService.updateLayerSettings(this.editorService.selectedLayer.widget.id, this.editorService.selectedLayer.widget.settings);
  }

  onBoxShadowEnabledChange(event: any) {
    if (this.editorService.selectedLayer == null) return;
    this.editorService.selectedLayer.widget.settings.boxShadowEnabled = event.target.checked == true ? true : false;
    this.editorService.updateLayerSettings(this.editorService.selectedLayer.widget.id, this.editorService.selectedLayer.widget.settings);
  }

  onBoxShadowOffSetXChange(event: any) {
    if (this.editorService.selectedLayer == null) return;
    this.editorService.selectedLayer.widget.settings.boxShadowOffSetX = parseFloat(event.target.value);
    this.editorService.updateLayerSettings(this.editorService.selectedLayer.widget.id, this.editorService.selectedLayer.widget.settings);
  }

  onBoxShadowOffSetYChange(event: any) {
    if (this.editorService.selectedLayer == null) return;
    this.editorService.selectedLayer.widget.settings.boxShadowOffSetY = parseFloat(event.target.value);
    this.editorService.updateLayerSettings(this.editorService.selectedLayer.widget.id, this.editorService.selectedLayer.widget.settings);
  }

  onBoxShadowBlurChange(event: any) {
    if (this.editorService.selectedLayer == null) return;
    this.editorService.selectedLayer.widget.settings.boxShadowSpreadRadius = parseFloat(event.target.value);
    this.editorService.updateLayerSettings(this.editorService.selectedLayer.widget.id, this.editorService.selectedLayer.widget.settings);
  }
  
  onTextColorChange(color: String): void {
    this.editorService.selectedLayer.widget.settings.color.r = parseInt(color.substring(1, 3), 16);
    this.editorService.selectedLayer.widget.settings.color.g = parseInt(color.substring(3, 5), 16);
    this.editorService.selectedLayer.widget.settings.color.b = parseInt(color.substring(5, 7), 16);
    this.editorService.selectedLayer.widget.settings.color.a = parseInt(color.substring(7, 9), 16); // Assuming the alpha value is in the range of 0-255
    this.editorService.updateLayerSettings(this.editorService.selectedLayer.widget.id, this.editorService.selectedLayer.widget.settings);
  }

  onBorderColorChange(color: String): void {
    this.editorService.selectedLayer.widget.settings.borderColor.r = parseInt(color.substring(1, 3), 16);
    this.editorService.selectedLayer.widget.settings.borderColor.g = parseInt(color.substring(3, 5), 16);
    this.editorService.selectedLayer.widget.settings.borderColor.b = parseInt(color.substring(5, 7), 16);
    this.editorService.selectedLayer.widget.settings.borderColor.a = parseInt(color.substring(7, 9), 16); // Assuming the alpha value is in the range of 0-255
    this.editorService.updateLayerSettings(this.editorService.selectedLayer.widget.id, this.editorService.selectedLayer.widget.settings);
  }

  onBoxShadowChange(color: String): void {
    this.editorService.selectedLayer.widget.settings.boxShadow.r = parseInt(color.substring(1, 3), 16);
    this.editorService.selectedLayer.widget.settings.boxShadow.g = parseInt(color.substring(3, 5), 16);
    this.editorService.selectedLayer.widget.settings.boxShadow.b = parseInt(color.substring(5, 7), 16);
    this.editorService.selectedLayer.widget.settings.boxShadow.a = parseInt(color.substring(7, 9), 16); // Assuming the alpha value is in the range of 0-255
    this.editorService.updateLayerSettings(this.editorService.selectedLayer.widget.id, this.editorService.selectedLayer.widget.settings);
  }

  onSkewxChange(event: any) {
    if (this.editorService.selectedLayer == null) return;
    this.editorService.selectedLayer.widget.settings.skewX = parseFloat(event.target.value);
    this.editorService.updateLayerSettings(this.editorService.selectedLayer.widget.id, this.editorService.selectedLayer.widget.settings);
  }

  onSkewyChange(event: any) {
    if (this.editorService.selectedLayer == null) return;
    this.editorService.selectedLayer.widget.settings.skewY = parseFloat(event.target.value);
    this.editorService.updateLayerSettings(this.editorService.selectedLayer.widget.id, this.editorService.selectedLayer.widget.settings);
  }

  rgbToHex(r: number, g: number, b: number, a: number) {
    const toHex = (n) => {
      const hex = n.toString(16);
      return hex.length === 1 ? '0' + hex : hex;
    };

    const rHex = toHex(r);
    const gHex = toHex(g);
    const bHex = toHex(b);
    const aHex = a !== undefined ? toHex(Math.round(a * 255)) : '';
    return `#${rHex}${gHex}${bHex}${aHex}`;
  }
}
