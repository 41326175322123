<div class="modal-main" cdkDrag>
    <div class="modal-head" cdkDragHandle>
        <div class="modal-title">{{data.name}}</div>
        <mat-icon class="action-icons close-action" (click)="cancel()">close</mat-icon>
    </div>
    <div class="modal-body">
        <div class="image-config">
            <div style="width: 31em; height: 17em;">
                <div *ngIf="data.meta.url" style="background-image: url('{{data.meta.url}}');" style="width: 100%; height: 100%;background-repeat: no-repeat;
            background-size: contain;">
                </div>

                <div class="waiting-for-image" *ngIf="!data.meta.url">
                    <span>Generating image</span>
                </div>
            </div>

            <div class="image-settings">
                <table class="table">
                    <tr>
                        <td >Name</td>
                        <td><input class="form-control" placeholder="Name" [(ngModel)]="data.name"></td>
                    </tr>
                    <tr>
                        <td style="width: 10em;">Tags:</td>
                        <td >
                            <div class="tags-list">
                                <div *ngFor="let tag of tags; let i2 = index">
                                    <span class="tag"><input class="form-control" [(ngModel)]="tag.value" /><button mat-stroked-button (click)="tags.splice(i2,1)">Remove</button></span>
                                </div>
                                <button mat-stroked-button (click)="addTag()">Add tag</button>
                            </div>
                        </td>
                    </tr>
                    <tr *ngIf="isFeed">
                        <td>URL</td>
                        <td><input class="form-control" [(ngModel)]="data.feed.meta.url"></td>
                    </tr>
                    <tr *ngIf="isFeed">
                        <td>width</td>
                        <td><input class="form-control" [(ngModel)]="data.feed.meta.width"></td>
                    </tr>
                    <tr *ngIf="isFeed">
                        <td>height</td>
                        <td><input class="form-control" [(ngModel)]="data.feed.meta.height"></td>
                    </tr>
                    <tr *ngIf="isFeed">
                        <td>Screenshot Delay (ms)</td>
                        <td><input class="form-control" [(ngModel)]="data.feed.meta.screenshotDelay"></td>
                    </tr>
                    <tr *ngIf="isFeed">
                        <td>Check Js Errors</td>
                        <td style="float: left;">
                            <mat-checkbox (click)="stopPropagation($event)" [(ngModel)]="data.feed.meta.checkJsErrors"></mat-checkbox>
                        </td>
                    </tr>
                    <tr *ngIf="isFeed">
                        <td>Check assets loaded</td>
                        <td style="float: left;">
                            <mat-checkbox (click)="stopPropagation($event)" [(ngModel)]="data.feed.meta.checkAssetLoading"></mat-checkbox>
                        </td>
                    </tr>
                    <tr *ngIf="isFeed">
                        <td>Check for blank screen</td>
                        <td style="float: left;">
                            <mat-checkbox (click)="stopPropagation($event)" [(ngModel)]="data.feed.meta.blankScreenCheck"></mat-checkbox>
                        </td>
                    </tr>
                    <tr *ngIf="isFeed">
                        <td style="width: 10em;">Lastupdate</td>
                        <td><span style="float: left">{{getLastUpdateHuman(data.feed.mutationDate)}}</span></td>
                    </tr>
                    <tr *ngIf="isFeed && data.feed.meta.lastErrors">
                        <td>Last error</td>
                        <td style="float: left;">
                            <span *ngIf="data.feed.meta.lastErrors.length == 0">No errors</span>
                            <div style="max-width: 35em;
                            display: block;
                            word-break: break-all; height: 5em; overflow: auto;" *ngIf="data.feed.meta.lastErrors.length > 0">{{data.feed.meta.lastErrors[0].message}}</div>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
    <div class="actions">
        <button mat-button (click)="cancel()">Close</button>
        <button mat-button (click)="save()">Save</button>
    </div>
</div>