<div class="modal-main" cdkDrag>
    <div class="modal-head" cdkDragHandle>
        <div class="modal-title">Partners</div>
        <mat-icon class="action-icons close-action" (click)="close()">close</mat-icon>
    </div>
    <div class="modal-body">
        <div class="account-list">
            <table class="table">
                <thead>
                    <tr>
                        <th>Partner</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let account of accounts">
                        <td class="align-middle">{{account.info.name}}</td>
                        <td>
                            <button type="button" class="btn" (click)="selectAccount(account)">Select</button>
                        </td>
                    </tr>
            </table>
        </div>
        <div>
            <!-- <button type="button" class="btn" (click)="openAccountCreate()">Create partner</button> -->
        </div>
    </div>
</div>