<div class="modal-main" cdkDrag>
    <div class="modal-head" cdkDragHandle>
        <div class="modal-title">{{playlist.name}}</div>
        <mat-icon class="action-icons close-action" (click)="close()">close</mat-icon>
    </div>
    <div class="modal-body">
        <mat-tab-group mat-align-tabs="start">
            <mat-tab label="Playlist">
                <div style="padding: 1em;">
                    <div>
                        <div class="mb-3" style="width: 20em;">
                            <input type="text" class="form-control" id="playlistName" [(ngModel)]="playlist.name" (change)="updatePlaylist()">
                        </div>
                    </div>
                    <!-- Playlist -->
                    <div class="list-block">
                        <div class="playlist-action-bar">
                            <div style="display: flex; align-items: center; gap: 0.5em;">
                                <div>Playlist</div>
                                <button mat-button *ngIf="this.selectedComposite" (click)="this.selectedComposite = null">Deselect</button>
                            </div>
                            <div>
                                <div style="display: flex; align-items: center; gap: 0.5em;">
                                    <input style="width: 5em;" *ngIf="this.selectedComposite" type="number" class="form-control" id="compositeTime" [(ngModel)]="this.selectedComposite.display_time" (change)="updateComposite()">
                                    <select class="form-select" *ngIf="this.selectedComposite" [(ngModel)]="this.selectedComposite.cross_fade" (change)="updateComposite()">
                                        <option [ngValue]="true">Synchronous animation</option>
                                        <option [ngValue]="false">Asynchronous animation</option>
                                    </select>

                                    <button *ngIf="this.selectedComposite" mat-button (click)="removeComposite(selectedComposite)">Remove</button>
                                </div>
                            </div>
                        </div>
                        <div class="composite-container-scroll">
                            <div class="composite-container" cdkDropList cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event)">
                                <div class="composite-item" [ngStyle]="{'background-image': composite.meta.images ? 'url('+composite.meta.images[0]+')' : 'unset'}" (click)="selectComposite(composite)" [class.selected]="selectedComposite && selectedComposite.id == composite.id" *ngFor="let composite of playlist.composites"
                                    cdkDrag>
                                    <div title="{{composite.name}}">
                                        {{composite.name}}
                                    </div>
                                </div>
                                <div class="playlist-add-items-tip" *ngIf="playlist.composites.length == 0">Add media from your drive/design library by double clicking on it or selecting multiple and adding it.</div>
                                <!-- <div class="composite-item-new" (click)="addComposite()">
                                    <div>Add</div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <!-- drive -->
                    <div class="list-block" *ngIf="selectedComposite">
                        <div class="playlist-action-bar">
                            <div>Drive:</div>
                            <div style="display: flex; align-items: center; gap: 0.1em;">
                                <div>Items per slide:</div>
                                <input style="width: 5em;" *ngIf="this.selectedComposite" type="number" class="form-control" [(ngModel)]="this.selectedComposite.items_per_composite" (change)="updateComposite()">
                                <button mat-button (click)="addData()">Add</button>
                                <button mat-button (click)="removeData(selectedData)">Remove</button>
                            </div>
                        </div>
                        <div class="drive-container-scroll">
                            <div class="drive-container">
                                <div [class.selected]="selectedData && selectedData.id == datastream.id" [style.background-image]="datastream.data.selected_class.name == 'Image' || datastream.data.selected_class.name == 'Video' ? 'url(' + datastream.data.meta.url_thumb + ')' : ''" class="folder-file"
                                    *ngFor="let datastream of selectedComposite.data_stream" (click)="selectData(datastream)">
                                    <mat-icon class="icon" *ngIf="datastream.data.selected_class.name != 'Video' && datastream.data.selected_class.name != 'Image'">
                                        {{datastream.data.selected_class.name == 'Folder' ? 'folder' : 'insert_drive_file'}}
                                    </mat-icon>
                                    <div class="name" *ngIf="datastream.data.selected_class.name != 'Image' && datastream.data.selected_class.name != 'Video'">
                                        {{datastream.data.name}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- designs -->
                    <div class="list-block" *ngIf="selectedComposite">
                        <div class="playlist-action-bar">
                            <div style="display: flex;
                            align-items: center;
                            gap: 0.5em;">Designs:
                                <div>
                                    <select class="form-select" *ngIf="this.selectedComposite" [(ngModel)]="this.selectedComposite.auto_variation" (change)="updateComposite()">
                                        <option [ngValue]="true">Auto Design Selection</option>
                                        <option [ngValue]="false">Custom Design Selection</option>
                                    </select>
                                </div>
                                <div>
                                    <select class="form-select" *ngIf="this.selectedComposite && this.selectedComposite.auto_variation" [(ngModel)]="this.selectedComposite.aspect_ratio" (change)="updateComposite()">
                                        <option [ngValue]="0">Auto</option>
                                        <option class="upperCaseFirstLetter" *ngFor="let aspectRatio of aspectRatios" [ngValue]="aspectRatio.aspectRatio">{{aspectRatio.orientation}} designs ({{aspectRatio.aspectRatioReadable}})</option>
                                    </select>
                                </div>
                                <div class="theme-selector" *ngIf="this.selectedComposite && this.selectedComposite.auto_variation">
                                    <select class="form-select" [(ngModel)]="this.selectedComposite.themeGroup" (change)="onThemeGroupChange()">
                                        <option [value]="''">All categories</option>
                                        <option *ngFor="let themeGroup of themeGroups" [value]="themeGroup">{{themeGroup}}</option>
                                    </select>

                                    <select *ngIf="this.selectedComposite.themeGroup != ''" class="form-select" [(ngModel)]="this.selectedComposite.themeName" (change)="onThemeChange()">
                                        <option [value]="''">All themes</option>
                                        <option *ngFor="let theme of themes" [value]="theme">{{theme}}</option>
                                    </select>
                                </div>
                                <div class="spinner-border" role="status" *ngIf="lodingTest">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </div>

                            <div>
                                <button *ngIf="this.selectedComposite && !this.selectedComposite.auto_variation" mat-button (click)="addDesign()">Add</button>
                                <button *ngIf="this.selectedComposite && !this.selectedComposite.auto_variation" mat-button (click)="removeDesign(selectedDesign)">Remove</button>
                                <!-- <button mat-button (click)="test()">Test</button> -->
                            </div>
                        </div>
                        <div class="drive-container-scroll">
                            <div class="drive-container" *ngIf="!this.selectedComposite.auto_variation">
                                <div class="folder-file playlistDesign" title="{{variation.design.selectionError ? 'This is a dynamic design. Please add something from your drive.' : ''}}" [class.selectionError]="variation.design.selectionError" [class.selected]="selectedDesign && selectedDesign.id == variation.id"
                                    (click)="selectDesign(variation)" *ngFor="let variation of selectedComposite.variations">
                                    <img crossorigin="anonymous" class="{{variation.design.data.orientation}}" *ngIf="variation.design.data.screenshot != null" [src]="variation.design.data.screenshot">
                                    <div class="name">
                                        {{variation.design.name}}
                                    </div>
                                </div>
                            </div>
                            <div class="drive-container" *ngIf="this.selectedComposite.auto_variation">
                                <div class="folder-file playlistDesign" style="cursor: unset;" *ngFor="let design of autoDesigns">
                                    <img crossorigin="anonymous" class="{{design.data.orientation}}" *ngIf="design.data.screenshot != null" [src]="design.data.screenshot">
                                    <div class="name">
                                        {{design.name}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Inline selector -->
                    <div *ngIf="!selectedComposite">
                        <div class="inline-selector-buttons">
                            <button mat-button style="border-radius: 0;" [ngStyle]="{'background-color': selectorType != 'drive' ? '#434343' : 'unset'}" (click)="selectorType = 'drive'">Drive</button>
                            <div class="inline-selector-buttons-line"></div>
                            <button mat-button style="border-radius: 0;" [ngStyle]="{'background-color': selectorType != 'design' ? '#434343' : 'unset'}" (click)="selectorType = 'design'">Designs</button>
                        </div>
                        <div class="inline-selector-container">
                            <app-data-table *ngIf="selectorType == 'design'" [dataSelect]="true" (onDataSelect)="onDataSelect($event)" [dataClass]="dataClassDesigns" (onTableDataChange)="onTableDataChange($event)"></app-data-table>
                            <app-data-folder *ngIf="selectorType == 'drive'" [dataSelect]="true" (onDataSelect)="onDataFolderSelect($event)" [dataClass]="dataClassData" (onTableDataChange)="onTableDataChange($event)"></app-data-folder>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="Schedule">
                <br />
                <div class="playlist-schedule-container">
                    <div class="playlist-schedule-list">
                        <table class="table table-striped table-dark">
                            <thead>
                                <tr>
                                    <td>Schedule name</td>
                                    <td>Start date</td>
                                    <td>End date</td>
                                    <td>Start time</td>
                                    <td>End time</td>
                                    <td>All day</td>
                                    <td>Monday</td>
                                    <td>Tuesday</td>
                                    <td>Wednesday</td>
                                    <td>Thursday</td>
                                    <td>Friday</td>
                                    <td>Saturday</td>
                                    <td>Sunday</td>
                                    <td></td>
                                </tr>
                            </thead>
                            <tbody *ngIf="playlist.schedules.length > 0">
                                <tr *ngFor="let schedule of playlist.schedules">
                                    <td><input (change)="onChangeSchedule(schedule)" (click)="onCheckboxClick($event)" class="form-control" [(ngModel)]="schedule.name"></td>
                                    <td><input (change)="onChangeSchedule(schedule)" (click)="onCheckboxClick($event)" class="form-control" type="date" [(ngModel)]="schedule.start_date" /></td>
                                    <td><input (change)="onChangeSchedule(schedule)" (click)="onCheckboxClick($event)" class="form-control" type="date" [(ngModel)]="schedule.end_date" /></td>
                                    <td><input [disabled]="schedule.all_day" (change)="onChangeSchedule(schedule)" (click)="onCheckboxClick($event)" class="form-control" type="time" [(ngModel)]="schedule.start_time" /></td>
                                    <td><input [disabled]="schedule.all_day" (change)="onChangeSchedule(schedule)" (click)="onCheckboxClick($event)" class="form-control" type="time" [(ngModel)]="schedule.end_time" /></td>
                                    <td>
                                        <mat-checkbox (change)="onChangeSchedule(schedule)" (click)="onCheckboxClick($event)" [(ngModel)]="schedule.all_day"></mat-checkbox>
                                    </td>
                                    <td>
                                        <mat-checkbox (change)="onChangeSchedule(schedule)" (click)="onCheckboxClick($event)" [(ngModel)]="schedule.monday"></mat-checkbox>
                                    </td>
                                    <td>
                                        <mat-checkbox (change)="onChangeSchedule(schedule)" (click)="onCheckboxClick($event)" [(ngModel)]="schedule.tuesday"></mat-checkbox>
                                    </td>
                                    <td>
                                        <mat-checkbox (change)="onChangeSchedule(schedule)" (click)="onCheckboxClick($event)" [(ngModel)]="schedule.wednesday"></mat-checkbox>
                                    </td>
                                    <td>
                                        <mat-checkbox (change)="onChangeSchedule(schedule)" (click)="onCheckboxClick($event)" [(ngModel)]="schedule.thursday"></mat-checkbox>
                                    </td>
                                    <td>
                                        <mat-checkbox (change)="onChangeSchedule(schedule)" (click)="onCheckboxClick($event)" [(ngModel)]="schedule.friday"></mat-checkbox>
                                    </td>
                                    <td>
                                        <mat-checkbox (change)="onChangeSchedule(schedule)" (click)="onCheckboxClick($event)" [(ngModel)]="schedule.saturday"></mat-checkbox>
                                    </td>
                                    <td>
                                        <mat-checkbox (change)="onChangeSchedule(schedule)" (click)="onCheckboxClick($event)" [(ngModel)]="schedule.sunday"></mat-checkbox>
                                    </td>

                                    <td><button mat-button (click)="removeSchedule(schedule)">Remove schedule</button></td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="add-schedule-button">
                            <button mat-stroked-button (click)="addSchedule()">Add schedule</button>
                        </div>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="Displays">
                <br />
                <div class="playlist-device-container">
                    <div class="playlist-device-list">
                        <table class="table table-striped table-dark">
                            <thead>
                                <tr>
                                    <td>Display name</td>
                                    <td></td>
                                </tr>
                            </thead>
                            <tbody *ngIf="playlist.displays.length > 0">
                                <tr *ngFor="let display of playlist.displays">
                                    <td style="width: 75%;">{{display.name}}</td>
                                    <td><button mat-button (click)="removeDisplay(display)">Remove display</button></td>
                                </tr>
                            </tbody>
                        </table>
                        <button mat-stroked-button (click)="addDisplay()">Add display</button>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>

<app-data-selector *ngIf="dataSelectOpen" (onCloseMedia)="onCloseMedia($event)" [playlist]="playlist" [composite]="selectedComposite"></app-data-selector>
<app-design-selector *ngIf="designSelectOpen" (onCloseDesigns)="onCloseDesigns($event)" [playlist]="playlist" [composite]="selectedComposite"></app-design-selector>
<app-display-selector *ngIf="displaySelectOpen" (onCloseDisplays)="onCloseDisplays($event)" [playlist]="playlist" [composite]="selectedComposite"></app-display-selector>

<div class="loading-adding" *ngIf="loading">
    <div>
        <div>Adding item(s) to playlist</div>
        <mat-spinner [diameter]="25"></mat-spinner>
    </div>
</div>