<div class="modal-main" cdkDrag>
    <div class="modal-head" cdkDragHandle>
        <div class="modal-title">{{data.name}}</div>
        <mat-icon class="action-icons close-action" (click)="cancel()">close</mat-icon>
    </div>
    <div class="modal-body">
        <div  style="display: flex; align-items: flex-start;flex-direction: column;">
              <input style="width: 25em; margin-top: 1em;" class="form-control" [(ngModel)]="data.name" placeholder="Name">
              <input style="width: 25em; margin-top: 1em;" class="form-control" [(ngModel)]="data.meta.url" placeholder="Website URL">

              <div>
                <div class="url-list">
                    <table>
                        <tr>
                            <td>
                                <div style="display: flex;">
                                    <div style="align-self: center;">Reset timeout (seconds):</div> 
                                    <input style="width: 5em" type="number" class="form-control" [(ngModel)]="data.meta.resetTimer" >
                                </div>
                            </td>
                            <td></td>
                        </tr>
                        <tr><td><div style="text-align: left;">Allowed filter:</div></td></tr>
                        <tr *ngFor="let urlVal of data.meta.allowedUrls; let i = index">
                            <td><input style="width: 25em" class="form-control" [value]="urlVal" (change)="onUpdate(i, $event.target.value)" placeholder="Allow urls *google.com* or * for any"></td>
                            <td><button class="btn" (click)="removeURL(i)">Remove</button></td>
                        </tr>
                    </table>
                    <hr />
                    <div class="urls-allows">
                        <input style="width: 25em" class="form-control" [(ngModel)]="newUrl" placeholder="Allow urls *google.com* or * for any">
                        <button class="btn" (click)="addURL()">+ add</button>
                    </div>
                </div>
              </div>
            
        </div>
    </div>
    <div class="actions">
        <button mat-button (click)="cancel()">Close</button>
        <button mat-button (click)="save()">Save</button>
    </div>
</div>