<div class="topbar-container">
    <button mat-icon-button class="back-button" (click)="back()">
            <mat-icon>arrow_back_ios</mat-icon>
        </button>
    <div class="main-menu" mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon>menu</mat-icon>
    </div>
    <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="openSettings()">
        <span>Settings</span>
      </button>
        <button mat-menu-item (click)="load()">
          <span>Load</span>
        </button>
        <button mat-menu-item (click)="save()">
            <span>Save</span>
          </button>
    </mat-menu>
    <div class="action-buttons-container">
        <div class="main-menu" mat-icon-button [matMenuTriggerFor]="MenuLayers">
            <mat-icon>dashboard_customize</mat-icon>
        </div>
        <mat-menu #MenuLayers="matMenu">
            <button mat-menu-item (click)="createTextLayer()">
            <span>Text</span>
          </button>
            <button mat-menu-item (click)="createImageLayer()">
          <span>Image</span>
        </button>
            <button mat-menu-item (click)="createVideoLayer()">
            <span>Video</span>
          </button>
            <!-- <button mat-menu-item (click)="createPlaylistLayer()">
            <span>Playlist</span>
          </button> -->
            <button mat-menu-item (click)="createTableLayer()">
            <span>Table</span>
          </button>
            <button mat-menu-item (click)="createWebviewLayer()">
            <span>Webpage (android)</span>
          </button>
        </mat-menu>
        <div class="action-buttons">
            <button [disabled]="editorService.history.length == 0" mat-icon-button (click)="editorService.undo()">
              <mat-icon>undo</mat-icon>
            </button>
            <button [disabled]="editorService.selectedLayers.length == 0" mat-icon-button (click)="editorService.addToCopyList()">
              <mat-icon>content_copy</mat-icon>
            </button>
            <button [disabled]="editorService.selectedForCopy.length == 0" mat-icon-button (click)="editorService.copySelectedLayers()">
              <mat-icon>content_paste</mat-icon>
            </button>
            <button [disabled]="editorService.selectedLayers.length == 0" mat-icon-button (click)="editorService.deleteSelectedLayers()">
              <mat-icon>delete</mat-icon>
            </button>
            <!-- <button class="main-menu-edit-layer" [class]="!editorService.isSnapToLayer ? 'snapton' : ''" mat-icon-button (click)="editorService.setSnapToLayer(editorService.isSnapToLayer)">
              <mat-icon>layers</mat-icon>
            </button>
            <button class="main-menu-edit-layer" [class]="!editorService.isSnapToGrid ? 'snapton' : ''" mat-icon-button (click)="editorService.setSnapToGrid(editorService.isSnapToGrid)">
              <mat-icon>grid_on</mat-icon>
            </button> -->
            <button [disabled]="editorService.selectedLayers.length == 0" class="main-menu-edit-layer" mat-icon-button (click)="editorService.overlay.settings = true">
                <mat-icon>settings</mat-icon>
            </button>
        </div>
    </div>


    <div class="menu-right">
        <mat-slider class="zoom-slider" color="accent" [max]="1" [min]="0" [step]="0.01">
            <input matSliderThumb [(ngModel)]="editorService.zoom" (valueChange)="changeZoom(editorService.zoom)" #slider>
        </mat-slider>
        <mat-button-toggle-group class="editorSelect" (change)="onEditorTypeChange($event)" [(value)]="editorService.editorType" aria-label="Editor type">
            <mat-button-toggle value="design">Design</mat-button-toggle>
            <mat-button-toggle value="animate">Animate</mat-button-toggle>
        </mat-button-toggle-group>
    </div>
</div>