import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { LayerText } from '../../editor/models/layer-text';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {
  testValue:LayerText = new LayerText();
  
  constructor(public authService: AuthService, private router: Router) { }

  loadCompent(com: string) {
    this.router.navigate([this.authService.getAccountBasePath()+'/'+com]);
  }
}
