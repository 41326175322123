<div class="loginContainer">
    <div class="login overlay" [class.waitingForLogin]="waitingForLogin" style="padding: 0; display: flex;">

        <div style="padding: 4em;" *ngIf="!waitingForLogin && !initLoading && !setupProfile && !user">
            <h1 *ngIf="!authService.isOauth">Account Login</h1>
            <h2 *ngIf="authService.isOauth">
                Connect to {{authService.scope == 'ifttt' ? 'IFTTT' : 'Other'}}
            </h2>
            <table class="table-login">
                <tr>
                    <td>
                        <input (keyup.enter)="login()" style="width: 22em;" class="input" type="email" id="email" placeholder="Email" [(ngModel)]="email" />
                    </td>
                </tr>
                <tr>
                    <td style="padding-top: 1em;">
                        <button mat-raised-button (click)="login()">{{loading ? 'Loading...' : 'Login'}}</button>
                    </td>
                </tr>
            </table>
        </div>

        <div style="padding: 4em; width: 96%;" *ngIf="user && authService.isOauth && !initLoading && !setupProfile">
            <h2 *ngIf="authService.isOauth">
                Connect to {{authService.scope == 'ifttt' ? 'IFTTT' : 'Other'}}
            </h2>
            <div class="waiting">
                <span>Hi {{user.alias}},</span>
                <span>Do you want to connect your account to {{authService.scope}}?</span>
                <div>
                    <button class="btn border-1" (click)="logout()">Logout</button>
                    <button class="btn border-1" (click)="oAuthApprove()">Approve connection</button>
                </div>

            </div>
        </div>

        <div *ngIf="!initLoading" style="background-image: url(assets/images/login_b.jpg);
        width: 100%;
        border-radius: 0 0.5em 0.5em 0;
    background-repeat: round;
        background-size: cover;"></div>

        <div style="padding: 4em;" *ngIf="waitingForLogin && !initLoading && !setupProfile">
            <h1>Account Login</h1>
            <div class="waiting">
                <span>We've just sent you an email with a link to access your account.<br />

                If you haven't received the email in your inbox, please check your spam or junk folder as it might have been mistakenly filtered.<br />
                
                Once you find the email, click the provided link to log in seamlessly.</span>
                <div class="email-buttons">
                    <div class="email-that-was-sent">{{email}}</div>
                    <button class="btn" (click)="cancelLogin()">Change email</button>
                </div>
                <div class="waiting-loader">
                    <mat-spinner diameter="25"></mat-spinner>
                    <span>Waiting for Email Login</span>

                </div>
            </div>
        </div>

        <div style="padding: 4em;" *ngIf="setupProfile">
            <h1>User Profile</h1>
            <div class="waiting">
                <span></span>
                <div>
                    <input style="width: 22em;" class="input" type="text" placeholder="Screen name" [(ngModel)]="user.alias" />
                </div>
                <div>
                    <input style="width: 22em;" class="input" type="text" placeholder="First name " [(ngModel)]="user.firstname" />
                </div>
                <div>
                    <input style="width: 22em;" class="input" type="text" placeholder="Last name" [(ngModel)]="user.lastname" />
                </div>
                <div>
                    <button class="btn" (click)="saveProfile()">Next</button>
                </div>
            </div>
        </div>


        <div style="padding: 4em;" *ngIf="initLoading">
            <div>
                <div style="display: flex; gap: 1em; align-items: center;">
                    <mat-spinner diameter="25"></mat-spinner>
                    <span>Loading...</span>
                </div>
            </div>
        </div>
    </div>
</div>