import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { ApiService } from 'src/app/api/api.service';
import { Data } from 'src/app/api/models/account/data/data';
import { DataChange, DataChangeEvent } from 'src/app/models/data-change';

@Component({
  selector: 'app-data-image',
  templateUrl: './data-image.component.html',
  styleUrls: ['./data-image.component.scss']
})
export class DataImageComponent implements AfterViewInit {

  @Input() data!: Data;
  @Output() onDataChange = new EventEmitter<DataChange>();

  tags: {value: string}[] = [];

  isFeed = false;

  constructor(private apiService: ApiService) { 

  }
  ngAfterViewInit(): void {
    if (this.data.feed) {
      this.isFeed = true;
    }

    if (this.data.meta.tags) {
      this.data.meta.tags.forEach(tag => {
        this.tags.push({ value: tag });
      });
    }
  }

  stopPropagation(event: MouseEvent) {
    event.stopPropagation(); // Prevent the event from bubbling up to the drag container
  }

  save() {
    if(Array.isArray(this.data.meta)){
      this.data.meta = {};
    }
    this.data.meta.tags = [];
    for (let i = 0; i < this.tags.length; i++) {
      if(this.tags[i].value != '')
      this.data.meta.tags.push(this.tags[i].value);
    }
    this.onDataChange.emit({dataChangeEvent: DataChangeEvent.onSave, data: this.data});
  }

  cancel() {
    this.onDataChange.emit({dataChangeEvent: DataChangeEvent.onCancel, data: this.data});
  }

  getLastUpdateHuman(datetime){
    let date = new Date(datetime);
    let now = new Date();
    let seconds = Math.floor((now.getTime() - date.getTime()) / 1000);

    //convert to human readable
    if(seconds > 31536000){
      return Math.floor(seconds / 31536000) + ' years ago';
    }else if(seconds > 2419200){
      return Math.floor(seconds / 2419200) + ' months ago';
    }else if(seconds > 604800){
      return Math.floor(seconds / 604800) + ' weeks ago';
    }else if(seconds > 86400){
      return Math.floor(seconds / 86400) + ' days ago';
    }else if(seconds > 3600){
      return Math.floor(seconds / 3600) + ' hours ago';
    }else if(seconds > 60){
      return Math.floor(seconds / 60) + ' minutes ago';
    }
    return 'just now';
  }

  addTag(){
    this.tags.push({value: ''});
  }
}
