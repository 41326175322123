import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApiService } from 'src/app/api/api.service';
import { Playlist } from 'src/app/api/models/account/display/playlist';
import { DataTableComponent } from 'src/app/components/data-table/data-table.component';
import { EditComponent } from 'src/app/components/edit/edit.component';
import { NewComponent } from 'src/app/components/new/new.component';
import { DataClass } from 'src/app/enums/data-class';
import { TableChangeData, TableChangeDataActions } from 'src/app/models/table-change-data';
import { PopupDialog } from 'src/app/popups/confirm/dialog';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-playlists',
  templateUrl: './playlists.component.html',
  styleUrls: ['./playlists.component.scss']
})
export class PlaylistsComponent {
  @ViewChild(DataTableComponent) dataTableComponent!: DataTableComponent;

  dataClass: DataClass = DataClass.Playlists;
  selected: boolean = false;
  selectedPlaylist: Playlist;

  constructor(private apiService: ApiService, private authService: AuthService, private dialog: MatDialog) { }

  currentLocation(){
    return this.authService.getAccountBasePath() + '/playlists';
  }

  onTableDataChange(event: TableChangeData) {
    if (event.action == TableChangeDataActions.New) {
      this.dialog.open(NewComponent, {
        enterAnimationDuration: 0,
        data: {
          title: 'New playlists',
          dataClass: DataClass.Playlists,
          data: {
            name: 'New playlists '+Math.floor(Math.random() * 1000),
          },
          width: '25em',
          height: 200
        }
      }).afterClosed().subscribe((result: any) => { 
        if (result) {
          this.updateTable();
        }
      });
    }

    if (event.action == TableChangeDataActions.Delete) {
      this.dialog.open(PopupDialog, {
        data: {
          title: 'Delete playlists',
          message: 'Are you sure you want to delete this playlists?'
        }
      }).afterClosed().subscribe((result: any) => {
        if (result) {
          this.apiService.deletePlaylist(this.authService.selectedAccountId, event.item.id).subscribe((data: any) => {
            this.updateTable();
          });
        }
      });
    }

    if (event.action == TableChangeDataActions.DeleteAll) {
      this.dialog.open(PopupDialog, {
        data: {
          title: 'Delete playlists',
          message: 'Are you sure you want to delete all selected playlists?'
        }
      }).afterClosed().subscribe((result: any) => {
        if (result) {
          event.selected.selected.forEach((item: any) => {
            this.apiService.deletePlaylist(this.authService.selectedAccountId, item.id).subscribe((data: any) => {
              this.updateTable();
            });
          });
        }
      });
    }

    if(event.action == TableChangeDataActions.Edit){
      this.selectedPlaylist = event.item;
      this.selected = true;
    }
  }

  updateTable() {
    this.dataTableComponent.updateWatch.emit();
  }

  onClose(){
    this.selected = false;
  }
}
