import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, NgZone, OnDestroy, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { merge, startWith, switchMap, catchError, map, Observable, of as observableOf } from 'rxjs';
import { ApiService } from 'src/app/api/api.service';
import { DataClass } from 'src/app/enums/data-class';
import { TableChangeData, TableChangeDataActions } from 'src/app/models/table-change-data';
import { AuthService } from 'src/app/services/auth.service';
import { Data } from 'src/app/api/models/account/data/data';
import { DataChange, DataChangeEvent } from 'src/app/models/data-change';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-data-folder',
  templateUrl: './data-folder.component.html',
  styleUrls: ['./data-folder.component.scss']
})
export class DataFolderComponent implements AfterViewInit, OnDestroy {
  displayedColumns: string[] = [];
  columnsToDisplay: string[] = [];
  dataSource: any;
  selection = new SelectionModel<any>(true, []);

  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;
  searchQuery: string = '';
  searchTimeout: any = null;

  dataWatcher: any = null;

  selected: {
    image: { isSelected: boolean, item: any },
    video: { isSelected: boolean, item: any },
    feed: { isSelected: boolean, item: any },
    webview: { isSelected: boolean, item: any },
  } = {
      image: { isSelected: false, item: null },
      video: { isSelected: false, item: null },
      feed: { isSelected: false, item: null },
      webview: { isSelected: false, item: null }
    };

  dirRouting: Array<{ name: string, id: number }> = [{ name: 'Home', id: 0 }];

  public updateWatch: EventEmitter<any>;
  public setParent: EventEmitter<Array<{ name: string, id: number }>>;
  public setLoading: EventEmitter<any>;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  sort: MatSort;

  @Output() onTableDataChange = new EventEmitter<TableChangeData>();
  @Output() onDataSelect = new EventEmitter<Data[]>();

  @Input() dataClass: DataClass = DataClass.Designs;
  @Input() dataType: string[] = [];
  @Input() dataSelect: boolean = false;
  @Input() playlistId: number = 0;

  pixabayFilter: any = {
    imageType: 'all',
    orientation: 'all',
    category: '',
    colors: '',
  };

  pixabaySettings: any = {
    imageType: ['all', 'photo', 'illustration'],
    orientation: ['all', 'horizontal', 'vertical'],
    category: ['', 'fashion', 'nature', 'backgrounds', 'science', 'education', 'people', 'feelings', 'religion', 'health', 'places', 'animals', 'industry', 'food', 'computer', 'sports', 'transportation', 'travel', 'buildings', 'business', 'music'],
    colors: ['', 'grayscale', 'transparent', 'red', 'orange', 'yellow', 'green', 'turquoise', 'blue', 'lilac', 'pink', 'white', 'gray', 'black', 'brown']
  };

  showPixaBayImport: boolean = false;


  constructor(private apiService: ApiService, private authService: AuthService, public commonService: CommonService) {
    this.updateWatch = new EventEmitter<any>();
    this.setParent = new EventEmitter<any>();
    this.setLoading = new EventEmitter<any>();
    this.sort = new MatSort();
  }
  ngOnDestroy(): void {
    this.commonService.uploadPlayListId = 0;
    this.commonService.uploadDirId = 0;

    clearInterval(this.dataWatcher);
  }

  getDataClass(): string {
    switch (this.dataClass) {
      case DataClass.Designs:
        return 'designs';
      case DataClass.Accounts:
        return 'accounts';
      case DataClass.Users:
        return 'users';
      case DataClass.Data:
        return 'data';
      case DataClass.Displays:
        return 'displays';
      case DataClass.Images:
        return 'images';
      case DataClass.Playlists:
        return 'playlists';
      case DataClass.Videos:
        return 'videos';
      case DataClass.Partners:
        return 'partners';
      case DataClass.PixaBay:
        return 'pixabay';
      default:
        return 'designs';
    }
  }

  selectData() {
    let selected: Data[] = [];
    this.selection.selected.forEach((item: any) => {
      let addItem = true;
      if (item.selected_class.name == 'Video') {
        if (item.meta?.needToTranscode) {
          if (item.meta?.transcodeStatus != 'job.completed') {
            addItem = false;
          }
        }
      }
      if (addItem) {
        selected.push(item);
      }
    });

    if (selected.length > 0) {
      this.onDataSelect.emit(selected);
    }
    this.selection.clear();
  }

  doubbleClick(item: any) {
    if (item.selected_class.name != 'Folder') {
      if (this.dataSelect) {
        let addItem = true;
        if (item.selected_class.name == 'Video') {
          if (item.meta?.needToTranscode) {
            if (item.meta?.transcodeStatus != 'job.completed') {
              addItem = false;
            }
          }
        }
        if (addItem) {
          this.onDataSelect.emit([item]);
        }
      } else {
        if (item.selected_class.name == 'Image') {
          this.selected.image.isSelected = true;
          this.selected.image.item = item;
        } else if (item.selected_class.name == 'Video') {
          this.selected.video.isSelected = true;
          this.selected.video.item = item;
        } else if (item.selected_class.name == 'Feed' || item.selected_class.name == 'Table') {
          this.selected.feed.isSelected = true;
          this.selected.feed.item = item;
        } else if (item.selected_class.name == 'WebView') {
          this.selected.webview.isSelected = true;
          this.selected.webview.item = item;
        }
      }
    } else {
      this.dirRouting.push({ name: item.name, id: item.id });
      this.commonService.uploadDirId = item.id;
      this.updateWatch.emit();
    }
    this.selection.clear();
  }

  editSelected() {
    if (this.selection.selected.length == 0) {
      return;
    }
    this.edit(this.selection.selected[0]);
  }

  changeDir(id: number) {
    if (this.dirRouting.length == 1) { return; }
    this.dirRouting.splice(id + 1, this.dirRouting.length - id);
    this.updateWatch.emit();
  }

  ngAfterViewInit() {
    //on delete key pressed
    document.addEventListener('keydown', (event) => {
      if (event.key == 'Delete') {
        if (this.selection.selected.length > 0) {
          this.deleteSelected();
          event.preventDefault();
        }
      }
    });
    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => {
      if (this.paginator == null) { return; }
      this.paginator.pageIndex = 0;
    });

    this.commonService.uploadPlayListId = this.playlistId;
    this.commonService.uploadChange = () => {
      this.updateWatch.emit();
    };

    this.setParent.subscribe((data: Array<{ name: string, id: number }>) => {
      this.dirRouting = data;
      this.updateWatch.emit();
    });

    this.setLoading.subscribe(() => {
      this.isLoadingResults = true;
    }); 

    merge(this.sort.sortChange, this.paginator.page, this.updateWatch)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.selection.clear();
          this.isLoadingResults = true;
          return this.doRequest().pipe(catchError(() => observableOf(null)));
        }),
        map(data => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = data === null;

          if (data === null) {
            return [];
          }

          // Only refresh the result length if there is new data. In case of rate
          // limit errors, we do not want to reset the paginator to zero, as that
          // would prevent users from re-triggering requests.
          this.resultsLength = data.count;

          data.results.map((item: any, index: number) => {
            item = this.modifyData(item);
          });

          if (this.displayedColumns.length == 0 && data.results.length > 0) {
            this.displayedColumns = Object.keys(data.results[0]);
            this.displayedColumns.unshift('select');
            this.displayedColumns.push('actions');
            this.displayedColumns = this.columnOrder(this.displayedColumns);
            this.displayedColumns = this.columnFilter(this.displayedColumns);
            this.columnsToDisplay = this.displayedColumns.slice();
          }

          data.results.map((item: any, index: number) => {
            item.select = false;
          });

          return data.results;
        }),
      )
      .subscribe(data => (this.dataSource = data));

    this.dataWatcher = setInterval(async () => {
      if (Array.isArray(this.dataSource)) {
        for (let i = 0; i < this.dataSource.length; i++) {
          if (this.dataSource[i].selected_class.name == 'Video') {
            if (this.dataSource[i].meta?.needToTranscode) {
              if (this.dataSource[i].meta?.transcodeStatus != 'job.completed') {
                let currentData = await this.apiService.getData(this.authService.selectedAccountId, this.dataSource[i].id).toPromise();
                if (currentData.meta?.transcodeStatus == 'job.completed') {
                  this.dataSource[i] = currentData;
                }
              }
            }
          } else if (typeof this.dataSource[i].feed == 'object' && this.dataSource[i].feed != null) {
            if (this.dataSource[i].feed.adapter == 'screenshot' && typeof this.dataSource[i].meta.url_thumb == 'undefined') {
              let currentData = await this.apiService.getData(this.authService.selectedAccountId, this.dataSource[i].id).toPromise();
              if (currentData && typeof currentData.meta.url_thumb != 'undefined') {
                this.dataSource[i] = currentData;
              }
            }
          }
        }
      }
    }, 5000);
  }

  doRequest(): Observable<any> {
    switch (this.dataClass) {
      case DataClass.Data:
        return this.apiService.findData(this.authService.selectedAccountId, {
          query: this.searchQuery,
          limit: this.paginator.pageSize,
          start: this.paginator.pageIndex * this.paginator.pageSize,
          order: this.sort.direction,
          orderField: this.sort.active == 'actions' || this.sort.active == 'select' ? 'id' : this.sort.active,
          parent: this.dirRouting[this.dirRouting.length - 1].id,
          filter: this.dataType
        });
      case DataClass.PixaBay:
        return this.apiService.findPixabayImages(this.authService.selectedAccountId,
          this.searchQuery,
          this.pixabayFilter.imageType,
          this.pixabayFilter.orientation,
          this.pixabayFilter.category,
          this.pixabayFilter.colors,
          this.paginator.pageIndex + 1,
          this.paginator.pageSize,
        );
      case DataClass.Displays:
        return this.apiService.findDisplays({
          query: this.searchQuery,
          limit: this.paginator.pageSize,
          start: this.paginator.pageIndex * this.paginator.pageSize,
          order: this.sort.direction,
          orderField: this.sort.active == 'actions' || this.sort.active == 'select' ? 'id' : this.sort.active
        }, this.authService.selectedAccountId);
      default:
        return this.apiService.findDesigns({
          query: this.searchQuery,
          limit: this.paginator.pageSize,
          start: this.paginator.pageIndex * this.paginator.pageSize,
          order: this.sort.direction,
          orderField: this.sort.active == 'actions' || this.sort.active == 'select' ? 'id' : this.sort.active
        }, this.authService.selectedAccountId);
    }
  }

  applyFilter(event: Event) {
    this.searchTimeout && clearTimeout(this.searchTimeout);

    this.searchTimeout = setTimeout(() => {
      const filterValue = (event.target as HTMLInputElement).value;
      this.searchQuery = filterValue.trim().toLowerCase();
      this.paginator.pageIndex = 0;
      this.updateWatch.emit({});
    }, 500);
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  columnNames(name: string) {
    if (this.dataClass == DataClass.Designs) {
      if (name == 'id') return 'ID';
      if (name == 'name') return 'Name';
      if (name == 'mutationDate') return 'Date';
    }

    if (this.dataClass == DataClass.Displays) {
      if (name == 'id') return 'ID';
      if (name == 'name') return 'Name';
      if (name == 'mutationDate') return 'Date';
    }

    if (this.dataClass == DataClass.Data) {
      if (name == 'id') return 'ID';
      if (name == 'name') return 'Name';
      if (name == 'mutationDate') return 'Date';
      if (name == 'className') return 'Type';
    }

    if (name == 'actions') return 'Actions';

    return name;
  }

  columnFilter(colums: string[]): string[] {
    for (var i = colums.length - 1; i >= 0; i--) {
      if (this.dataClass == DataClass.Designs) {
        if (colums[i] == 'groups') colums.splice(i, 1);
        if (colums[i] == 'attributes') colums.splice(i, 1);
        if (colums[i] == 'categories') colums.splice(i, 1);
        if (colums[i] == 'id') colums.splice(i, 1);
      }

      if (this.dataClass == DataClass.Displays) {
        if (colums[i] == 'groups') colums.splice(i, 1);
        if (colums[i] == 'attributes') colums.splice(i, 1);
        if (colums[i] == 'categories') colums.splice(i, 1);
        if (colums[i] == 'id') colums.splice(i, 1);
      }

      if (this.dataClass == DataClass.Data) {
        if (colums[i] == 'groups') colums.splice(i, 1);
        if (colums[i] == 'parent') colums.splice(i, 1);
        if (colums[i] == 'id') colums.splice(i, 1);
        if (colums[i] == 'selected_class') colums.splice(i, 1);
      }
    }

    return colums;
  }

  modifyData(data: any) {
    if (this.dataClass == DataClass.Data) {
      data.className = data.selected_class.name;
    }
  }

  columnOrder(colums: string[]): string[] {
    if (this.dataClass == DataClass.Designs) {
      colums = this.sortByOrder(colums, ['select', 'mutationDate', 'name', 'actions']);
    }

    if (this.dataClass == DataClass.Data) {
      colums = this.sortByOrder(colums, ['select', 'className', 'name', 'mutationDate', 'actions']);
    }

    return colums;
  }

  sortByOrder(arrayToSort: string[], orderArray: string[]) {
    // Create a dictionary of the order array to get the index of each element
    const orderDict: any = {};
    for (let i = 0; i < orderArray.length; i++) {
      orderDict[orderArray[i]] = i;
    }

    // Sort the array based on the order array using a custom sorting function
    arrayToSort.sort((a, b) => {
      // Get the index of each element in the order array
      const indexA: any = orderDict[a];
      const indexB = orderDict[b];

      // If both elements are in the order array, compare their indices
      if (indexA !== undefined && indexB !== undefined) {
        return indexA - indexB;
      }

      // If only one element is in the order array, it comes first
      if (indexA !== undefined) {
        return -1;
      }
      if (indexB !== undefined) {
        return 1;
      }

      // If neither element is in the order array, sort alphabetically
      return a.localeCompare(b);
    });

    return arrayToSort;
  }

  refreshSearch() {
    this.updateWatch.emit();
  }

  triggerLoading() {
    this.isLoadingResults = true;
  }

  /*
  * Actions
  */
  create() {
    this.onTableDataChange.emit({
      action: TableChangeDataActions.New,
      selected: this.selection,
      item: {
        parentId: this.dirRouting[this.dirRouting.length - 1].id
      }
    });
  }

  edit(row: any) {
    this.onTableDataChange.emit({
      action: TableChangeDataActions.Edit,
      selected: this.selection,
      item: row,
    });
  }

  delete(row: any) {
    this.onTableDataChange.emit({
      action: TableChangeDataActions.Delete,
      selected: this.selection,
      item: row,
    });
  }

  deleteSelected() {
    this.onTableDataChange.emit({
      action: TableChangeDataActions.DeleteAll,
      selected: this.selection,
    });
  }

  moveSelected() {
    this.onTableDataChange.emit({
      action: TableChangeDataActions.Move,
      selected: this.selection,
    });
  }

  copySelected() {
    this.onTableDataChange.emit({
      action: TableChangeDataActions.Copy,
      selected: this.selection,
    });
  }

  onImageChange(event: DataChange) {
    if (event.dataChangeEvent == DataChangeEvent.onSave) {
      this.apiService.updateFeed(this.authService.selectedAccountId, event.data).subscribe(
        data => {
          this.updateWatch.emit();
          this.selected.image.isSelected = false;
        }
      );
    } else if (event.dataChangeEvent == DataChangeEvent.onCancel) {
      this.selected.image.isSelected = false;
    }
  }

  onVideoChange(event: DataChange) {
    if (event.dataChangeEvent == DataChangeEvent.onSave) {
      this.apiService.updateData(this.authService.selectedAccountId, event.data).subscribe(
        data => {
          this.updateWatch.emit();
          this.selected.video.isSelected = false;
        }
      );
    } else if (event.dataChangeEvent == DataChangeEvent.onCancel) {
      this.selected.video.isSelected = false;
    }
  }

  onFeedChange(event: DataChange) {
    if (event.dataChangeEvent == DataChangeEvent.onSave) {
      this.apiService.updateData(this.authService.selectedAccountId, event.data).subscribe(
        data => {
          this.updateWatch.emit();
          this.selected.feed.isSelected = false;
        }
      );
    } else if (event.dataChangeEvent == DataChangeEvent.onCancel) {
      this.selected.feed.isSelected = false;
    }
  }

  onWebviewChange(event: DataChange) {
    if (event.dataChangeEvent == DataChangeEvent.onSave) {
      this.apiService.updateData(this.authService.selectedAccountId, event.data).subscribe(
        data => {
          this.updateWatch.emit();
          this.selected.webview.isSelected = false;
        }
      );
    } else if (event.dataChangeEvent == DataChangeEvent.onCancel) {
      this.selected.webview.isSelected = false;
    }
  }

  onclosePixaBayImport() {
    this.showPixaBayImport = false;
    this.updateWatch.emit();
  }
}
