import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LayerTableColumn } from '../../../models/layer-table-column';
import { LayerTableRow } from '../../../models/layer-table-row';
import { EditorService } from '../../../services/editor.service';
import { ApiService } from 'src/app/api/api.service';
import { DataFields } from 'src/app/api/models/account/data/data-fields';
import { AuthService } from 'src/app/services/auth.service';

export interface LayerTableColumnUpdate { i: number, column: LayerTableColumn }

@Component({
  selector: 'app-edit-table',
  templateUrl: './edit-table.component.html',
  styleUrls: ['./edit-table.component.scss']
})
export class EditTableComponent {
  @Input() tableDataSource: Array<LayerTableRow[]> = [];
  @Input() displayedColumns: LayerTableColumn[] = [];

  @Output() onCloseTable = new EventEmitter<void>();
  @Output() onColumnDelete = new EventEmitter<number>();
  @Output() onColmnUpdate = new EventEmitter<LayerTableColumnUpdate>();
  @Output() onUpdateRowStyle = new EventEmitter<Array<LayerTableRow[]>>();

  fields: DataFields[] = [];

  constructor(public editorService: EditorService, private apiService: ApiService, private authService: AuthService) {
    apiService.fieldTypes().subscribe((data: DataFields[]) => {
      this.fields = data;
    });
  }

  closeTable() {
    this.onCloseTable.emit();
  }

  deleteColumn(i: number) {
    this.onColumnDelete.emit(i);
  }

  updateRowStyles() {
    this.onUpdateRowStyle.emit(this.tableDataSource);
  }

  updateColumn(i: number, column: LayerTableColumn) {
    this.onColmnUpdate.emit({ i, column });
  }

  onChangeField(event: any, item: LayerTableColumn, i: number, mapvalue:boolean) {
    if (mapvalue) {
      item.fieldClass = item.selectedFieldType.split('_')[0];
      item.fieldName = item.selectedFieldType.split('_')[1];
    }
    this.onColmnUpdate.emit({ i, column: item });
  }

}
