<div class="modal-main" cdkDrag>
    <div class="modal-head" cdkDragHandle>
        <div class="modal-title">Account</div>
        <mat-icon class="action-icons close-action" (click)="close()">close</mat-icon>
    </div>
    <div class="modal-body">
        <div *ngIf="account">
            <div class="settings-main">
                <div class="v-menu-container">
                    <div class="v-menu-button" (click)="currentOption = 'info'" [class.v-menu-button-selected]="currentOption == 'info'">Account info</div>
                    <div class="v-menu-button" (click)="currentOption = 'settings'" [class.v-menu-button-selected]="currentOption == 'settings'">Settings</div>
                    <div class="v-menu-button" (click)="currentOption = 'users'" [class.v-menu-button-selected]="currentOption == 'users'">Users</div>
                    <div class="v-menu-button" (click)="currentOption = 'log'" [class.v-menu-button-selected]="currentOption == 'log'">Log</div>
                    <div class="v-menu-button" *ngIf="account.id != 1" (click)="currentOption = 'delete'" [class.v-menu-button-selected]="currentOption == 'delete'">Delete</div>                    
                </div>
                <div class="settings-edit">
                    <div class="settings" *ngIf="currentOption == 'info'">
                        <table class="table table-dark table-bordered">
                            <tr>
                                <td style="width: 10em;">Account name</td>
                                <td style="float: left;">{{account.info.name}}</td>
                            </tr>
                            <tr *ngIf="stats">
                                <td style="width: 10em;">Displays</td>
                                <td style="float: left;">{{stats.totalDisplays}}/{{stats.allowedDisplays == -1 ? 'unlimited' : stats.allowedDisplays}}</td>
                            </tr>
                            <tr *ngIf="stats">
                                <td style="width: 10em;">Drive storage</td>
                                <td style="float: left;">{{bytesToSize(stats.totalStorage)}}/{{stats.allowedMB == -1 ? 'unlimited' : stats.allowedMB+'MB'}}</td>
                            </tr>
                            <tr *ngIf="stats">
                                <td style="width: 10em;">Drive items</td>
                                <td style="float: left;">{{stats.totalData}}</td>
                            </tr>
                            <tr *ngIf="stats">
                                <td style="width: 10em;">Allowed feeds</td>
                                <td style="float: left;">{{stats.allowedFeeds == -1 ? 'unlimited' : stats.allowedFeeds}}</td>
                            </tr>
                            <tr *ngIf="stats">
                                <td style="width: 10em;">Playlists</td>
                                <td style="float: left;">{{stats.totalPlaylists}}</td>
                            </tr>                            
                        </table>
                    </div>
                    <div class="settings" *ngIf="currentOption == 'settings'">
                        <table class="table">
                            <tr>
                                <td style="width: 15em">Account name</td>
                                <td style="float: left;"><input style="width: 25em;" class="input" type="text" placeholder="account name" [(ngModel)]="account.info.name" /></td>
                            </tr>
                            <tr>
                                <td style="width: 15em">Allowed storage (MB)</td>
                                <td style="float: left;"><input class="input" type="number"  [(ngModel)]="allowedMB" /></td>
                            </tr>
                            <tr>
                                <td style="width: 15em">Allowed displays</td>
                                <td style="float: left;"><input class="input" type="number"  [(ngModel)]="allowedDisplays" /></td>
                            </tr>
                            <tr>
                                <td style="width: 15em">Allowed feeds</td>
                                <td style="float: left;"><input class="input" type="number"  [(ngModel)]="allowedFeeds" /></td>
                            </tr>
                            <tr>
                                <td><button type="button" class="btn" (click)="saveAccountInfo()">Save</button></td>
                            </tr>
                        </table>
                    </div>
                    <div class="settings" *ngIf="currentOption == 'users'">
                        <table class="table text-center">
                            <thead>
                                <tr>
                                    <th style="float: left;">User</th>
                                    <th>Permissions</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let accountUser of account.accountUsers">
                                    <td class="align-middle" style="display: flex;
                            flex-direction: column;
                            align-items: flex-start;">{{accountUser.user.alias == '' ? 'Pending setup' : accountUser.user.alias}} <br/>
                                        <div style="    padding: 0.4em;
                                background-color: #2a2a2b;
                                border-radius: 0.2em;">{{accountUser.user.authProviders[0].providerUUID}}</div>
                                    </td>
                                    <td>
                                        <mat-form-field>
                                            <mat-label>{{getRolesAsString(accountUser)}}</mat-label>
                                            <mat-select [(ngModel)]="accountUser.user.roles" multiple (selectionChange)="onRolesChange(accountUser.user)">
                                                <mat-option *ngFor="let role of roles" [value]="role.id">{{role.name}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <!-- <div *ngIf="authService.user.id == accountUser.user.id">
                                            {{getRolesAsString(accountUser)}}
                                        </div> -->
                                    </td>
                                    <td>
                                        <button type="button" class="btn" (click)="deleteUser(accountUser.id)">Delete</button>
                                    </td>
                                </tr>
                                <tr *ngFor="let invite of invites">
                                    <td class="align-middle" style="display: flex;
                            flex-direction: column;
                            align-items: flex-start;">Invation sent <br/>
                                        <div style="padding: 0.4em;
                                background-color: #2a2a2b;
                                border-radius: 0.2em;">{{invite.authProvider.providerUUID}}</div>
                                    </td>
                                    <td>

                                    </td>
                                    <td>
                                        <button type="button" class="btn" (click)="deleteInvite(invite.id)">Delete</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div style="display: flex;">
                            <input style="width: 25em;" class="input" type="text" placeholder="email" [(ngModel)]="email" />
                            <button type="button" class="btn" (click)="addUser()">Add user</button>
                        </div>
                    </div>
                    <div class="settings" *ngIf="currentOption == 'log'">
                        <app-data-table [accountId]="account.id" [dataClass]="dataClass" (onTableDataChange)="onTableDataChange($event)"></app-data-table>
                    </div>
                    <div class="settings" *ngIf="currentOption == 'delete'">
                        <div style="display: flex;flex-direction: column;align-items: flex-start; color: aliceblue;gap: 0.5em;">
                            <div>Type 'DELETE' to delete this account</div>
                            <input style="width: 25em;" class="input" type="text" placeholder="DELETE" [(ngModel)]="deleteText" />
                            <button *ngIf="deleteText == 'delete' || deleteText == 'DELETE'" type="button" class="btn" (click)="deleteAccount()">Delete account</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>