import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiService } from 'src/app/api/api.service';
import { Account } from 'src/app/api/models/account/account';
import { EditItem } from 'src/app/models/edit-item';
import { PopupDialog } from 'src/app/popups/confirm/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { EditComponent } from '../edit/edit.component';
import { Data } from 'src/app/api/models/account/data/data';
import { Display } from 'src/app/api/models/account/display/display';
import { Design } from 'src/app/api/models/account/display/design';

@Component({
  selector: 'app-copy-design',
  templateUrl: './copy-design.component.html',
  styleUrls: ['./copy-design.component.scss']
})
export class CopyDesignComponent {
  window: any = window;
  accounts: Array<Account>;
  isLoading = true;
  isCopying = false;

  constructor(
    public dialogRef: MatDialogRef<EditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EditItem,
    private apiService: ApiService,
    private authService: AuthService,
    private _snackBar: MatSnackBar, private dialog: MatDialog) {

    this.authService.getAllAccounts().then((accounts) => {
      this.accounts = accounts;
      this.isLoading = false;
    }).catch((error) => {
      this._snackBar.open('Error getting accounts', 'Dismiss', {
        duration: 2000,
      });
      this.isLoading = false;
    });
  }


  onSelectAccount(account: Account) {
    this.dialog.open(PopupDialog, {
      data: {
        title: 'Copy design',
        message: 'Are you sure you want to copy the design(s) to the account "' + account.info.name + '"?'
      }
    }).afterClosed().subscribe(async (resultCheck: any) => {
      if (resultCheck) {
        this.isLoading = true;
        this.isCopying = true;
        try {
          for (let i = 0; i < this.data.data.length; i++) {
            let item: Design = this.data.data[i];
            await this.apiService.copyDesign(this.authService.account.id, item.id, account.id).toPromise();
          }
          this.dialogRef.close();
        } catch (error) {
          this._snackBar.open('Error copying design(s)', 'Dismiss', {
            duration: 2000,
          });
          this.isLoading = false;
          this.isCopying = false;
          return;
        }
      }
    });

  }
}
