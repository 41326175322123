import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTabsModule } from '@angular/material/tabs';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { CronEditorModule } from 'ngx-cron-editor';
import { MatTreeModule } from '@angular/material/tree';
import { MatRadioModule } from '@angular/material/radio';

import { DataTableComponent } from '../../components/data-table/data-table.component';
import { DataFolderComponent } from '../../components/data-folder/data-folder.component';
import { DataImageComponent } from '../../components/data/data-image/data-image.component';
import { DataVideoComponent } from '../../components/data/data-video/data-video.component';
import { DataFeedComponent } from '../../components/data/data-feed/data-feed.component';
import { DataFeedEditComponent } from '../../components/data/data-feed-edit/data-feed-edit.component';
import { EditComponent } from '../../components/edit/edit.component';
import { NewComponent } from '../../components/new/new.component';
import { PopupDialog } from '../../popups/confirm/dialog';
import { BreadcrumbsComponent } from '../../components/breadcrumbs/breadcrumbs.component';
import { InputfieldComponent } from '../../components/inputfield/inputfield.component';
import { PlaylistComponent } from '../../components/playlist/playlist.component';
import { DataSelectorComponent } from '../../components/playlist/data-selector/data-selector.component';
import { DesignSelectorComponent } from '../../components/playlist/design-selector/design-selector.component';
import { DeviceInfoComponent } from '../../components/device-info/device-info.component';
import { ConnectDeviceComponent } from '../../components/connect-device/connect-device.component';
import { DisplaySelectorComponent } from '../../components/playlist/display-selector/display-selector.component';
import { ByteThisNgxGoogleFontsModule } from '@tdonselaar/ngx-google-fonts2';
import { TextStyleEditorComponent } from '../../components/text-style-editor/text-style-editor.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { RouterModule } from '@angular/router';
import { PartnerListComponent } from '../../components/partner-list/partner-list.component';
import { AccountListComponent } from '../../components/account-list/account-list.component';
import { MediaSelectComponent } from '../../account/editor/components/media-select/media-select.component';
import { MatSliderModule } from '@angular/material/slider'
import { MoveDataComponent } from '../../components/move-data/move-data.component';
import { FolderTreeComponent } from '../../components/folder-tree/folder-tree.component';
import { WebDeviceComponent } from '../../components/web-device/web-device.component';
import { PixabayComponent } from '../../components/pixabay/pixabay.component';
import { AccountsComponent } from '../../partner/components/accounts/accounts.component';
import { SettingsComponent } from '../../partner/components/settings/settings.component';
import { AccountSettingsComponent } from '../../partner/components/account-settings/account-settings.component';
import { UploadComponent } from '../../components/upload/upload.component';
import { DataWebviewComponent } from '../../components/data/data-webview/data-webview.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { UserProfileComponent } from '../../components/user-profile/user-profile.component';
import { CopyDataComponent } from '../../components/copy-data/copy-data.component';
import { CopyDesignComponent } from '../../components/copy-design/copy-design.component';
import { MoveDisplayComponent } from '../../components/move-display/move-display.component';

@NgModule({
  declarations: [
    DataTableComponent,
    DataFolderComponent,
    DataImageComponent,
    DataVideoComponent,
    DataFeedComponent,
    DataFeedEditComponent,
    EditComponent,
    NewComponent,
    PopupDialog,
    BreadcrumbsComponent,
    InputfieldComponent,
    PlaylistComponent,
    DataSelectorComponent,
    DesignSelectorComponent,
    DeviceInfoComponent,
    ConnectDeviceComponent,
    DisplaySelectorComponent,
    TextStyleEditorComponent,
    PartnerListComponent,
    AccountListComponent,
    MediaSelectComponent,
    MoveDataComponent,
    FolderTreeComponent,
    WebDeviceComponent,
    PixabayComponent,
    AccountsComponent,
    SettingsComponent,
    AccountSettingsComponent,
    UploadComponent,
    DataWebviewComponent,
    UserProfileComponent,
    CopyDataComponent,
    CopyDesignComponent,
    MoveDisplayComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatMenuModule,
    MatButtonToggleModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatTreeModule,
    DragDropModule,
    FormsModule,
    MatTabsModule,
    MatButtonModule,
    MatSnackBarModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatCheckboxModule,
    MatProgressBarModule,
    MatDialogModule,
    MatSelectModule,
    ColorPickerModule,
    ReactiveFormsModule,
    CronEditorModule,
    ByteThisNgxGoogleFontsModule,
    RouterModule,
    MatSliderModule,
    MatRadioModule,
    NgxChartsModule
  ],
  exports: [
    UserProfileComponent,
    DisplaySelectorComponent,
    ConnectDeviceComponent,
    DeviceInfoComponent,
    DataTableComponent,
    DataFolderComponent,
    DataImageComponent,
    DataVideoComponent,
    DataFeedComponent,
    DataFeedEditComponent,
    WebDeviceComponent,
    EditComponent,
    NewComponent,
    PlaylistComponent,
    PopupDialog,
    BreadcrumbsComponent,
    InputfieldComponent,
    DataSelectorComponent,
    DesignSelectorComponent,
    TextStyleEditorComponent,
    MediaSelectComponent,
    MoveDataComponent,
    FolderTreeComponent,
    PixabayComponent,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatButtonToggleModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatTreeModule,
    DragDropModule,
    FormsModule,
    MatTabsModule,
    MatButtonModule,
    MatSnackBarModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatCheckboxModule,
    MatProgressBarModule,
    MatDialogModule,
    MatSelectModule,
    ColorPickerModule,
    ReactiveFormsModule,
    CronEditorModule,
    ByteThisNgxGoogleFontsModule,
    RouterModule,
    PartnerListComponent,
    AccountListComponent,
    MatSliderModule,
    AccountsComponent,
    SettingsComponent,
    AccountSettingsComponent,
    MatRadioModule,
    UploadComponent,
    NgxChartsModule
  ],
  providers: [

  ]
})
export class SharedModule { }
