import { Data } from "../api/models/account/data/data";
import { DataRow } from "../api/models/account/data/data-row";
import { DataRowData, DataRowDataResult } from "../api/models/account/data/data-row-data";

export enum DataChangeEvent {
    onSave = 'onSave',
    onCancel = 'onCancel',
}

export interface DataRowChange {
    dataChangeEvent: DataChangeEvent;
    dataRow: DataRow
}

export interface DataChange {
    dataChangeEvent: DataChangeEvent;
    data: Data
}
