import { AfterViewInit, Component, NgZone } from '@angular/core';
import { ApiService } from 'src/app/api/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { CommonService } from 'src/app/services/common.service';


@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements AfterViewInit {
  uploadInProgress = false;
  uploadProgress = 0;
  showUploadProgress = false;
  showUploadDone = false;
  uploadFileCounter = 0;
  didGetError = false;

  constructor(public commonService: CommonService, private ngZone: NgZone, private apiService: ApiService, private authService: AuthService) {

  }

  ngAfterViewInit(): void {
    this.uploadNextFile(0);
  }

  closeUploadWindow() {
    this.ngZone.run(() => {
      this.commonService.uploadOpen = false;
      this.showUploadProgress = false;
      this.showUploadDone = false;
      this.commonService.filesToUpload = [];
      this.uploadFileCounter = 0;
      this.uploadProgress = 0;
      this.commonService.uploadChange();
    });
  }

  uploadNextFile(currentCount: number = 0) {
    if (this.commonService.filesToUpload.length == 0 || currentCount >= this.commonService.filesToUpload.length) {
      this.uploadInProgress = false;
      this.showUploadDone = true;
      this.uploadFileCounter = 0;
      return;
    }

    let file = this.commonService.filesToUpload[currentCount];
    this.uploadFileCounter++;
    this.apiService.uploadData(this.authService.selectedAccountId, file.file, file.className, this.commonService.uploadDirId, this.commonService.uploadPlayListId,
      (_proress: number, message: string) => {
        const fileToUpload = this.commonService.filesToUpload.find(x => x.file === file.file);
        if (fileToUpload.uploadProgress < _proress) {
          fileToUpload.uploadProgress = _proress;
          fileToUpload.finalizing = _proress == 100;
          this.uploadProgress = Math.round((100 * this.commonService.filesToUpload.reduce((acc, x) => acc + x.uploadProgress, 0)) / (this.commonService.filesToUpload.length * 100));
        }
      }).then((event: any) => {
        file.finalizing = false;
        // if (this.playlistId > 0) {
        //   this.onDataSelect.emit([event.body]);
        // }
        this.uploadNextFile((currentCount + 1));
      }).catch((error: any) => {
        file.error = true;

        if(Array.isArray(error.error)) {
          file.errorMessage = error.error[0];
        } else if(error.message){
          file.errorMessage = error.message;
        }else{
          file.errorMessage = "An error occurred while uploading the file, please try again later.";
        }
        
        this.didGetError = true;
        this.uploadNextFile((currentCount + 1));
      });
  }
}
