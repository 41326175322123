import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Account } from 'src/app/api/models/account/account';
import { Partner } from 'src/app/api/models/partner/partner';
import { User } from 'src/app/api/models/user/user';
import { AuthService } from 'src/app/services/auth.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-partner',
  templateUrl: './partner.component.html',
  styleUrls: ['./partner.component.scss']
})
export class PartnerComponent implements OnInit {
  id: string|null = null;
  user: User|null = null;
  account: Partner|null = null;
  menuOpen: boolean = false;

  constructor(private authService: AuthService, public commonService: CommonService) { 
    this.authService.getAccount().subscribe(user => {
      this.user = user;
      this.account = authService.partner;
    });    
  }

  openProfile() {
    this.menuOpen = false;
    this.commonService.userProfileOpen = true;
  }

  openAccountSelect() {
    this.menuOpen = false;
    this.commonService.accountSelectOpen = true;
  }

  openPartnerSelect(){
    this.menuOpen = false;
    this.commonService.partnerSelectOpen = true;
  }

  logout(): void {
    this.authService.logOut();
  }

  ngOnInit(): void {
    
  }
}
