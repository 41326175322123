import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { Data } from 'src/app/api/models/account/data/data';
import { ApiService } from 'src/app/api/api.service';
import { DataChange, DataChangeEvent } from 'src/app/models/data-change';

@Component({
  selector: 'app-data-video',
  templateUrl: './data-video.component.html',
  styleUrls: ['./data-video.component.scss']
})
export class DataVideoComponent implements AfterViewInit {
  @Input() data!: Data;
  @Output() onDataChange = new EventEmitter<DataChange>();

  tags: { value: string }[] = [];

  constructor(private apiService: ApiService) {

  }
  ngAfterViewInit(): void {
    if (this.data.meta.tags) {
      this.data.meta.tags.forEach(tag => {
        this.tags.push({ value: tag });
      });
    }
  }

  save() {
    if(Array.isArray(this.data.meta)){
      this.data.meta = {};
    }
    this.data.meta.tags = [];
    for (let i = 0; i < this.tags.length; i++) {
      if(this.tags[i].value != '')
      this.data.meta.tags.push(this.tags[i].value);
    }
    this.onDataChange.emit({ dataChangeEvent: DataChangeEvent.onSave, data: this.data });
  }

  cancel() {
    this.onDataChange.emit({ dataChangeEvent: DataChangeEvent.onCancel, data: this.data });
  }

  addTag(){
    this.tags.push({value: ''});
  }
}
