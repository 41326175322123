<div class="setupContainer">
    <div class="overlay">
        <div class="">
            <h1>Create account</h1>
            <span>Choose a unique name for your account. You can pick a new name for it whenever you want. (You can change it later on.)</span>
            <div style="padding-top: 1em; padding-bottom: 1em;">
                <input class="input" type="text" placeholder="account name" [(ngModel)]="name" />
            </div>
            <div>
                <button mat-raised-button (click)="setup()">Create new account</button>
            </div>
        </div>
    </div>
</div>