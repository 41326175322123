<div class="modal-main" cdkDrag>
    <div class="modal-head" cdkDragHandle>
        <div class="modal-title">Edit columns</div>
        <mat-icon class="action-icons close-action" (click)="cancel()">close</mat-icon>
    </div>
    <div class="modal-body">
        <table cdkDropList (cdkDropListDropped)="drop($event)">
            <tr cdkDrag *ngFor="let column of columns; let i = index">
                <td>
                    <div>
                        <input type="text" class="form-control" [(ngModel)]="column.name" (change)="onNameChange($event, column, i)">
                    </div>
                </td>
                <td>
                    <select class="form-select" [(ngModel)]="column.selected" (change)="onChangeField($event, column, i)">
                        <option *ngFor="let field of fields" [value]="field.field_class+'_'+field.name">{{field.field_class}} - {{field.name}}</option>
                    </select>

                    <!-- <mat-form-field>
                        <input matInput [(ngModel)]="column.fieldClass">
                    </mat-form-field> -->
                </td>
                <!-- <td>
                    <mat-form-field>
                        <input matInput [(ngModel)]="column.fieldName">
                    </mat-form-field>
                </td> -->
                <td>
                    <button tabindex="-1" mat-icon-button (click)="remove(i)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </td>
            </tr>
        </table>
        <div>
            <button (click)="add()" mat-stroked-button>Add a new column</button>
        </div>
    </div>
    <!-- <div class="actions">
        <button mat-button (click)="cancel()">Close</button>
    </div> -->
</div>