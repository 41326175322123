import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/api/api.service';
import { Account } from 'src/app/api/models/account/account';
import { AuthService } from 'src/app/services/auth.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-account-list',
  templateUrl: './account-list.component.html',
  styleUrls: ['./account-list.component.scss']
})
export class AccountListComponent implements AfterViewInit {
  accounts: Account[] = [];
  accountsFull: Account[] = [];
  isLoading = true;
  searchQuery = '';

  @ViewChild('accountSearchField') inputField: ElementRef;

  constructor(public commonService: CommonService, private apiService: ApiService, private authService: AuthService, private router: Router, private _snackBar: MatSnackBar,) {
    this.authService.getAllAccounts().then((accounts) => {
      this.accounts = accounts;
      this.accountsFull = accounts;
      this.isLoading = false;
    }).catch((error) => {
      this._snackBar.open('Error getting accounts', 'Dismiss', {
        duration: 2000,
      });
      this.isLoading = false;
    });
  }
  ngAfterViewInit(): void {
    this.setFocus();
  }

  close() {
    this.commonService.accountSelectOpen = false;
  }

  setFocus() {
    setTimeout(() => {
      this.inputField.nativeElement.focus();
    }, 0); // A short delay to ensure rendering is complete before focusing
  }

  selectAccount(account: Account) {
    this.router.navigate([
      'account/' + account.id + '/dashboard'
    ]);
    setTimeout(() => {
      location.reload();
      this.close();
    }, 100);
  }

  onSearch() {
    this.accounts = this.accountsFull.filter((account) => {
      return account.info.name.toLowerCase().includes(this.searchQuery.toLowerCase());
    });
  }

  openAccountCreate() {
    this.router.navigate(['/setup']);
    this.close();
  }
}
