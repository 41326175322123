import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
export interface DialogData {
    title: string;
    message: string;
}
@Component({
    selector: 'popup-dialog',
    templateUrl: './dialog.html',
    styleUrls: ['./dialog.scss']
  })
  export class PopupDialog {
    constructor(
      public dialogRef: MatDialogRef<PopupDialog>,
      @Inject(MAT_DIALOG_DATA) public data: DialogData,) {
     
    }
  }