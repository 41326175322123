<app-breadcrumbs [isPartner]="true" [crumbs]="[{name:'Settings', link:currentLocation()}]"></app-breadcrumbs>
<div class="overlay">
    <div class="settings-main">
        <div class="v-menu-container">
            <div class="v-menu-button" (click)="currentOption = 'info'" [class.v-menu-button-selected]="currentOption == 'info'">Partner info</div>
            <div class="v-menu-button" (click)="currentOption = 'users'" [class.v-menu-button-selected]="currentOption == 'users'">Users</div>
        </div>
        <div class="settings-edit">
            <div class="settings" *ngIf="currentOption == 'info'">
                <table class="table">
                    <tr>
                        <td style="width: 8em;">Partner name</td>
                        <td><input style="width: 25em;" class="input" type="text" placeholder="partner name" [(ngModel)]="authService.partner.info.name" /></td>
                    </tr>
                </table>

                <button type="button" class="btn" (click)="saveAccountInfo()">Save</button>
            </div>
            <div class="settings" *ngIf="currentOption == 'users'">
                <table class="table text-center">
                    <thead>
                        <tr>
                            <th>User</th>
                            <th>Permissions</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="authService.partner">
                        <tr *ngFor="let accountUser of authService.partner.accountUsers">
                            <td class="align-middle" style="display: flex;
                            flex-direction: column;
                            align-items: flex-start; min-height: 5.5em;">{{accountUser.user.alias == '' ? 'Pending setup' : accountUser.user.alias}} <br/>
                                <div style="padding: 0.4em; background-color: #2a2a2b; border-radius: 0.2em;display: flex; align-items: center;gap: 0.2em;">
                                    <span class="material-symbols-outlined" style="font-size: 1.2em;">mail</span>{{accountUser.user.authProviders[0].providerUUID}}
                                </div>
                            </td>

                            <td>
                                <mat-form-field>
                                    <mat-label>{{getRolesAsString(accountUser)}}</mat-label>
                                    <mat-select [(ngModel)]="accountUser.user.roles" multiple (selectionChange)="onRolesChange(accountUser.user)">
                                        <mat-option *ngFor="let role of roles" [value]="role.id">{{role.name}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <!-- <div *ngIf="authService.user.id == accountUser.user.id">
                                    {{getRolesAsString(accountUser)}}
                                </div> -->
                            </td>
                            <td>
                                <button type="button" class="btn" (click)="deleteUser(accountUser.id)">Delete</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div style="display: flex;">
                    <input style="width: 25em;" class="input" type="text" placeholder="email" [(ngModel)]="email" />
                    <button type="button" class="btn" (click)="addUser()">Add user</button>
                </div>
            </div>
        </div>
    </div>
</div>