import { LayerTableColumn } from "../models/layer-table-column";
import { LayerTableRow } from "../models/layer-table-row";

export interface TableLayerOutput {
    tableDataSource: Array<LayerTableRow[]>;
    displayedColumns: LayerTableColumn[];
}

export class TableLayer {
    tableDataSource: Array<LayerTableRow[]> = [];
    displayedColumns: LayerTableColumn[] = [];

    public static fromData(data: any) : TableLayerOutput {
        let tableLayer = new TableLayer();
        tableLayer.tableDataSource = data.tableDataSource;
        tableLayer.displayedColumns = data.displayedColumns;
        for (let i = 0; i < tableLayer.displayedColumns.length; i++) {
            tableLayer.displayedColumns[i].selectedFieldType = tableLayer.displayedColumns[i].fieldClass + '_' + tableLayer.displayedColumns[i].fieldName;            
        }

        return tableLayer;
    }
}
