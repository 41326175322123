<!-- upload status-->
<div class="modal-main" cdkDrag>
    <div class="modal-head" cdkDragHandle style="align-items: center;">
        <div class="modal-title">Uploading files</div>
        <div></div>
    </div>
    <div style="width: 100%;">
        <div class="progress-bar" [style.width]="uploadProgress + '%'"></div>
    </div>
    <div class="upload-done" *ngIf="showUploadDone && !didGetError">
        <div>
            <span class="material-symbols-outlined" style="font-size: 3em;">
                    cloud_done
                    </span>
        </div>
        <div>Upload done</div>
    </div>
    <div class="modal-body">
        <li *ngFor="let file of commonService.filesToUpload; let i = index" class="files-uploading">
            <div class="file-title">
                <span class="title-line"><mat-spinner *ngIf="!file.error && file.uploadProgress < 100 || !file.error && file.finalizing" [diameter]="15"></mat-spinner> {{ file.file.name }}</span>
                <span *ngIf="!file.error && file.uploadProgress < 100">Uploading...</span>
                <span *ngIf="!file.error && file.finalizing">Processing uploaded file...</span>
                <span class="file-error" *ngIf="file.error">{{file.errorMessage}}</span>
                <span class="file-done" *ngIf="file.uploadProgress >= 100 && !file.error && !file.finalizing">File uploaded</span>
            </div>
            <div *ngIf="!file.error && file.uploadProgress < 100" class="progress-bar" [style.width]="file.uploadProgress + '%'"></div>
        </li>
    </div>
    <div style="padding: 1em;" *ngIf="showUploadDone">
        <button mat-stroked-button (click)="closeUploadWindow()">Close window</button>
    </div>
</div>