<div class="modal-main" [style.width]="data.width" [style.height]="data.height" cdkDrag>
    <div class="modal-head" cdkDragHandle>
        <div class="modal-title">{{data.title}}</div>
        <mat-icon class="action-icons close-action" mat-dialog-close>close</mat-icon>
    </div>
    <div class="modal-body">
        <div *ngIf="data.dataClass == dataClasses.Designs || 
        data.dataClass == dataClasses.Displays  || 
        data.dataClass == dataClasses.Playlists  || 
        data.dataClass == dataClasses.Composite">
            <input class="form-control" [(ngModel)]="data.data.name">
            <select *ngIf="data.dataClass == dataClasses.Designs" class="form-select" [(ngModel)]="data.data.device">
                <option [value]="'tv'">TV</option>
                <option [value]="'tablet'">Tablet</option>
            </select>
            <select *ngIf="data.dataClass == dataClasses.Designs" class="form-select" [(ngModel)]="data.data.orientation">
                <option [value]="'landscape'">Landscape</option>
                <option [value]="'portrait'">Portrait</option>
            </select>
        </div>
        <div *ngIf="data.dataClass == dataClasses.Accounts">
            <input class="form-control" [(ngModel)]="data.data.name">
        </div>
        <div *ngIf="data.dataClass == dataClasses.Firmware">
            <div style="display: flex;">
                <mat-form-field appearance="fill">
                    <mat-label>Type</mat-label>
                    <mat-select [(ngModel)]="data.data.hardwareTypes" multiple>
                        <mat-option *ngFor="let type of hardwareTypes" [value]="type.id">
                            {{type.hardware_type}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div>
                <textarea class="form-control" [(ngModel)]="data.data.postScript" placeholder="Post script"></textarea>
            </div>
            <div>
                <textarea class="form-control" [(ngModel)]="data.data.script" placeholder="Script"></textarea>
            </div>
            <div>
                <input class="form-control" type="file" (change)="onUplaodFile($event)">
            </div>
        </div>
        <div *ngIf="data.dataClass == dataClasses.Data">

            <div class="new-data-container">
                <div *ngIf="selectedType != ''" style="display: flex;align-items: center;">
                    <button mat-icon-button (click)="selectedType = ''" *ngIf="selectedType != ''" style="margin-left: -0.3em;">
                        <mat-icon>arrow_back</mat-icon>
                    </button>

                    <div style="font-size: 1.5em;">{{selectedType}}</div>
                </div>
                <div class="new-title-container">
                    <input class="form-control" [(ngModel)]="data.data.name">
                </div>
                <div class="new-data-list" *ngIf="selectedType == ''">
                    <div (click)="dataCreateFolder()" class="data-items">
                        Folder
                    </div>
                    <div (click)="dataCreateTable()" class="data-items">
                        Table
                    </div>
                    <div (click)="selectedType = 'rss'" class="data-items">
                        RSS
                    </div>
                    <div (click)="selectedType = 'website'" class="data-items">
                        Website
                    </div>
                    <div (click)="selectedType = 'weather'" class="data-items">
                        Weather
                    </div>
                    <div (click)="dataCreateFeed()" class="data-items">
                        Custom feed
                    </div>
                    <div (click)="openUrl('https://ifttt.com/display_link')" class="data-items">
                        IFTTT
                    </div>
                </div>
                <div class="new-data-container" *ngIf="selectedType != ''">
                    <b>Settings:</b>
                    <div *ngIf="selectedType == 'rss'">
                        <input style="width: 25em" class="form-control" [(ngModel)]="settings.rss.url" placeholder="RSS URL">
                    </div>
                    <div *ngIf="selectedType == 'website'" style="display: flex; align-items: flex-start;flex-direction: column;">
                        <mat-radio-group aria-label="Select an option" [(ngModel)]="settings.webpage.type">
                            <mat-radio-button value="screenshot">Webpage screenshot</mat-radio-button>
                            <mat-radio-button value="live">Live webpage (Android only)</mat-radio-button>
                          </mat-radio-group>

                          <input style="width: 25em; margin-top: 1em;" class="form-control" [(ngModel)]="settings.webpage.url" placeholder="Website URL">

                          <div *ngIf="settings.webpage.type == 'live'">
                            
                            <div class="url-list">
                                <table>
                                    <tr>
                                        <td>
                                            <div style="display: flex;">
                                                <div style="align-self: center;">Reset timeout (seconds):</div> 
                                                <input style="width: 5em" type="number" class="form-control" [(ngModel)]="settings.webpage.resetTimer" >
                                            </div>
                                        </td>
                                        <td></td>
                                    </tr>
                                    <tr><td><div style="text-align: left;">Allowed filter:</div></td></tr>
                                    <tr *ngFor="let urlVal of settings.webpage.allowedUrls; let i = index">
                                        <td><input style="width: 25em" class="form-control" [value]="urlVal" placeholder="Allow urls *google.com* or * for any"></td>
                                        <td><button class="btn" (click)="removeURL(i)">Remove</button></td>
                                    </tr>
                                </table>
                                <hr />
                                <div class="urls-allows">
                                    <input style="width: 25em" class="form-control" [(ngModel)]="settings.webpage.allowedURL" placeholder="Allow urls *google.com* or * for any">
                                    <button class="btn" (click)="addURL()">+ add</button>
                                </div>
                            </div>
                          </div>
                        
                    </div>
                    <div *ngIf="selectedType == 'weather'" style="width: 100%;">
                        <input style="width: 25em" class="form-control" (keyup)="searchForLocations()" [(ngModel)]="weatherLocationSearchField" placeholder="Find location">
                        <table class="table table-dark mt-3 align-middle">
                            <thead>
                                <tr>
                                    <th scope="col">Location</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let location of weatherLocations"><td>{{location.country}} - {{location.name}} - {{location.region}}</td><td><button (click)="selectLocation(location.name)" [style.backgroundColor]="location.name == settings.weather.location ? '#18181' : ''" class="btn">{{location.name == settings.weather.location ? "selected" : "select"}}</button></td></tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div class="actions">
        <button mat-button mat-dialog-close cdkFocusInitial>Close</button>
        <button mat-button (click)="onSave()">Save</button>
    </div>
</div>