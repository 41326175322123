<h1 class="typeTitle" *ngIf="getDataClass() != 'pixabay'">Drive</h1>

<div *ngIf="getDataClass() == 'pixabay'" class="powered-pixabay">
    <div>Powered by</div>
    <a href="https://pixabay.com/" style="margin: 3px 15px 5px 0;
    font-size: 12px;
    line-height: 1.7;
    color: #555;
    display: block;
    float: left;
    padding: 9px 12px 6px;
    border: 1px solid #ccc;
    background-color: white;
    border-radius: 0.4em;" target="_blank" (click)="$event.stopPropagation()">
        <i style="display:block;width:68px;height:18px;overflow:hidden"><img crossorigin="anonymous" src="/assets/images/logo.svg" style="width:94px"></i> Free Images
    </a>
</div>

<mat-form-field style="width: 100%; padding-bottom: 1em;">
    <mat-label>Search</mat-label>
    <input class="input" matInput (keyup)="applyFilter($event)" placeholder="Search..." type="search" #input>
</mat-form-field>

<div class="table-top-buttons">
    <div>
        <div *ngIf="getDataClass() == 'pixabay'" class="pixabay-settings">
            <select class="form-select" [(ngModel)]="pixabayFilter.imageType" (change)="applyFilter($event)">
                <option *ngFor="let pixabaySetting of pixabaySettings.imageType" [value]="pixabaySetting">{{pixabaySetting == 'all' ? 'All types' : pixabaySetting}}</option>
            </select>
            <select class="form-select" [(ngModel)]="pixabayFilter.orientation" (change)="applyFilter($event)">
                <option *ngFor="let pixabaySetting of pixabaySettings.orientation" [value]="pixabaySetting">{{pixabaySetting == 'all' ? 'All orientations' : pixabaySetting}}</option>
            </select>
            <select class="form-select" [(ngModel)]="pixabayFilter.category" (change)="applyFilter($event)">
                <option *ngFor="let pixabaySetting of pixabaySettings.category" [value]="pixabaySetting">{{pixabaySetting ? pixabaySetting : 'Any categories'}}</option>
            </select>
            <select class="form-select" [(ngModel)]="pixabayFilter.colors" (change)="applyFilter($event)">
                <option *ngFor="let pixabaySetting of pixabaySettings.colors" [value]="pixabaySetting">{{pixabaySetting ? pixabaySetting : 'Any colors'}}</option>
            </select>
        </div>
    </div>
    <div class="buttons-right">
        <div *ngIf="getDataClass() == 'data'" >
            <button (click)="fileInput.click()" mat-stroked-button>Upload Files</button>
            <input type="file" #fileInput style="display: none;" (change)="commonService.onFilesSelected($event)" multiple  />
        </div>
        <button *ngIf="getDataClass() == 'data'" (click)="showPixaBayImport = true;" mat-stroked-button>Search PixaBay</button>
        <button *ngIf="dataSelect" mat-stroked-button (click)="selectData()">Add {{selection.selected.length}} items</button>
        <button *ngIf="getDataClass() != 'pixabay'" mat-stroked-button (click)="create()"> create new item</button>

        <button *ngIf="getDataClass() != 'pixabay'" class="right-menu" mat-button [matMenuTriggerFor]="menu"><mat-icon>more_vert</mat-icon></button>
        <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="editSelected()"><mat-icon>edit</mat-icon>Rename</button>
            <button mat-menu-item (click)="moveSelected()"><mat-icon>drive_file_move</mat-icon>Move</button>
            <button mat-menu-item (click)="copySelected()"><mat-icon>file_copy</mat-icon>Copy</button>
            <button mat-menu-item (click)="deleteSelected()"><mat-icon>delete</mat-icon>Delete all</button>
        </mat-menu>
    </div>
</div>

<div class="dir-breadcrumbs">
    <div class="dir-breadcrumb" *ngFor="let dir of dirRouting; let i = index" (click)="changeDir(i)">
        <mat-icon *ngIf="i > 0">chevron_right</mat-icon>
        <div>{{dir.name}}</div>
    </div>
</div>

<mat-progress-bar mode="indeterminate" [style.opacity]="isLoadingResults ? 1 : 0"></mat-progress-bar>
<div class="mat-elevation-z8" ngClass="{{getDataClass()}}">
    <div class="folder-file-container">
        <div [title]="item.name" [style.background-image]="item.selected_class.name == 'Image' || item.selected_class.name == 'Video' ? 'url(' + item.meta.url_thumb + ')' : ''" class="folder-file" *ngFor="let item of dataSource" [ngClass]="{'item-selected': selection.isSelected(item), 'isDir': item.selected_class.name == 'Folder'}"
            (click)="selection.toggle(item)" (dblclick)="doubbleClick(item)">
            <div class="data-icon">
                <mat-icon class="icon" *ngIf="item.selected_class.name != 'Video' && item.selected_class.name != 'Image'">
                    {{item.selected_class.name == 'Folder' ? 'folder' : 'dynamic_feed'}}
                </mat-icon>
            </div>
            <div class="creating-screenshot-status" *ngIf="item.selected_class.name == 'Image' && !item.meta.url_thumb && item.feed">
                Generating screenshot
            </div>
            <div class="optimizing-error" *ngIf="item.meta?.needToTranscode && item.meta?.transcodeStatus == 'job.failed'">Error optimizing video</div>
            <div class="optimizing-status" *ngIf="item.meta?.needToTranscode && item.meta?.transcodeStatus != 'job.failed' && item.meta?.transcodeStatus != 'job.completed'">Optimizing video...</div>
            <div class="name">
                <div class="name-value">{{item.name}}</div>
            </div>

        </div>
    </div>

    <mat-paginator [length]="resultsLength" [pageSizeOptions]="[50, 100, 150]" aria-label="Select page of items"></mat-paginator>
</div>

<!-- edit coms-->
<div class="edit-container">
    <app-data-image (onDataChange)="onImageChange($event)" *ngIf="selected.image.isSelected" [data]="selected.image.item"></app-data-image>
    <app-data-video (onDataChange)="onVideoChange($event)" *ngIf="selected.video.isSelected" [data]="selected.video.item"></app-data-video>
    <app-data-feed (onDataChange)="onFeedChange($event)" *ngIf="selected.feed.isSelected" [data]="selected.feed.item"></app-data-feed>
    <app-data-webview (onDataChange)="onWebviewChange($event)" *ngIf="selected.webview.isSelected" [data]="selected.webview.item"></app-data-webview>
    <app-pixabay *ngIf="showPixaBayImport" [dataId]="dirRouting[dirRouting.length - 1].id" (onCloseMedia)="onclosePixaBayImport($event)" [dataType]="[]"></app-pixabay>
</div>