import { Component, ViewEncapsulation } from '@angular/core';
import { EditorTypes } from '../../enum/editor-types';
import { LayerTableColumn } from '../../models/layer-table-column';
import { LayerTableRow } from '../../models/layer-table-row';
import { EditorService } from '../../services/editor.service';
import { TextLayer } from '../../class/text-layer';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';


@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.css'],
})
export class TopbarComponent {
  constructor(public editorService: EditorService, private authService: AuthService, private router: Router) {

  }

  onEditorTypeChange(event: any) {
    if (event.value == EditorTypes.design) {
      this.editorService.onDesignMode();
    } else {
      this.editorService.onAnimationMode();
    }
  }

  back() {
    this.router.navigate([
      this.authService.getAccountBasePath() + '/designs'
    ]);
  }

  save() {
    this.editorService.save();
  }

  load() {
    this.editorService.load();
  }

  openSettings() {
    this.editorService.overlay.designsettings = true;
  }

  changeZoom(zoom: number) {
    //@ts-ignore
    LayerAPI.zoom(zoom);
    this.editorService.zoom = zoom;
  }

  createImageLayer() {
    this.editorService.overlay.driveTypes = ['Folder', 'Image'];
    this.editorService.overlay.drive = true;
  }

  createTableLayer() {
    let colums: LayerTableColumn[] = [];
    let rows: Array<LayerTableRow[]> = [];

    this.editorService.createTableLayer(colums, rows, "Table " + this.editorService.layers.length + 1);
  }

  createTextLayer() {
    let textLayer = new TextLayer();
    textLayer.text = 'Double click to edit text';
    this.editorService.createTextLayer(textLayer, "Text " + this.editorService.layers.length + 1);
  }

  createVideoLayer() {
    this.editorService.overlay.driveTypes = ['Folder', 'Video'];
    this.editorService.overlay.drive = true;
  }

  createPlaylistLayer() {
    this.editorService.createPlaylistLayer("Playlist " + this.editorService.layers.length + 1);
  }

  createWebviewLayer() {
    this.editorService.createWebViewLayer({
      url: "https://www.google.com",
      allowedUrls: ["*"],
      resetTimer: 0
    }, "Webview " + this.editorService.layers.length + 1);
  }
}
