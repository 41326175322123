<div class="modal-main" cdkDrag>
    <div class="modal-head" cdkDragHandle>
        <div class="modal-title">Settings</div>
        <mat-icon class="action-icons close-action" (click)="close()">close</mat-icon>
    </div>
    <div class="modal-body">
        <table class="table text-center">
            <tr>
                <td>Design name</td>
                <td>
                    <div class="mb-3">
                        <input class="form-control" [(ngModel)]="editorService.selectedDesign.name" />
                    </div>
                </td>
            </tr>
            <tr>
                <td>Theme Category</td>
                <td>
                    <div class="mb-3">
                        <select *ngIf="!selectCustomGroup" class="form-select" [(ngModel)]="editorService.selectedDesign.themeGroup" (change)="onThemeGroupChange()">
                            <option *ngFor="let themeGroup of themeGroups" [value]="themeGroup">{{themeGroup}}</option>
                            <option [value]="'custom'">Custom</option>
                        </select>
                        <input *ngIf="selectCustomGroup" class="form-control" [(ngModel)]="editorService.selectedDesign.themeGroup" />
                    </div>
                </td>
            </tr>
            <tr>
                <td>Theme name</td>
                <td>
                    <div class="mb-3">
                        <select *ngIf="!selectCustomTheme" class="form-select" [(ngModel)]="editorService.selectedDesign.themeName" (change)="onThemeChange()">
                            <option *ngFor="let theme of themes" [value]="theme">{{theme}}</option>
                            <option [value]="'custom'">Custom</option>
                        </select>

                        <input *ngIf="selectCustomTheme" class="form-control" [(ngModel)]="editorService.selectedDesign.themeName" />
                    </div>
                </td>
            </tr>
            <tr>
                <td>Tags (tag1, tag2, tag3)</td>
                <td>
                    <div class="mb-3">
                        <input class="form-control" [(ngModel)]="editorService.selectedDesign.tags" />
                    </div>
                </td>
            </tr>
            <tr *ngIf="authService.account.global_design">
                <td>Global</td>
                <td>
                    <div class="mb-3">
                        <input style="float: left;" type="checkbox" class="form-check-input" [(ngModel)]="editorService.selectedDesign.global_design" (change)="updateGlobalDesign()" />
                    </div>
                </td>
            </tr>
        </table>
    </div>
</div>