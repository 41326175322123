import { Injectable, NgZone } from '@angular/core';
import { TextLayer } from '../class/text-layer';
import { EditorTypes } from '../enum/editor-types';
import { LayerTypes } from '../enum/layer-types';
import { AnimationGroup } from '../models/animation-group';
import { ChildWidget } from '../models/child-widget';
import { Layer } from '../models/layer';
import { LayerSettings } from '../models/layer-settings';
import { LayerTableColumn } from '../models/layer-table-column';
import { LayerTableRow } from '../models/layer-table-row';
import { Design } from 'src/app/api/models/account/display/design';
import { ApiService } from 'src/app/api/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataClassType } from 'src/app/api/models/account/data/data-class';
import { TableLayer } from '../class/table-layer';
import { DataFields } from 'src/app/api/models/account/data/data-fields';
import { AnimationCustom } from '../models/animation-custom';
import { Observable } from 'rxjs/internal/Observable';
import { LayerWebView } from '../models/layer-webview';

@Injectable({
  providedIn: 'root'
})
export class EditorService {
  debug: boolean = false;

  editorType: EditorTypes = EditorTypes.design;
  zoom: number = 1;
  layers: Layer[] = [];
  layersSelected: boolean = false;
  selectedLayers: Layer[] = [];
  selectedLayer: Layer | null = null;
  editorLoaded: boolean = false;

  selectedForCopy: any[] = [];

  lastSelectedLayerId: number = 0;

  animationGroups: AnimationGroup[] = [];
  animationLayers: Layer[] = [];
  animationSelectedGroup: number = 0;
  animationSelectedKeyframe: number = -1;
  isAnimating: boolean = false;
  selectedDesign: Design | null = null;

  fieldTypes: DataFields[] = [];
  fieldClasses: DataClassType[] = [];

  updateAnimationTimeline: Function = (selectionChanged: boolean) => { };
  updateConfig: Function = () => { };
  openSettings: Function = () => { };
  onUpdateLayerSetings: Function = () => { };
  onTextLayerUpdate: Function = () => { };
  onLayerConfigUpdate: Function = () => { };
  onSavingSate: Function = (state: number) => { };
  play: Function = () => { };
  stop: Function = () => { };
  playPause: Function = () => { };

  history: any[] = [];
  historyLastChange: string = '';
  historyTimer: any = null;
  wasUndo: boolean = false;

  screenshotTimer: any = null;
  screenshotCounter: number = 0;

  isSnapToLayer: boolean = false;
  isSnapToGrid: boolean = false;

  selectedLayersChanged: boolean = false;

  overlay = {
    table: false,
    media: false,
    text: false,
    shape: false,
    drive: false,
    driveTypes: [],
    settings: false,
    designsettings: false,
  }
  editorFocused: boolean = false;
  onTimelineFocus: boolean;

  constructor(private ngZone: NgZone, private apiService: ApiService, private authService: AuthService, private _snackBar: MatSnackBar) {
    //@ts-ignore
    window.ServiceOnChange = this.onServiceUpdate.bind(this);
    //@ts-ignore
    window.ServiceOnDoubleClick = this.onDoubleClick.bind(this);
    this.animationSelectedGroup = 0;

    this.apiService.fieldTypes().subscribe(fieldTypes => {
      this.fieldTypes = fieldTypes;
    });

    this.getClasses().subscribe(classes => {
      this.fieldClasses = classes;
    });

    document.addEventListener('keydown', (event) => {
      if (!this.editorLoaded) { return; }

      if (event.ctrlKey && event.key === 's') {
        event.preventDefault(); // Prevent the default browser save action
        this.ngZone.run(() => {
          this.save();
        });
      } else if (event.ctrlKey && event.key === 'z' && this.editorFocused) {
        event.preventDefault(); // Prevent the default browser save action
        this.undo();
      } else if (event.key === 'Delete' && this.editorFocused || event.key === 'Delete' && this.onTimelineFocus) {
        if (this.animationSelectedKeyframe >= 0) {
          event.preventDefault(); // Prevent the default browser save action
          //@ts-ignore
          LayerAPI.removeKeyFrame(this.selectedLayer.widget.id, this.animationSelectedGroup, this.animationSelectedKeyframe);
        } else if (!this.onTimelineFocus) {
          if (this.selectedLayers.length > 0) {
            event.preventDefault(); // Prevent the default browser save action
            this.deleteSelectedLayers();
          }
        }
      } else if (event.ctrlKey && event.key === 'c' && this.editorFocused) {
        event.preventDefault(); // Prevent the default browser save action
        this.addToCopyList();
      } else if (event.ctrlKey && event.key === 'v' && this.editorFocused && this.selectedForCopy.length > 0) {
        event.preventDefault(); // Prevent the default browser save action
        if (this.selectedForCopy.length > 0) {
          this.copySelectedLayers();
        }
      } else if (event.code === 'Space' && this.onTimelineFocus) {
        event.preventDefault(); // Prevent the default browser save action
        if (!this.isAnimating) {
          this.play();
        } else {
          this.stop();
        }
      }
    });
  }

  onDesignMode(){
     //@ts-ignore
     LayerAPI.enableDesignMode(true);
  }

  onAnimationMode(){
    //@ts-ignore
    LayerAPI.enableDesignMode(false);
  }

  addToCopyList() {
    let layers = this.formatLayers();
    this.selectedForCopy = [];
    for (let i = 0; i < layers.length; i++) {
      if (layers[i].widget.selected) {
        this.selectedForCopy.push(layers[i]);
      }
    }
  }

  deleteSelectedLayers() {
    if (this.selectedLayers.length > 0) {
      this.selectedLayers.forEach(layer => {
        //@ts-ignore
        LayerAPI.removeLayer(layer.widget.id);
      });
    }
  }

  getClasses(): Observable<DataClassType[]> {
    //cache the response
    if (this.fieldClasses.length > 0) {
      return new Observable<DataClassType[]>(observer => {
        observer.next(this.fieldClasses);
        observer.complete();
      });
    }
    return new Observable<DataClassType[]>(observer => {
      this.apiService.getClasses(this.authService.selectedAccountId).subscribe(classes => {
        this.fieldClasses = classes;
        observer.next(this.fieldClasses);
        observer.complete();
      });
    });
  }

  createScreenshot(callback: Function) {
    this.screenshotCounter = 0;
    clearInterval(this.screenshotTimer);
    //@ts-ignore
    LayerAPI.startScreenShot();
    this.screenshotTimer = setInterval(() => {
      this.ngZone.run(() => {
        this.screenshotCounter++;
        if (this.screenshotCounter > 10) {
          clearInterval(this.screenshotTimer);
          callback("");
        }
        //@ts-ignore
        let data = LayerAPI.getScreenShot();
        if (data != "") {
          clearInterval(this.screenshotTimer);
          callback(data);
        }
      });
    }, 100);
  }

  onDoubleClick(layers: Layer[]) {
    this.ngZone.run(() => {
      this.openSettings();
      setTimeout(() => {
        this.overlay.settings = true;
      }, 100);
    });
  }

  save() {
    this.onSavingSate(1);
    this.createScreenshot((screenshot: string) => {
      if (this.selectedDesign == null) return;
      this.selectedDesign.data = {
        settings: {},
        layers: this.formatLayers(),
        screenshot: screenshot,
        orientation: this.selectedDesign.data.orientation,
        width: this.selectedDesign.data.width,
        height: this.selectedDesign.data.height,
      }
      this.onSavingSate(2);
      this.apiService.updateDesign(this.authService.selectedAccountId, this.selectedDesign).subscribe(x => {
        this.onSavingSate(3);
        setTimeout(() => {
          this.onSavingSate(0);
        }, 2000);
        this._snackBar.open("Design saved", "Close", {
          duration: 2000,
          verticalPosition: 'top',
        });
      }, error => {
        this.onSavingSate(0);
        this._snackBar.open("Error saving design", "Close", {
          duration: 10000,
          verticalPosition: 'top',
        });
      });
    });
  }

  load() {
    //@ts-ignore
    LayerAPI.reset();

    this.layers = [];
    this.layersSelected = false;
    this.selectedLayers = [];
    this.selectedLayer = null;
    this.selectedForCopy = [];

    this.animationGroups = [];
    this.animationLayers = [];
    this.animationSelectedGroup = 0;
    this.isAnimating = false;

    this.history = [];
    this.selectedDesign.tags = '';
    for (let i = 0; i < this.selectedDesign.categories.length; i++) {
      this.selectedDesign.tags += (this.selectedDesign.tags == '' ? '' : ', ') + this.selectedDesign.categories[i].name;
    }

    //@ts-ignore
    LayerAPI.loadLayers(JSON.stringify(this.selectedDesign.data));

    if(this.editorType === EditorTypes.design) {
      this.onDesignMode();
    }else{
      this.onAnimationMode();
    }
  }

  onServiceUpdate(layers: Layer[], animationGroups: any[], animationSelectedGroup: number) {
    if (this.debug) console.log('onServiceUpdate', layers, animationGroups, animationSelectedGroup);
    this.ngZone.run(() => {
      //compare the olds layer ids with the new ones
      this.selectedLayersChanged = false;
      if (this.selectedLayers.length > 0) {
        for (let i = 0; i < this.selectedLayers.length; i++) {
          let foundSelectedLayer = false;
          for (let index = 0; index < layers.length; index++) {
            if (layers[index].widget.selected) {
              if (this.selectedLayers[i].widget.id == layers[index].widget.id) {
                foundSelectedLayer = true;
              }
            }
          }

          if (!foundSelectedLayer) {
            this.selectedLayersChanged = true;
            break;
          }
        }
      }

      this.layers = layers;
      this.animationSelectedGroup = animationSelectedGroup;
      this.animationGroups = animationGroups;
      this.selectedLayers = this.layers.filter(x => x.widget.selected);
      this.selectedLayer = this.selectedLayers.length > 0 ? this.selectedLayers[0] : null;
      this.layersSelected = this.selectedLayers.length > 0;
      this.animationLayers = [];
      this.isAnimating = false;
      this.layers.forEach(layer => {
        if (layer == null) return;
        if (layer.widget.keyFrames[this.animationSelectedGroup].length > 0)
          this.animationLayers.push(layer);
        if (layer.widget.animate) {
          this.isAnimating = true;
        }

        layer.selectedFieldType = layer.fieldClass + '_' + layer.fieldName;
      });

      if (this.selectedLayersChanged) {
        this.animationSelectedKeyframe = -1;
      }

      if (this.selectedLayers.length > 0) {
        this.onUpdateLayerSetings();
        this.onTextLayerUpdate();
        if (this.lastSelectedLayerId != this.selectedLayers[0].widget.id) {
          this.lastSelectedLayerId = this.selectedLayers[0].widget.id;
          this.updateConfig();
        } else if (this.selectedLayers[0].widget.childWidget.type == LayerTypes.playlist) {
          this.updateConfig();
        } else if (this.selectedLayers[0].widget.childWidget.type == LayerTypes.table) {
          this.updateConfig();
        }

        this.addHistory();
      } else {
        this.animationSelectedKeyframe = -1;
      }

      this.updateAnimationTimeline(this.selectedLayersChanged);
    });
  }

  getLayerById(id: number, lastLayers): Layer | null {
    for (let i = 0; i < lastLayers.length; i++) {
      if (lastLayers[i].widget.id == id) {
        return lastLayers[i];
      }
    }
    return null;
  }

  addHistory() {
    if (this.wasUndo && this.history.length > 0) { this.wasUndo = false; return; }
    clearTimeout(this.historyTimer);
    this.historyTimer = setTimeout(() => {
      this.ngZone.run(() => {
        let layers = this.formatLayers();
        if (layers.length == 0) return;
        if (this.history.length > 0 && this.historyLastChange == JSON.stringify(layers)) return;
        this.historyLastChange = JSON.stringify(layers);
        if (this.history.length > 0) {
          let lastLayers = this.history[0];
          for (let i = 0; i < layers.length; i++) {
            if (!lastLayers[i]) { this.history.unshift(layers); break; }
            lastLayers[i].widget.selected = true;
            layers[i].widget.selected = true;
            let lastLayer = this.getLayerById(layers[i].widget.id, lastLayers);
            if (lastLayer == null) continue;
            if (JSON.stringify(layers[i]) != JSON.stringify(lastLayer)) {
              this.history.unshift(layers);
              break;
            }
          }
        } else {
          this.history.push(JSON.parse(JSON.stringify(layers)));
        }
        if (this.history.length > 50)
          this.history.splice(50, this.history.length - 50);
      });
    }, 150);
  }

  undo() {
    if (this.history.length == 0) return;
    this.wasUndo = true;
    let layers = this.history.shift();
    if (layers.length > 0) {
      let layer: Layer = layers[0];
    }
    //@ts-ignore
    LayerAPI.reset();
    //@ts-ignore
    LayerAPI.loadLayers(JSON.stringify({
      'layers': layers,
    }));

    let append = false;
    for (let i = 0; i < this.selectedLayers.length; i++) {
      this.selectLayer(this.selectedLayers[i], append);
      append = true;
    }
  }

  formatLayers() {
    let layers = this.layers.map(x => {
      return {
        name: x.name,
        fieldName: x.fieldName,
        fieldClass: x.fieldClass,
        classType: x.classType,
        isDynamic: x.isDynamic,
        required: x.required,

        widget: {
          id: x.widget.id,
          selected: x.widget.selected,
          animate: x.widget.animate,
          animateInit: x.widget.animateInit,
          changeRotation: x.widget.changeRotation,
          playlistKeyFrameSwitch: x.widget.playlistKeyFrameSwitch,
          useDisplayTime: x.widget.useDisplayTime,
          animationCustom: x.widget.animationCustom,
          childWidget: this.formatWidget(x.widget.childWidget),
          currentAnimation: x.widget.currentAnimation,
          keyFrames: x.widget.keyFrames.map(y => {
            return y.map(z => {
              return {
                from: {
                  angle: z.from.angle,
                  height: z.from.height,
                  width: z.from.width,
                  left: z.from.left,
                  top: z.from.top,
                  opacity: z.from.opacity,
                  zoom: z.from.zoom,
                  skewX: z.from.skewX,
                  skewY: z.from.skewY,
                  blurValue: z.from.blurValue,
                },//LayerSettings
                to: {
                  angle: z.to.angle,
                  height: z.to.height,
                  width: z.to.width,
                  left: z.to.left,
                  top: z.to.top,
                  opacity: z.to.opacity,
                  zoom: z.to.zoom,
                  skewX: z.to.skewX,
                  skewY: z.to.skewY,
                  blurValue: z.to.blurValue,
                },
                time: z.time,
                curve: z.curve,
              }
            })
          }),
          selectedGroup: x.widget.selectedGroup,
          settings: x.widget.settings
        }
      }
    });
    return layers;
  }

  formatWidget(widget: ChildWidget) {
    if (widget.type == LayerTypes.text) {
      return {
        type: LayerTypes.text,
        data: TextLayer.fromData(JSON.parse(widget.data))
      }
    } else if (widget.type == LayerTypes.table) {
      return {
        type: LayerTypes.table,
        data: TableLayer.fromData(JSON.parse(widget.data))
      }
    } else if (widget.type == LayerTypes.image) {
      return {
        type: LayerTypes.image,
        data: JSON.parse(widget.data)
      }
    } else if (widget.type == LayerTypes.video) {
      return {
        type: LayerTypes.video,
        data: JSON.parse(widget.data)
      }
    } else if (widget.type == LayerTypes.playlist) {
      return {
        type: LayerTypes.playlist,
        data: JSON.parse(widget.data)
      }
    } else if (widget.type == LayerTypes.webview) {
      return {
        type: LayerTypes.webview,
        data: JSON.parse(widget.data)
      }
    }

    return {
      type: LayerTypes.none,
      data: {}
    };
  }

  updateLayerInfo(layer: Layer) {
    if (this.debug) console.log('updateLayerInfo', layer);
    //@ts-ignore
    LayerAPI.updateLayerInfo(
      Number(layer.widget.id),
      layer.name,
      layer.fieldClass,
      layer.fieldName,
      layer.classType,
      layer.isDynamic ? true : false,
      layer.required ? true : false,
    )
  }

  updateLayerSettings(id: Number, settings: LayerSettings) {
    if (this.debug) console.log('updateLayerSettings', id, settings);
    //@ts-ignore
    LayerAPI.updateLayerSettings(
      Number(id),
      JSON.stringify(settings),
    );
  }

  createTextLayer(textLayer: TextLayer, name: string = 'Layer', settings: LayerSettings | null = null) {
    if (this.debug) console.log('createTextLayer', textLayer, name, settings);
    if (settings == null) {
      settings = {
        angle: 0,
        height: 150,
        width: 300,
        left: 0,
        top: 0,
        opacity: 1,
        zoom: 1,
        color: { r: 255, g: 255, b: 255, a: 0 },
        borderColor: { r: 0, g: 0, b: 0, a: 0 },
        borderSize: 0,
        borderRadius: 0,
        isCircle: false,
        boxShadowEnabled: false,
        boxShadowOffSetX: 0,
        boxShadowOffSetY: 0,
        boxShadowSpreadRadius: 0,
        boxShadow: { r: 0, g: 0, b: 0, a: 0 },
        skewX: 0,
        skewY: 0,
        blurValue: 0,
      };
    }
    //@ts-ignore
    let layer: Layer = LayerAPI.createTextLayer(JSON.stringify(textLayer));
    layer.name = name;
    layer.fieldClass = "Feed";
    layer.fieldName = "Title";
    layer.classType = 'Feed';
    this.updateLayerInfo(layer);
    this.updateLayerSettings(layer.widget.id, settings);
    return layer;
  }

  updateTextLayer(layer: Layer, textLayer: TextLayer) {
    if (this.debug) console.log('updateTextLayer', layer, textLayer);
    textLayer.fontWeight = Number(textLayer.fontWeight);
    //@ts-ignore
    LayerAPI.updateTextLayer(layer.widget.id, JSON.stringify(textLayer));
  }

  createWebViewLayer(layerWebView: LayerWebView, name: string = 'Layer', settings: LayerSettings | null = null) {
    if (this.debug) console.log('createWebViewLayer', name, settings);
    if (settings == null) {
      settings = {
        angle: 0,
        height: 1080,
        width: 1920,
        left: 0,
        top: 0,
        opacity: 1,
        zoom: 1,
        color: { r: 255, g: 255, b: 255, a: 0 },
        borderColor: { r: 0, g: 0, b: 0, a: 0 },
        borderSize: 0,
        borderRadius: 0,
        isCircle: false,
        boxShadowEnabled: false,
        boxShadowOffSetX: 0,
        boxShadowOffSetY: 0,
        boxShadowSpreadRadius: 0,
        boxShadow: { r: 0, g: 0, b: 0, a: 0 },
        skewX: 0,
        skewY: 0,
        blurValue: 0,
      };
    }
    //@ts-ignore
    let layer: Layer = LayerAPI.createWebView(JSON.stringify(layerWebView));
    layer.name = name;
    layer.fieldClass = "Feed";
    layer.fieldName = "Title";
    layer.classType = 'WebView';
    this.updateLayerInfo(layer);
    this.updateLayerSettings(layer.widget.id, settings);
    return layer;
  }

  updatePlaylistItems(layer: Layer, data: any) {
    if (this.debug) console.log('updatePlaylistItems', data);
    //@ts-ignore
    LayerAPI.updatePlaylistItems(layer.widget.id, JSON.stringify(data));
    this.updateConfig();
  }

  createPlaylistLayer(name: string = 'Layer', settings: LayerSettings | null = null) {
    if (this.debug) console.log('createPlaylistLayer', name, settings);

    if (settings == null) {
      settings = {
        angle: 0,
        height: 400,
        width: 400,
        left: 0,
        top: 0,
        opacity: 1,
        zoom: 1,
        color: { r: 255, g: 255, b: 255, a: 0 },
        borderColor: { r: 0, g: 0, b: 0, a: 0 },
        borderSize: 0,
        borderRadius: 0,
        isCircle: false,
        boxShadowEnabled: false,
        boxShadowOffSetX: 0,
        boxShadowOffSetY: 0,
        boxShadowSpreadRadius: 0,
        boxShadow: { r: 0, g: 0, b: 0, a: 0 },
        skewX: 0,
        skewY: 0,
        blurValue: 0,
      };
    }
    //@ts-ignore
    let layer: Layer = LayerAPI.createPlaylistLayer(JSON.stringify({
      time: 5,
      items: []
    }));
    layer.name = name;
    layer.fieldClass = "Feed";
    layer.fieldName = "Image";
    layer.classType = 'Playlist';
    this.updateLayerInfo(layer);
    this.updateLayerSettings(layer.widget.id, settings);
    return layer;
  }

  createTableLayer(columns: LayerTableColumn[], rows: Array<LayerTableRow[]>, name: string = 'Layer', settings: LayerSettings | null = null): Layer {
    if (this.debug) console.log('createTableLayer', columns, rows, name, settings);
    if (settings == null) {
      settings = {
        angle: 0,
        height: 500,
        width: 1000,
        left: 0,
        top: 0,
        opacity: 1,
        zoom: 1,
        color: { r: 255, g: 255, b: 255, a: 0 },
        borderColor: { r: 0, g: 0, b: 0, a: 0 },
        borderSize: 0,
        borderRadius: 0,
        isCircle: false,
        boxShadowEnabled: false,
        boxShadowOffSetX: 0,
        boxShadowOffSetY: 0,
        boxShadowSpreadRadius: 0,
        boxShadow: { r: 0, g: 0, b: 0, a: 0 },
        skewX: 0,
        skewY: 0,
        blurValue: 0,
      };
    }

    //@ts-ignore
    let layer: Layer = LayerAPI.createTable(name, JSON.stringify({ displayedColumns: columns, tableDataSource: rows }));
    layer.fieldClass = "Table";
    layer.fieldName = "Title";
    layer.classType = 'Table';
    this.updateLayerInfo(layer);
    this.updateLayerSettings(layer.widget.id, settings);
    return layer;
  }

  createVideoLayer(url: string, duration: number, name: string = 'Layer', settings: LayerSettings | null = null): Layer {
    if (this.debug) console.log('createVideoLayer', url, name, settings);
    if (settings == null) {
      settings = {
        angle: 0,
        height: 400,
        width: 400,
        left: 0,
        top: 0,
        opacity: 1,
        zoom: 1,
        color: { r: 255, g: 255, b: 255, a: 0 },
        borderColor: { r: 0, g: 0, b: 0, a: 0 },
        borderSize: 0,
        borderRadius: 0,
        isCircle: false,
        boxShadowEnabled: false,
        boxShadowOffSetX: 0,
        boxShadowOffSetY: 0,
        boxShadowSpreadRadius: 0,
        boxShadow: { r: 0, g: 0, b: 0, a: 0 },
        skewX: 0,
        skewY: 0,
        blurValue: 0,
      };
    }
    //@ts-ignore
    let layer: Layer = LayerAPI.createVideo(name, url, Number(duration), JSON.stringify({ url: url, duration: Number(duration) }));
    layer.fieldClass = "Feed";
    layer.fieldName = "Video";
    layer.classType = "Video";
    this.updateLayerInfo(layer);
    this.updateLayerSettings(layer.widget.id, settings);
    return layer;
  }

  createImageLayer(url: string, name: string = 'Layer', settings: LayerSettings | null = null): Layer {
    if (this.debug) console.log('createImageLayer', url, name, settings);
    if (settings == null) {
      settings = {
        angle: 0,
        height: 400,
        width: 400,
        left: 0,
        top: 0,
        opacity: 1,
        zoom: 1,
        color: { r: 255, g: 255, b: 255, a: 0 },
        borderColor: { r: 0, g: 0, b: 0, a: 0 },
        borderSize: 0,
        borderRadius: 0,
        isCircle: false,
        boxShadowEnabled: false,
        boxShadowOffSetX: 0,
        boxShadowOffSetY: 0,
        boxShadowSpreadRadius: 0,
        boxShadow: { r: 0, g: 0, b: 0, a: 0 },
        skewX: 0,
        skewY: 0,
        blurValue: 0,
      };
    }
    //@ts-ignore
    let layer: Layer = LayerAPI.createImage(name, url, JSON.stringify({ url: url }));
    layer.fieldClass = "Feed";
    layer.fieldName = "Image";
    layer.classType = "Image";
    this.updateLayerInfo(layer);
    this.updateLayerSettings(layer.widget.id, settings);
    return layer;
  }


  updateTableLayer(layer: Layer, columns: LayerTableColumn[], rows: Array<LayerTableRow[]>) {
    if (this.debug) console.log('updateTableLayer', layer, columns, rows);
    //@ts-ignore
    LayerAPI.updateTable(layer.widget.id, JSON.stringify({ displayedColumns: columns, tableDataSource: rows }));
  }

  selectLayer(layer: Layer, append: boolean = false) {
    if (this.debug) console.log('selectLayer', layer, append);
    //@ts-ignore
    LayerAPI.selectLayer(layer.widget.id, append);
  }

  selectKeyFrame(layer: Layer, keyFrameIndex: number) {
    if (this.debug) console.log('selectKeyFrame', layer, keyFrameIndex);
    //@ts-ignore
    LayerAPI.selectKeyFrame(layer.widget.id, keyFrameIndex);
  }

  updateAnimation(layer: Layer, keyFrameIndex: number, duration: number) {
    if (this.debug) console.log('updateAnimation', layer, keyFrameIndex, duration);
    //@ts-ignore
    LayerAPI.updateAnimation(layer.widget.id, keyFrameIndex, duration);
  }

  updateAnimationCurve(id: number, keyFrameIndex: number, curve: string) {
    if (this.debug) console.log('updateAnimationCurve', id, keyFrameIndex, curve);
    //@ts-ignore
    LayerAPI.updateAnimationCurve(id, keyFrameIndex, curve);
  }

  updateAnimationCustom(id: number, animationCustom: AnimationCustom) {
    if (this.debug) console.log('updateAnimationCustom', id, animationCustom);
    //@ts-ignore
    LayerAPI.updateAnimationCustom(id, animationCustom.animationLayer, animationCustom.animationName, animationCustom.animationType);
  }

  copySelectedLayers() {
    if (this.debug) console.log('copySelectedLayers');
    for (let i = 0; i < this.selectedForCopy.length; i++) {
      //@ts-ignore
      LayerAPI.copySelectedLayers(JSON.stringify(this.selectedForCopy[i]));
    }
  }

  setSnapToLayer(snap: boolean) {
    this.isSnapToLayer = !snap;
    //@ts-ignore
    LayerAPI.setsnapToLayer(this.isSnapToLayer);
  }

  setSnapToGrid(snap: boolean) {
    this.isSnapToGrid = !snap;
    //@ts-ignore
    LayerAPI.setSnapToGrid(this.isSnapToGrid);
  }
}
