import { DataSource, CollectionViewer, SelectionChange } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, EventEmitter, Injectable, Output } from '@angular/core';
import { BehaviorSubject, Observable, merge, map } from 'rxjs';
import { ApiService } from 'src/app/api/api.service';
import { Data } from 'src/app/api/models/account/data/data';
import { DataResult } from 'src/app/api/models/account/data/data-result';
import { AuthService } from 'src/app/services/auth.service';


/** Flat node with expandable and level information */
export class DynamicFlatNode {
  constructor(
    public item: string,
    public data: Data,
    public dirList: Array<{ name: string, id: number }>,
    public level = 1,
    public expandable = false,
    public isLoading = false,
  ) { }
}

/**
 * File database, it can build a tree structured Json object from string.
 * Each node in Json object represents a file or a directory. For a file, it has filename and type.
 * For a directory, it has filename and children (a list of files or directories).
 * The input will be a json object string, and the output is a list of `FileNode` with nested
 * structure.
 */
export class DynamicDataSource implements DataSource<DynamicFlatNode> {
  dataChange = new BehaviorSubject<DynamicFlatNode[]>([]);

  get data(): DynamicFlatNode[] {
    return this.dataChange.value;
  }
  set data(value: DynamicFlatNode[]) {
    this._treeControl.dataNodes = value;
    this.dataChange.next(value);
  }

  constructor(
    private _treeControl: FlatTreeControl<DynamicFlatNode>,
    private apiService: ApiService,
    private authService: AuthService,
  ) { }

  connect(collectionViewer: CollectionViewer): Observable<DynamicFlatNode[]> {
    this._treeControl.expansionModel.changed.subscribe(change => {
      if (
        (change as SelectionChange<DynamicFlatNode>).added ||
        (change as SelectionChange<DynamicFlatNode>).removed
      ) {
        this.handleTreeControl(change as SelectionChange<DynamicFlatNode>);
      }
    });

    return merge(collectionViewer.viewChange, this.dataChange).pipe(map(() => this.data));
  }

  disconnect(collectionViewer: CollectionViewer): void { }

  /** Handle expand/collapse behaviors */
  handleTreeControl(change: SelectionChange<DynamicFlatNode>) {
    if (change.added) {
      change.added.forEach(node => this.toggleNode(node, true));
    }
    if (change.removed) {
      change.removed
        .slice()
        .reverse()
        .forEach(node => this.toggleNode(node, false));
    }
  }

  /**
   * Toggle the node, remove from display list
   */
  toggleNode(node: DynamicFlatNode, expand: boolean) {
    const index = this.data.indexOf(node);
    if (index < 0) {
      // If no children, or cannot find the node, no op
      return;
    }
    node.isLoading = true;

    setTimeout(() => {
      if (expand) {
        let nodes = [];
        this.apiService.findData(this.authService.selectedAccountId, {
          query: "",
          limit: 1000,
          start: 0,
          order: 'asc',
          orderField: 'id',
          parent: node.data.id,
          filter: ['Folder']
        }).subscribe((data: DataResult) => {
          data.results.forEach((item: any, indexnum: number) => {
            nodes.push(new DynamicFlatNode(item.name, item, [...node.dirList, ...[{ name: item.name, id: item.id }]], node.level + 1, true));
          });
          this.data.splice(index + 1, 0, ...nodes);
          this.dataChange.next(this.data);
          node.isLoading = false;
        }, (error: any) => {
          node.isLoading = false;
        });
      } else {
        let count = 0;
        for (
          let i = index + 1;
          i < this.data.length && this.data[i].level > node.level;
          i++, count++
        ) { }
        this.data.splice(index + 1, count);
        this.dataChange.next(this.data);
        node.isLoading = false;
      }

    }, 1000);
  }
}

@Component({
  selector: 'app-folder-tree',
  templateUrl: './folder-tree.component.html',
  styleUrls: ['./folder-tree.component.scss']
})
export class FolderTreeComponent {
  @Output() onDataSelect = new EventEmitter<{ data: Data, dirList: Array<{ name: string, id: number }> }>();

  constructor(private apiService: ApiService,
    private authService: AuthService,) {
    this.treeControl = new FlatTreeControl<DynamicFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new DynamicDataSource(this.treeControl, this.apiService, this.authService);

    this.dataSource.data.push(new DynamicFlatNode("Home", {
      id: 0,
      name: "Home",
      groups: null,
      meta: {},
      mutationDate: "",
      parent: null,
      selected_class: null,
    }, [{
      name: "Home",
      id: 0
    }], 1, true, false));
  }

  treeControl: FlatTreeControl<DynamicFlatNode>;

  dataSource: DynamicDataSource;

  getLevel = (node: DynamicFlatNode) => node.level;

  isExpandable = (node: DynamicFlatNode) => node.expandable;

  hasChild = (_: number, _nodeData: DynamicFlatNode) => _nodeData.expandable;

  onSelected(data: DynamicFlatNode) {
    this.onDataSelect.emit({ data: data.data, dirList: data.dirList });
  }
}
